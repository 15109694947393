import { MenuItem, Select, Tab, Tabs } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid-premium";
import React from "react";
import { isSBPSchedule, isTwoPageSBPSchedule } from "../util/DataTable.util";
import { Jurisdiction, ReturnNode } from "src/types";

export const getCustomToolbar = (
    returnNode: ReturnNode,
    handlePageChange,
    selectedPage: number,
    jurisdictions: Map<number, Jurisdiction>,
    selectedJurisdiction: number,
    handleSelectJurisdicion,
) => {
    return (
        <GridToolbarContainer className="grid-toolbar-container">
            <div className="first-grid-toolbar-row">
                {isTwoPageSBPSchedule(returnNode) ? (
                    <Tabs value={selectedPage} onChange={handlePageChange}>
                        <Tab label="Page 1" value={0} />
                        <Tab label="Page 2" value={1} />
                    </Tabs>
                ) : (
                    <></>
                )}
                {isSBPSchedule(returnNode) &&
                jurisdictions.get(selectedJurisdiction) ? (
                    <Select
                        className="sbp-jurisdiction-select"
                        key={`sbp-jurisdiction-select`}
                        value={selectedJurisdiction}
                        onChange={(e) => handleSelectJurisdicion(e)}
                        autoWidth={false}
                    >
                        {Array.from(jurisdictions.entries()).map(
                            ([key, jurisdiction]) => (
                                <MenuItem
                                    key={`jurisdiction${jurisdiction.id}`}
                                    value={key}
                                >
                                    {jurisdiction.longName}
                                </MenuItem>
                            ),
                        )}
                    </Select>
                ) : (
                    <></>
                )}
            </div>
        </GridToolbarContainer>
    );
};
