import { Menu, MenuItem, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { formatDateTime } from "src/services/Utility";
import { AllocatorJob, AllocatorReportsPageSize, ContextMenuPosition, FixMeLater, JOB_STATUS_TYPES, User } from "src/types";
import "./JobInfoMenu.scss";
import { AllocatorService } from "src/services";
import { useSnackbar } from "notistack";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { useSearchParams } from "react-router-dom";
import { DEFAULT_SEARCH_PARAMS } from "src/products/Allocator/constant";
import ConfirmationDialog from "../../../ConfirmationDialog/ConfirmationDialog";
import { TRITECH_ROLE } from "src/constants";

interface JobInfoMenuProps {
    job: AllocatorJob;
    position: ContextMenuPosition | null;
    handleCloseJobInfoMenu: () => void;
}

const JobInfoMenu: FC<JobInfoMenuProps> = ({
    job,
    position,
    handleCloseJobInfoMenu,
}) => {
    const allocatorService = AllocatorService.getInstance();

    const [open, setOpen] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );

    const self: User | undefined = useAppSelector(
        (state) => state?.Self,
    )?.value;

    const jobList: AllocatorJob[] = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.jobList
    );

    const selectedJob: AllocatorJob = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.selectedJob
    );

    const reportsPageSize: AllocatorReportsPageSize = useAppSelector(
        (state) => state?.[product?.productName]?.value?.reportsPageSize
    );

    const page: number = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.page
    );

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();

    const getDateOrDash = (date: string) => {
        return date ? formatDateTime(date) : "-";
    };

    const handleDeleteJob = async (jobId: number) => {
        try {
            await allocatorService.deleteJob(jobId);
            // Resetting and refreshing all jobs after uploading a bunch of new files
            if (page) {
                dispatch(
                    GlobalStateActions[product?.productName].setJobsPage(0)
                );
            }
            dispatch(
                GlobalStateActions[
                    product?.productName
                ].setToogleFetchJobs()
            );
            dispatch(
                GlobalStateActions[product?.productName].setJobsFilterState({ filters: [], searchPhrase: ""})
            );
            handleClose();
            enqueueSnackbar("Job Deleted Successfully", { variant: "success" });

            if (jobId === selectedJob?.id) {
                // if the latest job was deleted then auto select the next one after it
                const job = jobList[0]?.id !== jobId ? jobList[0] : jobList[1];

                const isJobCompleted = job?.status?.name !== JOB_STATUS_TYPES.FAILED 
                    && job?.status?.name !== JOB_STATUS_TYPES.PROCESSING;

                setSearchParams(
                    `?${new URLSearchParams({
                        jobId: job?.id?.toString() ?? "0",
                        report: isJobCompleted ? DEFAULT_SEARCH_PARAMS.summary : DEFAULT_SEARCH_PARAMS.report,
                        page: DEFAULT_SEARCH_PARAMS.page,
                        size: reportsPageSize[job?.id?.toString()] ?? DEFAULT_SEARCH_PARAMS.size,
                    })}`
                );

                dispatch(
                    GlobalStateActions[
                        product?.productName
                    ].setSelectedJob(job)
                );
            }
        } catch (error) {
            enqueueSnackbar("Error deleting job", { variant: "error" });
        } 
    }

    const handleClose = () => {
        setOpen(false);
        handleCloseJobInfoMenu()
    };

    const actionDelete = {
        text: "Delete Job",
        action: () => handleDeleteJob(job?.id),
    };

    const actionCancel = {
        text: "Cancel",
        action: handleClose,
    };

    return (
        <Menu
            open={position !== null}
            onClose={handleCloseJobInfoMenu}
            anchorReference="anchorPosition"
            anchorPosition={
                position !== null
                    ? { top: position?.mouseY, left: position?.mouseX }
                    : undefined
            }
        >
            {self?.roles?.includes(TRITECH_ROLE.ClientAccountAdmin) && (
                <MenuItem onClick={() => setOpen(true)}>
                    Delete Job
                </MenuItem>
            )}
            <div className="job-info-container">
                <Typography>
                    Completion Date: {getDateOrDash(job?.completionDate)}
                </Typography>
                <Typography>Created by: {job?.createdBy}</Typography>
                <Typography>
                    Approved: {getDateOrDash(job?.approvedDate)}
                </Typography>
            </div>
            <ConfirmationDialog
                open={open}
                title={"Are you sure you want to delete this job?"}
                content={"Deleting this job will remove all associated data and cannot be undone. Please confirm that you want to proceed with the deletion."}
                actionPrimary={actionDelete}
                actionSecondary={actionCancel}
                handleClose={handleClose}
            />
        </Menu>
    );
};

export default JobInfoMenu;
