import { Button, Modal, TextField, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";
import Loader from "src/components/Loader/Loader";
import ModalError from "src/components/ModalError/ModalError";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { SubAccountService } from "src/services";
import { CreateSubAccountPayload, FixMeLater } from "src/types";
import {
    StyledFlexContainer,
    StyledForm,
    StyledFormContainer,
    StyledModalContainer,
} from "../../../../AccountAdmin.styled";
import "./SubAccountModal.scss";

interface SubAccountModalProps {
    open: boolean;
    onClose: () => void;
}

const SubAccountModal: FC<SubAccountModalProps> = ({ open, onClose }) => {
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const selectedAccount = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccount
    );
    const selectedAccountSubWithCompanies = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccountSubAccountsWithCompanies
    );

    const dispatch = useAppDispatch();
    const subAccountService = SubAccountService.getInstance();

    const initialFormData = {
        subAccountName: "",
    };

    const [formData, setFormData] = useState(initialFormData);

    const resetFormData = () => {
        setFormData({
            subAccountName: "",
        });
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            setIsLoading(true);

            const newSubAccount: CreateSubAccountPayload = {
                name: formData.subAccountName,
                parentId: selectedAccount.id,
            };
            await subAccountService.createSubAccount(newSubAccount);

            dispatch(
                GlobalStateActions[product?.productName].setSelectedAccountSubWithCompanies([
                    ...selectedAccountSubWithCompanies,
                    newSubAccount,
                ])
            );
            resetFormData();
            onClose();
            setSnackbarOpen(true);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setError(null);
        onClose();
    };

    return (
        <>
            <Modal open={open} onClose={handleCancel}>
                <div>
                    <StyledModalContainer>
                        <StyledFormContainer>
                            <Typography variant="h5">
                                Add Sub-Account
                            </Typography>
                            <StyledForm onSubmit={handleSubmit}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="subAccountName"
                                    label="Sub-Account Name"
                                    name="subAccountName"
                                    value={formData.subAccountName}
                                    onChange={handleInputChange}
                                />

                                <StyledFlexContainer justify="center">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Add
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        color="secondary"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </StyledFlexContainer>
                            </StyledForm>
                        </StyledFormContainer>
                        {isLoading && <Loader />}
                        {error && <ModalError error={error} />}
                    </StyledModalContainer>
                </div>
            </Modal>
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message="Sub-account created successfully!"
                severity="success"
            />
        </>
    );
};

export default SubAccountModal;
