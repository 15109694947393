import React, { FC, useState } from "react";
import { ErrorCode, FileRejection, useDropzone } from "react-dropzone";
import { SnackbarKey, closeSnackbar, useSnackbar } from "notistack";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Button, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import Loader from "src/components/Loader/Loader";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AllocatorService } from "src/services";
import colors from "src/styles/colors.scss";
import { FixMeLater } from "src/types";
import { ACCEPTED_FILE_TYPES } from "../../constant";
import "./DragAndDrop.scss";
import { getErrorMessageByCode } from "./DragAndDrop.util";
import { getFirstPathSegment } from "src/services/Utility";

interface DragAndDropConfig {
    isEmptyPage: boolean;
    style: {
        height?: string;
        border?: string;
        backgroundColor?: string;
    };
}

interface DragAndDropProps {
    dragAndDropConfig?: DragAndDropConfig;
    lastSelectedCompany?: string | undefined;
}

const DragAndDrop: FC<DragAndDropProps> = ({ dragAndDropConfig, lastSelectedCompany }) => {
    const allocatorService = AllocatorService.getInstance();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const page: number = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.page
    );
    const selectedCompany: string = useAppSelector(
        (state) => state?.[product?.productName]?.value?.company,
    );
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();

    const { getRootProps, getInputProps } = useDropzone({
        disabled: isLoading,
        maxFiles: 15,
        accept: ACCEPTED_FILE_TYPES,
        onDrop: (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
            if (rejectedFiles?.length) {
                handleRejectedFileCode(rejectedFiles[0]?.errors[0]?.code);
            } else {
                handleUpload(acceptedFiles);
            }
        },
    });

    const handleRejectedFileCode = (errorCode: ErrorCode | string) => {
        enqueueSnackbar(getErrorMessageByCode(errorCode), { variant: "error" });
    };

    const checkSize = (files: File[]) => {
        const maxFilesSize = 15 * 1024 * 1024; // 15 MB in bytes
        const filesSize = files?.reduce(
            (acc: number, file: File) => acc + file.size,
            0
        );

        if (filesSize > maxFilesSize) {
            enqueueSnackbar("Max file size is 15 MB.", { variant: "error" });
            return false;
        }

        return true;
    };

    const handleUpload = async (files: File[]) => {
        // Temporary disabled for testing purposes (will be enabled soon)
        // if (!checkSize(files)) {
        //     return;
        // }

        setIsLoading(true);

        const formData = new FormData();
        const hasFileNameError = files?.some((file: File) => {
            formData.append(`files`, file, file.name);
            return (selectedCompany && selectedCompany !== file.name.split('_')[0]?.toUpperCase()) || 
                (lastSelectedCompany && lastSelectedCompany !== file.name.split('_')[0]?.toUpperCase());
        });

        if (hasFileNameError) {
            setIsLoading(false);
            enqueueSnackbar("Please select the correct company before uploading the file.", { variant: "error" });
            return;
        }

        try {
            const response = await allocatorService.uploadJob(formData);

            // Resetting and refreshing all jobs after uploading a bunch of new files
            if (page) {
                dispatch(
                    GlobalStateActions[product?.productName].setJobsPage(0)
                );
            }
                dispatch(
                    GlobalStateActions[
                        product?.productName
                    ].setToogleFetchJobs()
                );
            dispatch(
                GlobalStateActions[product?.productName].setJobsFilterState({ filters: [], searchPhrase: ""})
            );

            // Validating specific backend errors
            if (response?.hasErrors) {
                response?.errors?.forEach(err => {
                    if (err?.jobId) {
                        const action = (snackbarId: SnackbarKey) => (
                            <>
                                <Button color="inherit" size="small" onClick={() => handleNavigate(err?.jobId)}>
                                        View Error Log
                                </Button>
                                <IconButton
                                    color="inherit"
                                    sx={{ p: 0.5, marginLeft: 1 }}
                                    onClick={() => { closeSnackbar(snackbarId) }}
                                >
                                    <Close />
                                </IconButton>
                            </>
                        );

                        enqueueSnackbar(`${err?.filename} contains errors`, { variant: "error", action });
                    } else {
                        enqueueSnackbar(err?.errorDetails, { variant: "error" });
                    }
                })
            }
        } catch (error) {
            enqueueSnackbar("Error uploading files", { variant: "error" });
        } finally {
            setIsLoading(false);
        }
    };

    const handleNavigate = (jobId: number | undefined) => {
        window.open(
            `${
                window.location.origin
            }/${getFirstPathSegment()}/warnings/${jobId}`,
            "_blank"
        );
    };

    return (
        <div
            {...getRootProps({
                className: "drag-and-drop-container",
                style: dragAndDropConfig?.style,
            })}
        >
            {isLoading ? (
                <Loader />
            ) : (
                <div className="upload-info-container">
                    <input {...getInputProps()} />
                    <FileUploadOutlinedIcon
                        fontSize={
                            dragAndDropConfig?.isEmptyPage ? "large" : "medium"
                        }
                        style={{ color: colors.primaryColor }}
                    />
                    {dragAndDropConfig?.style?.height ? (
                        <p>Drop a document here</p>
                    ) : dragAndDropConfig?.isEmptyPage ? (
                        <>
                            <p>
                                Click or drag ASCII file to this area to upload
                            </p>
                            <p className="upload-info-container-hint">
                                Support for a single CSV or TXT upload
                            </p>
                        </>
                    ) : (
                        <p>Click or drag ASCII file</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default DragAndDrop;
