import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import Loader from "src/components/Loader/Loader";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { CompanyService } from "src/services";
import { FixMeLater } from "src/types";
import {
    StyledHeader,
    StyledNoDataFound,
    StyledPaper,
    StyledTableCell,
    StyledTableContainer,
    StyledTableRow,
} from "../../../../AccountAdmin.styled";
import "./Groups.scss";
import { FrameCard } from "src/uikit";

const Groups: FC = () => {
    const companyService = CompanyService.getInstance();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value,
    );
    const selectedAccountCompany = useAppSelector(
        (state) => state?.[product?.productName]?.value?.selectedAccountCompany,
    );
    const selectedAccountCompanyGroups = useAppSelector(
        (state) =>
            state?.[product?.productName]?.value?.selectedAccountCompanyGroups,
    );

    const dispatch = useAppDispatch();

    useEffect(() => {
        const fetchSelectedAccountCompanyGroups = async () => {
            if (selectedAccountCompany) {
                setIsLoading(true);
                try {
                    const selectedAccountCompanyGroupsData: FixMeLater[] =
                        await companyService.getCompanyGroups(
                            selectedAccountCompany.id,
                        );

                    dispatch(
                        GlobalStateActions[
                            product?.productName
                        ].setSelectedAccountCompanyGroups(
                            selectedAccountCompanyGroupsData,
                        ),
                    );
                } catch (error) {
                    setError(error as Error);
                } finally {
                    setIsLoading(false);
                }
            } else {
                dispatch(
                    GlobalStateActions[
                        product?.productName
                    ].setSelectedAccountCompanyGroups([]),
                );
            }
        };

        fetchSelectedAccountCompanyGroups();
    }, [selectedAccountCompany]);

    const noDataFoundMessage = selectedAccountCompany
        ? "This company is not currently associated with any groups."
        : "Please select a company.";

    if (error)
        return (
            <ErrorMessage error={`Error fetching users: ${error.toString()}`} />
        );

    if (!error && isLoading) return <Loader />;

    return (
        <div className="company-groups-container">
            <FrameCard title="COMPANY GROUPS" actions={[]}>
                {!selectedAccountCompanyGroups?.length ? (
                    <StyledNoDataFound>{noDataFoundMessage}</StyledNoDataFound>
                ) : (
                    <Table>
                        <TableBody>
                            {selectedAccountCompanyGroups?.map((group) => (
                                <TableRow key={group.id}>
                                    <TableCell sx={{ textAlign: "center" }}>
                                        {group.name}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </FrameCard>
        </div>
    );
};

export default Groups;
