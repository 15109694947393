import { List, ListItem, Paper } from "@mui/material";
import styled from "styled-components";

export const StyledPaper = styled(Paper)`
    position: absolute;
    width: 600px;
    background-color: #ffffff;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    padding: 24px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const StyledListContainer = styled(List)`
    max-height: 400px;
    overflow-y: auto;
`;

export const StyledListItem = styled(ListItem)<{ itemType: string }>`
    background-color: ${(props) =>
        props.itemType === "account"
            ? "#f0f8ff"
            : props.itemType === "subaccount"
              ? "#f0fff0"
              : props.itemType === "company"
                ? "#fffff0"
                : props.itemType === "user"
                  ? "#f0f0ff"
                  : props.itemType === "group"
                    ? "#fff0f5"
                    : "inherit"};

    border: 1px solid #f0f0f0;
    border-radius: 4px;
    padding: 12px;

    &:hover {
        background-color: #f0f0f0;
        cursor: pointer;
    }
`;

export const StyledListItemHeader = styled.div`
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 8px;
`;
