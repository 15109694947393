import { DataGridPremium } from "@mui/x-data-grid-premium";
import styled from "styled-components";

interface SupportingWorksheetStyledProps {
    $minWidth: number;
    $minHeight: number;
}

export const SupportingWorksheetStyled = styled.div<SupportingWorksheetStyledProps>`
    display: flex;
    flex-direction: column;
    padding: 16px;
    min-width: ${(props) => props.$minWidth}px;
    min-height: ${(props) => props.$minHeight}px;
`;

export const SupportingWorksheetLoading = styled.div<SupportingWorksheetStyledProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
    min-width: ${(props) => props.$minWidth}px;
    width: ${(props) => props.$minWidth}px;
    min-height: ${(props) => props.$minHeight}px;
    height: ${(props) => props.$minHeight}px;
`;

export const SupportingWorksheetHeader = styled.div`
    color: #354560;
    font-weight: bold;
    white-space: pre-wrap;
`;


export const DataGridPremiumStyled = styled(DataGridPremium)`
        .MuiDataGrid-cell {
            padding: 0 10px;
        }
`;