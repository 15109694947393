import { ErrorCode } from "react-dropzone";

export const getErrorMessageByCode = (errorCode: ErrorCode | string): string => {
    switch (errorCode) {
        case ErrorCode.FileInvalidType:
            return "Can't upload. Use a file in .txt extension.";
        case ErrorCode.TooManyFiles:
            return "Can't upload multiple files";
        default:
            return "Can't upload file";
    }
};

export const ACCEPTED_FILE_TYPES = {
    "text/plain": [],
};