const FeatureFlags = {
    showOnlyInLocal: () => {
        return process.env.REACT_APP_ENVIRONMENT === "local";
    },

    showOnlyInLocalAndDev: () => {
        return (
            process.env.REACT_APP_ENVIRONMENT === "local" ||
            process.env.REACT_APP_ENVIRONMENT === "development"
        );
    },

    showOnlyInLocalDevAndQa: () => {
        return (
            process.env.REACT_APP_ENVIRONMENT === "local" ||
            process.env.REACT_APP_ENVIRONMENT === "development" ||
            process.env.REACT_APP_ENVIRONMENT === "qa"
        );
    },

    showOnlyInLocalDevQaAndStaging: () => {
        return (
            process.env.REACT_APP_ENVIRONMENT === "local" ||
            process.env.REACT_APP_ENVIRONMENT === "development" ||
            process.env.REACT_APP_ENVIRONMENT === "qa" ||
            process.env.REACT_APP_ENVIRONMENT === "stage"
        );
    },
};

export default FeatureFlags;
