import React, { FC, useCallback, useEffect } from "react";
import ContainerWithResizableSideBar from "../../components/ContainerWithResizableSideBar/ContainerWithResizableSideBar";
import ReturnTreeViewer from "../../components/ReturnTreeViewer/ReturnTreeViewer";
import "./PremiumTax.scss";
import { User } from "src/types";
import { useAppSelector } from "src/hooks";
import MultiTabContainer from "src/components/MultiTabContainer/MultiTabContainer";
import CompanyOptions from "src/components/CompanyOptions/CompanyOptions";
import { Layout } from "src/uikit";

const PremiumTax: FC = () => {
    const self: User | undefined = useAppSelector(
        (state) => state?.Self
    )?.value;

    const companyOptionsOpen: boolean | undefined = useAppSelector(
        (state) => state?.Premium?.value.companyOptionsOpen
    );

    const beforeunloadHandler = useCallback(function (e) {
        e.preventDefault();
        e.returnValue = "";
    }, []);

    useEffect(() => {
        const syncData = async () => {
            if (self?.pendingChanges?.length) {
                window.addEventListener("beforeunload", beforeunloadHandler);
            } else {
                window.removeEventListener("beforeunload", beforeunloadHandler);
            }
        };
        syncData();
        return () => {
            window.removeEventListener("beforeunload", beforeunloadHandler);
        };
    }, [self?.pendingChanges]);

    const getDocument = () => {
        return <MultiTabContainer />;
    };

    return (
        <>
            {companyOptionsOpen ? (
                <CompanyOptions />
            ) : (
                <Layout leftPane={<ReturnTreeViewer />}>{getDocument()}</Layout>
            )}
        </>
    );
};

export default PremiumTax;
