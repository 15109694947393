import { CircularProgress, Typography } from "@mui/material";
import React, { FC, PropsWithChildren } from "react";
import "./Loader.scss";

export type LoaderProps = {
    color?: string;
    progress?: number;
    size?: number;
};

const Loader: FC<PropsWithChildren<LoaderProps>> = ({ color, progress, size }) => {
    return (
        <div className="loader-container">
            <CircularProgress 
                sx={{ color: color }} 
                size={ size || 40 }
                variant={progress? "determinate" : "indeterminate"} 
                value={progress}
            />
            {!!progress && (
                <Typography
                    variant="caption"
                    className="loader-text"
                >
                    {`${Math.round(progress)}%`}
                </Typography>
            )}
        </div>
    );
};

export default Loader;
