import { ActionRecord } from "src/types";
import { BaseAPIService } from "./BaseApiService";

export class AccountAdminActionsReportService {
    private static _instance: AccountAdminActionsReportService;
    private readonly BASE_PATH: string = "/api";

    constructor() {}

    private api: BaseAPIService = BaseAPIService.getInstance();

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    getActionsReport() {
        return this.api.get<ActionRecord[]>(this.getPath("/actions-report"));
    }

    public static getInstance(): AccountAdminActionsReportService {
        if (!AccountAdminActionsReportService._instance) {
            AccountAdminActionsReportService._instance =
                new AccountAdminActionsReportService();
        }
        return AccountAdminActionsReportService._instance;
    }
}
