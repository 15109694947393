import { get } from "http";
import styled from "styled-components";

interface StyledLayoutPaneProps {
    position: "left" | "right" | "top" | "bottom";
    pinned: boolean;
    expanded: boolean;
    size: number;
    zIndex?: number;
}

const getPosition = (
    props: StyledLayoutPaneProps & { targetPosition: string }
) => {
    let { pinned, position, size, expanded, targetPosition } = props;
    if (pinned || position !== targetPosition) return "auto";
    return expanded ? "0" : `-${size}px`;
};

const StyledLayoutPane = styled.div<StyledLayoutPaneProps>`
    display: flex;
    flex-direction: column;
    position: ${({ pinned }) => (pinned ? "relative" : "absolute")};
    top: ${(props) => getPosition({ ...props, targetPosition: "top" })};
    left: ${(props) => getPosition({ ...props, targetPosition: "left" })};
    right: ${(props) => getPosition({ ...props, targetPosition: "right" })};
    bottom: ${(props) => getPosition({ ...props, targetPosition: "bottom" })};
    width: ${({ size, position }) =>
        position === "left" || position === "right" ? `${size}px` : "100%"};
    min-width: ${({ size, position }) =>
        position === "left" || position === "right" ? `${size}px` : "100%"};
    height: ${({ size, position }) =>
        position === "top" || position === "bottom" ? `${size}px` : "100%"};
    background-color: white;
    transition: top 0.3s, left 0.3s, right 0.3s;
    z-index: ${({ zIndex }) => (zIndex ? zIndex + 90 : undefined)};
    box-shadow: ${({ pinned }) =>
        pinned ? "none" : "0 0 10px rgba(0, 0, 0, 0.5)"};
`;

export const StyledLayoutPaneHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    background-color: #354560;
    color: white;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray[800]}`};
`;

// ===== LayoutPaneHandle =====

interface StyledLayoutPaneHandleProps {
    position: "left" | "right" | "top" | "bottom";
    size: number;
    pinned: boolean;
}

function getHandlePosition(
    props: StyledLayoutPaneHandleProps & { targetPosition: string }
) {
    let { position, targetPosition } = props;
    switch (position) {
        case "left":
            return targetPosition === "right" ? 0 : undefined;
        case "right":
            return targetPosition === "left" ? 0 : undefined;
        case "top":
            return targetPosition === "bottom" ? 0 : undefined;
        case "bottom":
            return targetPosition === "top" ? 0 : undefined;
    }
}

function getHandleCursor(position: string) {
    switch (position) {
        case "left":
        case "right":
            return "ew-resize";
        case "top":
        case "bottom":
            return "ns-resize";
    }
}

export const StyledLayoutPaneHandle = styled.div<StyledLayoutPaneHandleProps>`
    position: absolute;
    cursor: ${({ position }) => getHandleCursor(position)};
    width: ${({ position, size }) =>
        position === "left" || position === "right" ? `${size}px` : "100%"};
    height: ${({ position, size }) =>
        position === "top" || position === "bottom" ? `${size}px` : "100%"};
    right: ${(props) =>
        getHandlePosition({ ...props, targetPosition: "right" })};
    left: ${(props) => getHandlePosition({ ...props, targetPosition: "left" })};
    top: ${(props) => getHandlePosition({ ...props, targetPosition: "top" })};
    bottom: ${(props) =>
        getHandlePosition({ ...props, targetPosition: "bottom" })};
    background-color: ${({ theme, pinned }) =>
        pinned ? theme.colors.gray[900] : theme.palette.transparent};
    &:hover {
        background-color: ${({ theme }) => theme.colors.indigo_dye[700]};
        transition: background-color 0.5s;
    }
`;

export default StyledLayoutPane;

// LayoutPaneToggleExpand

interface StyledLayoutPaneToggleProps {
    position: "left" | "right" | "top" | "bottom";
    size: number;
    handleSize: number;
}

function getTogglePosition(
    props: StyledLayoutPaneToggleProps & { targetPosition: string }
) {
    let { position, handleSize, targetPosition } = props;

    if (position === "left") {
        if (targetPosition === "left") {
            return `${handleSize}px`;
        }
        if (targetPosition === "top") {
            return "20%";
        }
    }

    if (position === "right") {
        if (targetPosition === "right") {
            return `${handleSize}px`;
        }
        if (targetPosition === "top") {
            return "20%";
        }
    }

    if (position === "top") {
        if (targetPosition === "top") {
            return `${handleSize}px`;
        }
        if (targetPosition === "left") {
            return "80%";
        }
    }

    if (position === "bottom") {
        if (targetPosition === "bottom") {
            return `${handleSize}px`;
        }
        if (targetPosition === "left") {
            return "20%";
        }
    }
}

export const StyledLayoutPaneToggle = styled.div<StyledLayoutPaneToggleProps>`
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    top: ${(props) => getTogglePosition({ ...props, targetPosition: "top" })};
    left: ${(props) => getTogglePosition({ ...props, targetPosition: "left" })};
    right: ${(props) =>
        getTogglePosition({ ...props, targetPosition: "right" })};
    bottom: ${(props) =>
        getTogglePosition({ ...props, targetPosition: "bottom" })};
    position: absolute;
    background-color: #354560;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;

    border-radius: ${({ position }) => {
        switch (position) {
            case "left":
                return "0 50% 50% 0";
            case "right":
                return "50% 0 0 50%";
            case "top":
                return "0 0 50% 50%";
            case "bottom":
                return "50% 50% 0 0";
            default:
                return "50%";
        }
    }};
`;
