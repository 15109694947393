import React, { FC } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { FixMeLater } from "src/types";

interface PremiumCompanyOptionsProps {
    formData: FixMeLater;
    onFormDataChange: (newData: FixMeLater) => void;
}

const PremiumCompanyOptions: FC<PremiumCompanyOptionsProps> = ({
    formData,
    onFormDataChange,
}) => {
    const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        const fieldName = name.split(".")[1];
        onFormDataChange({
            premium: {
                ...formData?.jsonOptions.premium,
                [fieldName]: checked,
            },
        });
    };

    return (
        <>
            <FormControlLabel
                control={
                    <Switch
                        checked={
                            formData?.jsonOptions?.premium?.qualifiedForRHOInCO
                        }
                        onChange={handleToggleChange}
                        name="premium.qualifiedForRHOInCO"
                    />
                }
                label="Qualified for RHO in CO"
            />
            <FormControlLabel
                control={
                    <Switch
                        checked={
                            formData?.jsonOptions?.premium?.calcNJPremiumsAccordingNJSA
                        }
                        onChange={handleToggleChange}
                        name="premium.calcNJPremiumsAccordingNJSA"
                    />
                }
                label="Calc NJ Premiums according N.J.S.A. 54:18A-6"
            />
        </>
    );
};

export default PremiumCompanyOptions;
