export interface WebSocketEvent {
    eventType: WEBSOCKET_EVENT_TYPES;
    payload: WebSocketPayload;
    productInfo: WebSocketProductInfo;
    sessionId: string;
}

export interface WebSocketPayload {
    progress: number;
}

export interface WebSocketProductInfo {
    companyId: number;
    jurisdiction: string;
    year: number;
}

export enum WEBSOCKET_EVENT_TYPES {
    TASK_PROGRESS = "TASK_PROGRESS",
}