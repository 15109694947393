import React from "react";
import { DashboardWrapper } from "./Dashboard.styled";
import { Box, Button, Grid, Icon, Typography } from "@mui/material";
import { Gauge } from "@mui/x-charts/Gauge";
import { User } from "@auth0/auth0-react";
import { useAppSelector } from "src/hooks";
import DashboardCard from "./DashboardCard/DashboardCard";
import {
    AgentUsageReport,
    NewsCarousel,
    PreparerStatusPieChart,
    ReturnStatusBarChart,
} from "./Widgets";
import { Grading, Help, Person, School } from "@mui/icons-material";


const Dashboard: React.FC = () => {
    const user: User | undefined = useAppSelector((state) => state?.Self.value);

    const showDashboardGrid = false;

    return (
        <DashboardWrapper data-id="dashboard-wrapper">
            <Typography variant="h6" className="title">
                Hello {user?.firstName}, Welcome to Stratus!
            </Typography>

            {showDashboardGrid && (
                <Grid
                    container
                    spacing={2}
                    flexWrap={"wrap-reverse"}
                    justifyContent="center"
                >
                    {/* COLUMN 1 */}
                    <Grid item xs={12} lg={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <AgentUsageReport />
                            </Grid>
                            <Grid item xs={12}>
                                <PreparerStatusPieChart />
                            </Grid>
                            <Grid item xs={12}>
                                <ReturnStatusBarChart />
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* COLUMN 2 */}
                    <Grid item xs={12} lg={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <NewsCarousel />
                            </Grid>
                            <Grid item xs={12}>
                                <DashboardCard
                                    title="Quick Links"
                                    subheader=""
                                    actions={[]}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "start",
                                        }}
                                    >
                                        <Button
                                            variant="text"
                                            color="primary"
                                            startIcon={<Person />}
                                        >
                                            Profile
                                        </Button>
                                        <Button
                                            variant="text"
                                            color="primary"
                                            startIcon={<Help />}
                                        >
                                            Help/Support
                                        </Button>
                                        <Button
                                            variant="text"
                                            color="primary"
                                            startIcon={<School />}
                                        >
                                            Documentation
                                        </Button>
                                        <Button
                                            variant="text"
                                            color="primary"
                                            startIcon={<Grading />}
                                        >
                                            Activity Log
                                        </Button>
                                    </Box>
                                </DashboardCard>
                            </Grid>

                            <Grid item xs={12}>
                                <DashboardCard
                                    title="Completed Tasks"
                                    subheader=""
                                    actions={[
                                        {
                                            text: "See tasks",
                                            cb: () => {
                                                console.log("View All Clicked");
                                            },
                                        },
                                    ]}
                                >
                                    <Box
                                        sx={{
                                            height: 200,
                                        }}
                                    >
                                        <Gauge
                                            value={22}
                                            valueMax={36}
                                            startAngle={0}
                                            endAngle={360}
                                            innerRadius="80%"
                                            outerRadius="100%"
                                            text={({ value, valueMax }) =>
                                                `${value} / ${valueMax}`
                                            }
                                        />
                                    </Box>
                                </DashboardCard>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </DashboardWrapper>
    );
};

export default Dashboard;
