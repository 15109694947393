import React, { FC } from "react";
import { TablePaginationProps } from "@mui/material";
import Pagination from '@mui/material/Pagination';
import { gridPageCountSelector, useGridApiContext, useGridSelector, GridPagination } from "@mui/x-data-grid";

const MuiPagination: FC<Pick<TablePaginationProps, "page" | "onPageChange" | "className">> = ({
    page,
    onPageChange,
    className,
}) => {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    
    return (
        <Pagination
            className={className}
            count={pageCount}
            page={page + 1}
            onChange={(event, newPage) => {
                onPageChange(event as any, newPage - 1);
            }}
        />
    );
  }

const NumberedPagination: FC = (props) => {
    return <GridPagination ActionsComponent={MuiPagination} {...props} />;
}

export default NumberedPagination;
