// move this file to src/utils/ folder
import dayjs from "dayjs";

export const getModuleId = (product, company, state) => {
    if (product?.productName === "FormsPlus") {
        switch (company?.companySubType) {
            case "LIFE":
            case "HMO":
            case "HEALTH":
                return "1";
            case "PC":
            case "RRG":
            case "TITLE":
                return "2";
            default:
                return "-1";
        }
    }

    if (product?.productName === "Premium") {
        switch (company?.companySubType) {
            case "LIFE":
                return "7";
            case "PC":
                return "6";
            case "HMO":
                return "10";
            case "RRG":
                return "14";
            case "TITLE":
                return "16";
            case "HEALTH":
                return "36";
            default:
                return "-1";
        }
    }

    if (product?.productName === "Municipal") {
        switch (state) {
            case "AL":
                return "29";
            case "FL":
                return "30";
            case "GA":
                return "31";
            case "IL":
                return "32";
            case "KY":
                return "33";
            case "LA":
                return "34";
            case "SC":
                return "35";
            case "NY":
                return "43";
            case "ND":
                return "44";
            case "NJ":
                return "45";
            default:
                return "-1";
        }
    }

    return "-1";
};

export const getScheduleId = (product, quarter) => {
    if (product?.productName === "Municipal") {
        switch (quarter) {
            case "Qtr 1":
                return 27571;
            case "Qtr 2":
                return 27572;
            case "Qtr 3":
                return 27573;
            case "Qtr 4":
                return 27574;
            case "Reconciliation":
                return 27575;
            default:
                return 0;
        }
    }

    return 2757;
}

export const getQuarterId = (quarter) => {
    switch (quarter) {
        case "Qtr 1":
            return 92;
        case "Qtr 2":
            return 93;
        case "Qtr 3":
            return 94;
        case "Qtr 4":
            return 95;
        default:
            return 96;
    }
}

export const getQuarterLabel = (quarter) => {
    switch (quarter) {
        case 92:
            return "Qtr 1";
        case 93:
            return "Qtr 2";
        case 94:
            return "Qtr 3";
        case 95:
            return "Qtr 4";
        case 96:
            return "Reconciliation";
    }
}

export const formatIsoDate = (isoDate) => {
    const date = new Date(isoDate);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export const getDateOrDefault = (date) => {
    return date !== undefined &&
        date !== null &&
        typeof date === "string" &&
        date !== "null" &&
        date !== ""
        ? formatIsoDate(date)
        : "N/A";
};

export const formatDateTime = (date) => {
    return dayjs(date).format("MM/DD/YYYY hh:mm a");
};

export const formatFilterValue = (value) => {
    if (Array.isArray(value)) {
        return JSON.stringify(value);
    }

    if (value instanceof Date) {
        return value.toISOString();
    }

    return value;
}

export const getStringValueOrDefault = (value) => {
    return value !== null &&
        value !== "null" &&
        value !== undefined &&
        value !== ""
        ? value
        : "N/A";
};

export const replaceUrlParam = (paramName, paramValue) => {
    var queryParams = new URLSearchParams(window.location.search);

    if (!paramValue) queryParams.delete(paramName);
    else queryParams.set(paramName, paramValue);

    history.replaceState(null, "", "?" + queryParams.toString());
};

export const getFirstPathSegment = () => {
    // Get the path from the URL
    var pathArray = window?.location?.pathname?.split("/");

    // Filter out any empty elements or the base path
    var filteredPathArray = pathArray?.filter(function (element) {
        return element !== "";
    });

    // Return the first path segment
    return filteredPathArray?.length > 0 ? filteredPathArray?.[0] : "";
};
