import { useEffect, useState } from "react";

export const useDebugMode = () => {
    const [debugMode, setDebugMode] = useState(false);
  
    useEffect(() => {
      const handleGlobalChange = () => {
        setDebugMode((window as any).TRITECH?.DEBUG?.DISPLAY_FIELD_IDS);
      };
  
      window.addEventListener('debugModeChange', handleGlobalChange);
  
      handleGlobalChange();
  
      return () => {
        window.removeEventListener('debugModeChange', handleGlobalChange);
      };
    }, []);
  
    return debugMode;
  };