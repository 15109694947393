import { OverrideState } from "./ReturnTemplate.types";

export interface RowDTO {
    rowId: number;
    currentIndex: number;
    cellGroups: CellGroupDTO[];
    displayOrder: number;
}

export interface CellGroupDTO {
    cells: CellDTO[];
}

export interface CellDTO {
    cellType: CellType;
    v: string;
    ov: string;
    column: number;
    scheduleId: number;
    alignment: CellAlignment;
    overrideState: OverrideState;
    editable?: boolean;
    formatType?: BaseFormatType;
    format?: string;
    textHyperlinkType?: CellHyperlink;
    decimalPrecision?: number;
    excludedCharacters?: string[];
}


export interface ColumnTemplateDTO {
    field: string;
    columnTemplate: ColumnTemplate;
}

export interface DataGridRow {
    id: number;
}


export interface ScheduleTemplateDTO {
    columnTemplateDTOs: ColumnTemplateDTO[];
    rowDTOs: RowDTO[];
    scheduleName: string;
    totalRowsNum: number;
    displayTotalRow: boolean;
    queryJurisdiction?: number;
}


export interface ColumnTemplate {
    id: number;
    name: string;
    height: number;
    width: number;
    label: boolean;
    scheduleId: number;
    overrideState: OverrideState;
    cellType: CellType;
    alignment: CellAlignment;
    formatType: BaseFormatType;
    format: string;
    decimalPrecision?: number;
    excludedCharacters?: string[];
}

export enum CellType {
    NUMBER = "NUMBER",
    TEXT = "TEXT",
    CHECK = "CHECK",
    LABEL = "LABEL",
    ADDRESS_SELECT = "ADDRESS_SELECT",
    DROPDOWN = "DROPDOWN",
    DATE = "DATE"
}

export enum CellAlignment {
    LEFT = "LEFT",
    CENTER = "CENTER",
    RIGHT = "RIGHT"
}

export enum BaseFormatType {
    NONE = "NONE",
    LABEL = "LABEL",
    HEADER = "HEADER",
    FOOTER = "FOOTER",
    FORMAT_DOUBLE = "FORMAT_DOUBLE",
    FORMAT_PERCENT = "FORMAT_PERCENT",
    FORMAT_CURRENCY = "FORMAT_CURRENCY",
    FORMAT_TEXT = "FORMAT_TEXT",
    FORMAT_DATE = "FORMAT_DATE",
    FORMAT_NUMBER_TEXT = "FORMAT_NUMBER_TEXT",
    X = "X",
    DARKEN = "DARKEN",
    Y_OR_N = "Y_OR_N",
    CHECKMARK = "CHECKMARK",
    CIRCLE = "CIRCLE"
}

export enum CellHyperlink {
    TEXT = "TEXT",
    HYPERLINK = "HYPERLINK",
    EMAIL = "EMAIL"
}
