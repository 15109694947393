import React, { PropsWithChildren } from "react";
import {
    StyledButton,
    StyledCard,
    StyledCardActions,
    StyledCardContent,
    StyledCardHeader,
} from "./FrameCard.styled";

export interface FrameCardProps {
    title?: string;
    actions?: Array<{
        text: string;
        onClick: () => void;
    }>;
    disabled?: boolean;
}

export const FrameCard: React.FC<PropsWithChildren<FrameCardProps>> = ({
    children,
    title,
    actions,
    disabled,
}) => {
    return (
        <StyledCard>
            {title && (
                <StyledCardHeader
                    title={title}
                    titleTypographyProps={{ variant: "body1" }}
                />
            )}
            <StyledCardContent>{children}</StyledCardContent>
            {actions && actions.length > 0 && (
                <StyledCardActions>
                    {actions.map(({ text, onClick }) => (
                        <StyledButton
                            key={text}
                            onClick={onClick}
                            disabled={disabled}
                        >
                            {text}
                        </StyledButton>
                    ))}
                </StyledCardActions>
            )}
        </StyledCard>
    );
};
