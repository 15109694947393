import styled from "styled-components";
import colors from "src/styles/colors.scss";

interface StyledSignatureBackgroundProps {
    $isHidden: boolean;
}

interface StyledSignatureFieldProps {
    $isLocked: boolean | undefined;
}

interface StyledSignatureImgProps {
    $imageUrl: string | undefined;
}

export const StyledSignatureBackground = styled.div<StyledSignatureBackgroundProps>`
    background-color: ${(props) => (
        props.$isHidden ? 
        "transparent" :
        colors.textFieldOverrideAlwaysBackgroundColor 
    )};
    border-radius: 6px;
    position: absolute;
`

export const StyledSignatureField = styled.div<StyledSignatureFieldProps>`
    align-items: center;
    display: flex;
    border-radius: 6px;
    position: absolute;
    z-index: 10;
    cursor: ${(props) => props.$isLocked ? "not-allowed": "pointer"};
`

export const StyledSignatureImg = styled.div<StyledSignatureImgProps>`
    height: 100%;
    width: 100%;
    object-fit: contain;
    background-image: ${(props) => `url(${props.$imageUrl})`};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`