import {
    Autocomplete,
    Button,
    Modal,
    TextField,
    Typography,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";
import Loader from "src/components/Loader/Loader";
import ModalError from "src/components/ModalError/ModalError";
import { CompanySubTypes } from "src/constants/CompanySubTypes";
import {
    federalIdRegex,
    naicGroupRegex,
    naicRegex,
    zipRegex,
} from "src/constants/Regex";
import { USStateAbbreviations } from "src/constants/States";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { CompanyService } from "src/services";
import { FixMeLater } from "src/types";
import {
    StyledFlexContainer,
    StyledForm,
    StyledFormContainer,
    StyledModalContainer,
} from "../../../../AccountAdmin.styled";
import "./CompanyInformationModal.scss";

interface CompanyInformationModalProps {
    open: boolean;
    onClose: () => void;
}

const CompanyInformationModal: FC<CompanyInformationModalProps> = ({
    open,
    onClose,
}) => {
    const companyService = CompanyService.getInstance();
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value,
    );
    const selectedAccountCompany = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccountCompany,
    );
    const selectedAccountCompanies = useAppSelector(
        (state) =>
            state?.[product?.productName].value?.selectedAccountCompanies,
    );
    const selectedAccountSubWithCompanies = useAppSelector(
        (state) =>
            state?.[product?.productName].value
                ?.selectedAccountSubAccountsWithCompanies,
    );
    const [subAccountListWithEmpty, setSubAccountListWithEmpty] =
        useState<FixMeLater>([]);

    useEffect(() => {
        setSubAccountListWithEmpty([
            {
                id: "",
                name: "N/A",
                parentId: "",
            },
            ...(selectedAccountSubWithCompanies || []),
        ]);
        
    }, [selectedAccountSubWithCompanies]);

    const dispatch = useAppDispatch();

    const initialFormData = {
        companyName: selectedAccountCompany?.name || "",
        companyAbbreviation: selectedAccountCompany?.coabbr || "",
        federalId: selectedAccountCompany?.federalId || "",
        naic: selectedAccountCompany?.naic || "",
        naicGroup: selectedAccountCompany?.naicGroup || "",
        stateOfIncorporation: selectedAccountCompany?.stateOfDomicile || "",
        companyType: selectedAccountCompany?.companyType || "",
        companySubType: selectedAccountCompany?.companySubType || "",
        address1: selectedAccountCompany?.address1 || "",
        address2: selectedAccountCompany?.address2 || "",
        city: selectedAccountCompany?.addressCity || "",
        state: selectedAccountCompany?.addressState || "",
        zipCode: selectedAccountCompany?.addressZip || "",
        subAccount: selectedAccountCompany?.subAccount?.id || "",
    };

    const [formData, setFormData] = useState(initialFormData);

    const [validationErrors, setValidationErrors] = useState({
        naic: "",
        naicGroup: "",
        federalId: "",
        zipCode: "",
    });

    useEffect(() => {
        setFormData(initialFormData);
    }, [selectedAccountCompany]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (name === "naic" && !naicRegex.test(value)) {
            setValidationErrors({
                ...validationErrors,
                naic: "NAIC should be 5 digits.",
            });
        } else if (name === "naicGroup" && !naicGroupRegex.test(value)) {
            setValidationErrors({
                ...validationErrors,
                naicGroup: "NAIC Group should be 4 digits.",
            });
        } else if (name === "federalId" && !federalIdRegex.test(value)) {
            setValidationErrors({
                ...validationErrors,
                federalId: "FEIN should be 9 digits.",
            });
        } else if (name === "zipCode" && !zipRegex.test(value)) {
            setValidationErrors({
                ...validationErrors,
                zipCode: "Zip Code should be a 5-digit number.",
            });
        } else {
            setValidationErrors({ ...validationErrors, [name]: "" });
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            setIsLoading(true);
            let userSelectedSA;
            if (formData.subAccount === null) {
                userSelectedSA = {
                    id: "",
                    name: "N/A",
                    parentId: "",
                };
            } else {
                userSelectedSA = selectedAccountSubWithCompanies.find(
                    (s) => s.id === formData.subAccount,
                );
            }

            const updatedCompany = {
                // Use Company type when migrating to TS
                ...selectedAccountCompany,
                name: formData.companyName,
                coabbr: formData.companyAbbreviation,
                federalId: formData.federalId.replace("-", ""),
                naic: formData.naic,
                naicGroup: formData.naicGroup,
                stateOfDomicile: formData.stateOfIncorporation,
                companyType: formData.companyType,
                companySubType: formData.companySubType,
                address1: formData.address1,
                address2: formData.address2,
                addressCity: formData.city,
                addressState: formData.state,
                addressZip: formData.zipCode,
                subAccount: userSelectedSA,
            };

            await companyService.updateCompany(updatedCompany);

            dispatch(
                GlobalStateActions[
                    product?.productName
                ].setSelectedAccountCompany(updatedCompany),
            );

            const updatedSelectedAccountCompanies = [
                ...selectedAccountCompanies,
            ];

            updatedSelectedAccountCompanies.forEach((company, index) => {
                if (company.id === updatedCompany.id) {
                    updatedSelectedAccountCompanies[index] = {
                        ...updatedCompany,
                    };
                }
            });

            dispatch(
                GlobalStateActions[
                    product?.productName
                ].setSelectedAccountCompanies(updatedSelectedAccountCompanies),
            );

            onClose();
            setSnackbarOpen(true);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setError(null);
        onClose();
    };

    return (
        <>
            <Modal open={open} onClose={handleCancel}>
                <div>
                    <StyledModalContainer>
                        <StyledFormContainer>
                            <Typography variant="h5">
                                Edit Company Information
                            </Typography>
                            <StyledForm onSubmit={handleSubmit}>
                                <StyledFlexContainer>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="companyName"
                                        label="Company Name"
                                        name="companyName"
                                        value={formData.companyName}
                                        onChange={handleInputChange}
                                    />
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="companyAbbreviation"
                                        label="Abbreviation of Company"
                                        name="companyAbbreviation"
                                        value={formData.companyAbbreviation}
                                        onChange={handleInputChange}
                                    />
                                </StyledFlexContainer>

                                <Autocomplete
                                    id="subAccount"
                                    options={subAccountListWithEmpty}
                                    getOptionLabel={(option: FixMeLater) =>
                                        option.name
                                    }
                                    value={
                                        subAccountListWithEmpty.find(
                                            (option) =>
                                                option.id ===
                                                formData.subAccount,
                                        ) || null
                                    }
                                    onChange={(
                                        event: any,
                                        newValue: FixMeLater | null,
                                    ) => {
                                        handleInputChange({
                                            target: {
                                                name: "subAccount",
                                                value: newValue
                                                    ? newValue.id
                                                    : "",
                                            },
                                        } as React.ChangeEvent<HTMLInputElement>);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="SubAccount"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                    fullWidth
                                    autoHighlight
                                />

                                <StyledFlexContainer>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="federalId"
                                        label="Federal ID"
                                        name="federalId"
                                        value={formData.federalId}
                                        onChange={handleInputChange}
                                        error={!!validationErrors.federalId}
                                        helperText={validationErrors.federalId}
                                    />
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="naic"
                                        label="NAIC"
                                        name="naic"
                                        value={formData.naic}
                                        onChange={handleInputChange}
                                        error={!!validationErrors.naic}
                                        helperText={validationErrors.naic}
                                    />
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="naicGroup"
                                        label="NAIC Group"
                                        name="naicGroup"
                                        value={formData.naicGroup}
                                        onChange={handleInputChange}
                                        error={!!validationErrors.naicGroup}
                                        helperText={validationErrors.naicGroup}
                                    />
                                </StyledFlexContainer>

                                <StyledFlexContainer>
                                    <Autocomplete
                                        id="stateOfIncorporation"
                                        options={USStateAbbreviations}
                                        value={formData.stateOfIncorporation}
                                        onChange={(
                                            event: any,
                                            newValue: string | null,
                                        ) => {
                                            handleInputChange({
                                                target: {
                                                    name: "stateOfIncorporation",
                                                    value: newValue ?? "",
                                                },
                                            } as React.ChangeEvent<HTMLInputElement>);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="State of Incorporation"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                        fullWidth
                                        autoHighlight
                                    />
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="companyType"
                                        label="Company Type"
                                        name="companyType"
                                        value={formData.companyType}
                                        onChange={handleInputChange}
                                    />
                                    <Autocomplete
                                        id="companySubType"
                                        options={CompanySubTypes}
                                        value={formData.companySubType}
                                        onChange={(
                                            event: any,
                                            newValue: string | null,
                                        ) => {
                                            handleInputChange({
                                                target: {
                                                    name: "companySubType",
                                                    value: newValue ?? "",
                                                },
                                            } as React.ChangeEvent<HTMLInputElement>);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Company Sub-Type"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                        fullWidth
                                        autoHighlight
                                    />
                                </StyledFlexContainer>

                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="address1"
                                    label="Address1"
                                    name="address1"
                                    value={formData.address1}
                                    onChange={handleInputChange}
                                />

                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="address2"
                                    label="Address2"
                                    name="address2"
                                    value={formData.address2}
                                    onChange={handleInputChange}
                                />

                                <StyledFlexContainer>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="city"
                                        label="City"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleInputChange}
                                    />
                                    <Autocomplete
                                        id="state"
                                        options={USStateAbbreviations}
                                        value={formData.state}
                                        onChange={(
                                            event: any,
                                            newValue: string | null,
                                        ) => {
                                            handleInputChange({
                                                target: {
                                                    name: "state",
                                                    value: newValue ?? "",
                                                },
                                            } as React.ChangeEvent<HTMLInputElement>);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="State"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                        fullWidth
                                        autoHighlight
                                    />
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="zipCode"
                                        label="Zip Code"
                                        name="zipCode"
                                        value={formData.zipCode}
                                        onChange={handleInputChange}
                                        error={!!validationErrors.zipCode}
                                        helperText={validationErrors.zipCode}
                                    />
                                </StyledFlexContainer>
                                <StyledFlexContainer justify={"center"}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={
                                            Boolean(validationErrors.naic) ||
                                            Boolean(
                                                validationErrors.naicGroup,
                                            ) ||
                                            Boolean(
                                                validationErrors.federalId,
                                            ) ||
                                            Boolean(validationErrors.zipCode)
                                        }
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        color="secondary"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </StyledFlexContainer>
                            </StyledForm>
                        </StyledFormContainer>
                        {isLoading && <Loader />}
                        {error && <ModalError error={error} />}
                    </StyledModalContainer>
                </div>
            </Modal>
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message="Company details updated successfully!"
                severity="success"
            />
        </>
    );
};

export default CompanyInformationModal;
