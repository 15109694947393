import React, { useEffect } from "react";
import { Product, State, UserOptions } from "src/types";
import "./StateSelector.scss";
import SimpleAutoComplete from "../SimpleAutoComplete/SimpleAutoComplete";
import { useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

interface StateSelectorProps {
    stateChangeFn: (changes: { selectedState: State }) => void;
}
const StateSelector: React.FC<StateSelectorProps> = ({ stateChangeFn }) => {
    
    const [searchParams] = useSearchParams();

    const dispatch = useDispatch();

    const userOptions: UserOptions | undefined = useAppSelector(
        (state) => state.UserOptions.value,
    );

    const product: Product | undefined = useAppSelector(
        (state) => state?.Product?.value,
    );
    const states = useAppSelector((state) => state?.States?.value);

    let lastStateIdFromPreferences;

    if (product) {
        lastStateIdFromPreferences =
            userOptions.productPreferences?.[product?.productName]?.filters
                ?.state;
    }

    if (!product) return null;

    const selectedState: State = useSelector(
        (state) => state?.[product?.productName]?.value?.state,
    );

    const stateOptions = states?.map((st: State) => ({
        value: st,
        label: st.abbrev ?? st.longName,
    }));

    useEffect(() => {
        const stateParam =
            searchParams.get("folderId") ||
            lastStateIdFromPreferences?.toString();
        if (stateParam) {
            let selectedState = states?.find(
                (state: State) => state.id === parseInt(stateParam),
            );
            if (selectedState) {
                dispatch(
                    GlobalStateActions[product?.productName].setState(
                        selectedState,
                    ),
                );
            }
        }
    }, []);

    useEffect(() => {
        const stateParam =
            searchParams.get("folderId") ||
            lastStateIdFromPreferences?.toString();
        if (stateParam) {
            let selectedState = states?.find(
                (state: State) => state.id === parseInt(stateParam),
            );
            if (selectedState) {
                dispatch(
                    GlobalStateActions[product?.productName].setState(
                        selectedState,
                    ),
                );
            }
        }
    }, [states, userOptions]);

    const handleChange = (selectedState: State) => {
        stateChangeFn({
            selectedState,
        });
        dispatch(
            GlobalStateActions[product?.productName].setState(selectedState),
        );
    };

    return (
        <div className="tts-state-selector">
            <SimpleAutoComplete
                icon={null}
                size="small"
                label="States"
                data={stateOptions}
                onSelect={handleChange}
                selected={selectedState}
                data-testid="tts-company-selector"
            />
        </div>
    );
};

export default StateSelector;
