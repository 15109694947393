import React, { FC } from "react";
import { Product, User } from "src/types";
import {
    StyledListItem,
    StyledListItemHeader,
} from "../ExtensiveSearchModal.styled";
import PersonIcon from "@mui/icons-material/Person";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";

interface UserSearchResultProps {
    user: User;
    closeModal: () => void;
}

const UserSearchResult: FC<UserSearchResultProps> = ({ user, closeModal }) => {
    const product: Product = useAppSelector(
        (state) => state.Product.value,
    ) as Product;

    const accounts = useAppSelector(
        (state) => state?.[product?.productName].value?.accounts || [],
    );

    const dispatch = useAppDispatch();

    const handleClick = () => {
        const account = accounts.find(
            (account) => account?.id === user?.accounts?.[0], // If user is in multiple accounts, the first account is selected
        );

        dispatch(
            GlobalStateActions[product?.productName]?.setSelectedAccount(
                account,
            ),
        );

        dispatch(
            GlobalStateActions[product?.productName]?.setSelectedComponent(
                "Users",
            ),
        );

        dispatch(
            GlobalStateActions[product?.productName]?.setSelectedAccountUser(
                user,
            ),
        );

        closeModal();
    };

    return (
        <StyledListItem itemType="user" onClick={handleClick}>
            <div className="list-item-icon">
                <PersonIcon />
            </div>
            <div>
                <StyledListItemHeader>User</StyledListItemHeader>
                <div>
                    Name: {user?.firstName} {user?.lastName}
                </div>
                <div>Email: {user?.email}</div>
            </div>
        </StyledListItem>
    );
};

export default UserSearchResult;
