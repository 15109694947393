export type SupportingWorkshetUpdateRequest = {
    supportingWorkSheetIdDTO: SupportingWorkshetRequest,
    supportingWorkSheet: SupportingWorksheetResponse
}

export type SupportingWorkshetRequest = {
    companyId: number;
    productId: number;
    taxYearId: number;
    folderId: string;
    moduleId: string;
    returnId: string;
    retalFolderId: string;
    supportingWorkSheetId: number;
};

export type SupportingWorksheetResponse = {
    pageID: string;
    width: number;
    height: number;
    title: string;
    maxRows: number;
    columns: SupportingWorksheetColumn[];
    rows: SupportingWorksheetRow[];
};

export type SupportingWorksheetColumn = {
    colNumber: string;
    description: string;
    width: number;
    height: number;
    other: string;
};

export type SupportingWorksheetRow = {
    rowType: SupportingWorksheetRowType;
    cells: SupportingWorksheetCell[];
};

export enum SupportingWorksheetRowType {
    USER_DEFINED = 'USER_DEFINED',
    SYSTEM_DEFINED = 'SYSTEM_DEFINED',
}

export type SupportingWorksheetCell =
    | SupportingWorksheetTextField
    | SupportingWorksheetNumberField
    | SupportingWorksheetLabelField;

export type SupportingWorksheetBaseCell = {
    cellType: SupportingWorksheetCellType;
    colNumber: string;
    fieldID: string;
    fieldNumber: string;
    hyperlink: string;
    tooltip: string;
    defaultValue: string;
    override: string;
    isEditable: boolean;
    formatType: string;
    formatString: string;
};

export type SupportingWorksheetTextField = SupportingWorksheetBaseCell & {
    cellType: SupportingWorksheetCellType.TextField;
    dropdownType: string;
};

export type SupportingWorksheetNumberField = SupportingWorksheetBaseCell & {
    cellType: SupportingWorksheetCellType.NumberField;
    empty: string;
};

export type SupportingWorksheetLabelField = SupportingWorksheetBaseCell & {
    cellType: SupportingWorksheetCellType.LabelField;
    underline: string;
    bold: string;
    visible: string;
    dontPrint: string;
    alignment: string;
    tag: string;
};

export enum SupportingWorksheetCellType {
    TextField = "TextField",
    NumberField = "NumberField",
    LabelField = "LabelField",
}