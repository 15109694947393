import React, { FC } from "react";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { Box, FormControl, MenuItem, Select, Switch } from "@mui/material";
import { USStateAbbreviations } from "src/constants/States";
import { SelectChangeEvent } from "@mui/material";

interface JurisdictionOptionsProps {
    formData: any;
    onFormDataChange: (newData: any) => void;
}

const JurisdictionOptions: FC<JurisdictionOptionsProps> = ({
    formData,
    onFormDataChange,
}) => {
    // Handler for state-based switches
    const handleStateToggleChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        state: string,
        option: string
    ) => {
        onFormDataChange({
            [state]: {
                ...formData[state],
                [option]: event.target.checked,
            },
        });
    };

    // Handler for state-based dropdown (retaliatory returns)
    const handleDropdownChange = (event: SelectChangeEvent, state: string) => {
        onFormDataChange({
            [state]: {
                ...formData[state],
                retaliatoryReturnsDisplay: event.target.value as string,
            },
        });
    };

    // DataGrid columns definition
    const columns: GridColDef[] = [
        { field: "state", headerName: "State", width: 150 },
        {
            field: "dollarsAndCentsDisplay",
            headerName: "Dollars and Cents Display",
            renderCell: (params) => (
                <Switch
                    checked={params.value}
                    onChange={(event) =>
                        handleStateToggleChange(
                            event,
                            params.row.state,
                            "dollarsAndCentsDisplay"
                        )
                    }
                />
            ),
            width: 250,
        },
        {
            field: "defaultOverpaymentHandling",
            headerName: "Default Overpayment Handling",
            renderCell: (params) => (
                <Switch
                    checked={params.value}
                    onChange={(event) =>
                        handleStateToggleChange(
                            event,
                            params.row.state,
                            "defaultOverpaymentHandling"
                        )
                    }
                />
            ),
            width: 250,
        },
        {
            field: "dividendOptions",
            headerName: "Dividend Options",
            renderCell: (params) => (
                <Switch
                    checked={params.value}
                    onChange={(event) =>
                        handleStateToggleChange(
                            event,
                            params.row.state,
                            "dividendOptions"
                        )
                    }
                />
            ),
            width: 200,
        },
        {
            field: "printOptions",
            headerName: "Print Options",
            renderCell: (params) => (
                <Switch
                    checked={params.value}
                    onChange={(event) =>
                        handleStateToggleChange(
                            event,
                            params.row.state,
                            "printOptions"
                        )
                    }
                />
            ),
            width: 200,
        },
        {
            field: "municipalOptions",
            headerName: "Municipal Options",
            renderCell: (params) => (
                <Switch
                    checked={params.value}
                    onChange={(event) =>
                        handleStateToggleChange(
                            event,
                            params.row.state,
                            "municipalOptions"
                        )
                    }
                />
            ),
            width: 200,
        },
        {
            field: "retaliatoryReturnsDisplay",
            headerName: "Retaliatory Returns Display",
            renderCell: (params) => (
                <FormControl fullWidth>
                    <Select
                        value={params.value}
                        onChange={(event) =>
                            handleDropdownChange(event, params.row.state)
                        }
                    >
                        <MenuItem value="both">
                            Display both Returns and Worksheets
                        </MenuItem>
                        <MenuItem value="returnsOnly">
                            Display Returns only
                        </MenuItem>
                        <MenuItem value="worksheetsOnly">
                            Display Worksheets only
                        </MenuItem>
                    </Select>
                </FormControl>
            ),
            width: 300,
        },
    ];

    // Prepare rows for DataGrid
    const rows = USStateAbbreviations.map((state, index) => ({
        id: index,
        state,
        ...formData[state],
    }));

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                width: "90%",
            }}
        >
            <DataGridPremium
                rows={rows}
                columns={columns}
                checkboxSelection={false}
                disableRowSelectionOnClick
                autoHeight
            />
        </Box>
    );
};

export default JurisdictionOptions;
