import { BaseAPIService } from "src/services/BaseApiService";
import { FixMeLater } from "src/types";

export class ExtensiveSearchService {
    private static _instance: ExtensiveSearchService;
    private readonly BASE_PATH: string = "/api/search";
    private api: BaseAPIService = BaseAPIService.getInstance();

    constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    search(query: string): FixMeLater {
        return this.api.get<FixMeLater>(this.getPath(`?query=${query}`));
    }

    public static getInstance(): ExtensiveSearchService {
        if (!ExtensiveSearchService._instance) {
            ExtensiveSearchService._instance = new ExtensiveSearchService();
        }
        return ExtensiveSearchService._instance;
    }
}
