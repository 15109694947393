// This theme will be used in the whole application via ThemeProvider by styled-components

const COLORS = {
    indigo_dye: {
        100: "#0b0e13",
        200: "#151b26",
        300: "#202939",
        400: "#2a374c",
        500: "#354560",
        600: "#4e668e",
        700: "#738ab2",
        800: "#a2b1cc",
        900: "#d0d8e5",
    },
    gray: {
        100: "#1a1a1a",
        200: "#333333",
        300: "#4d4d4d",
        400: "#666666",
        500: "#808080",
        600: "#999999",
        700: "#b3b3b3",
        800: "#cccccc",
        900: "#e6e6e6",
    },
    true_blue: "#0466C8",
    sapphire: "#0353A4",
    yale_blue: "#023E7D",
    oxford_blue: "#002855",
    oxford_blue_dark: "#001845",
    oxford_blue_darker: "#001233",
    delft_blue: "#33415C",
    powder_blue: "#8DA9C4",
    paynes_gray: "#5C677D",
    slate_gray: "#7D8597",
    cool_gray: "#979DAC",
    white: "#ffffff",
    black: "#000000",
    transparent: "transparent",
};

const DARK_PALETTE = {
    primary: COLORS.indigo_dye[500],
    secondary: COLORS.indigo_dye[700],
    textColor: COLORS.white,
};

export const THEME = {
    palette: { ...DARK_PALETTE },
    colors: { ...COLORS },
    shadows: {

    }
};
