import styled from "styled-components";

export const StyledPdfViewerContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    align-items: center;
    height: 100%;
    background-color: #f8f8f8;
    width: 100%;
    max-width: 100%;
    position: relative;
`;

export const StyledPdfViewerPagesContainer = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 100%;
    width: 100%;
    position: relative;
    min-height: 48px;
    max-height: 48px;
`;

export const StyledPdfViewerMain = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    position: relative;
    overflow-x: scroll;
`;

export const StyledToolbarContainer = styled.div`
    position: relative;
    /* right: 0; */
    /* top: 50%;
    transform: translateY(-50%); */
    z-index: 100;
    background-color: #354560;
    height: 100%;
    border-left: 1px solid #e0e0e0;
    width: 50px;
`;

export const StyledNotesContainer = styled.div`
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 30%;
    min-width: 250px;
    border-left: 1px solid #e0e0e0;
    padding: 16px;

`;
