import { Box } from "@mui/material";
import {
    GridCellModesModel,
    GridCellParams,
    GridColDef,
    GridColumnResizeParams,
    GridFilterModel,
    GridPaginationModel,
    GridRowModel,
    useGridApiRef,
} from "@mui/x-data-grid-premium";
import React, {
    FC,
    MouseEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import Loader from "src/components/Loader/Loader";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AllocatorService } from "src/services";
import {
    ALLOCATOR_REPORT_PAGE_SIZES,
    ALLOCATOR_REPORT_TYPES,
    ALLOCATOR_SEARCH_PARAMS,
    AllocatorFilters,
    AllocatorJob,
    AllocatorJobStatus,
    AllocatorReportColumnsWidth,
    AllocatorReportFilterState,
    AllocatorReportRow,
    AllocatorReportTab,
    ContextMenuPosition,
    CorrectedAddresses,
    FixMeLater,
    JOB_STATUS_TYPES,
    REPORT_EDIT_FIELDS,
} from "src/types";
import { StyledDataGrid } from "../../Allocator.styled";
import "./ReportDataGrid.scss";
import {
    getMappedFilters,
    getParsedColumn,
    getParsedRow,
    getUpdatedCellModesModel,
} from "./ReportDataGrid.util";
import NoDataOverlay from "./components/NoDataOverlay/NoDataOverlay";
import ReportContextMenu from "./components/ReportContextMenu/ReportContextMenu";
import ReportToolbar from "./components/ReportToolbar/ReportToolbar";
import NumberedPagination from "./components/NumberedPagination/NumberedPagination";
import { useSnackbar } from "notistack";
import colors from "src/styles/colors.scss";

const ReportDataGrid: FC = () => {
    const allocatorService = AllocatorService.getInstance();

    const [searchParams, setSearchParams] = useSearchParams();
    const jobId = searchParams.get(ALLOCATOR_SEARCH_PARAMS.JOB_ID);
    const reportId = searchParams.get(ALLOCATOR_SEARCH_PARAMS.REPORT);
    const reportPage = searchParams.get(ALLOCATOR_SEARCH_PARAMS.PAGE);
    const reportSize = searchParams.get(ALLOCATOR_SEARCH_PARAMS.SIZE);

    const [columns, setColumns] = useState<GridColDef[]>([]);
    const [rows, setRows] = useState<GridRowModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [rowCountState, setRowCountState] = useState<number>(0);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
        {
            page: Number(reportPage),
            pageSize: Number(reportSize) || ALLOCATOR_REPORT_PAGE_SIZES.SMALL,
        }
    );
    const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>(
        {}
    );
    const [reportContextMenuPosition, setReportContextMenuPosition] =
        useState<ContextMenuPosition | null>(null);
    const [selectedRow, setSelectedRow] = useState<GridRowModel>({});

    let timeout: ReturnType<typeof setTimeout>;

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const reportTabsLoading: boolean = useAppSelector(
        (state) => state?.[product?.productName]?.value?.reportTabsLoading
    );
    const selectedReportTab: AllocatorReportTab = useAppSelector(
        (state) => state?.[product?.productName]?.value?.selectedReportTab
    );
    const selectedJob: AllocatorJob = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.selectedJob
    );
    const selectedJobStatus: AllocatorJobStatus = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.selectedJobStatus
    );
    const correctedAddresses: CorrectedAddresses = useAppSelector(
        (state) => state?.[product?.productName]?.value?.correctedAddresses
    );
    const reportsFilterState: AllocatorReportFilterState = useAppSelector(
        (state) => state?.[product?.productName]?.value?.reportsFilterState
    );
    const reportColumnsWidth: AllocatorReportColumnsWidth = useAppSelector(
        (state) => state?.[product?.productName]?.value?.reportColumnsWidth
    );
    const toogleFetchReport: boolean = useAppSelector(
        (state) => state?.[product?.productName]?.value?.toogleFetchReport
    );
    const firstRender = useRef<boolean>(true);
    const selectedReportTabFilters = reportsFilterState[String(jobId) + String(reportId)];

    const apiRef = useGridApiRef();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(
            GlobalStateActions[
                product?.productName
            ].setEmptyCorrectedAddresses()
        );
        if (jobId && reportId) {
            setIsLoading(true);
            const filters = getMappedFilters(selectedReportTabFilters);
            const searchPhrase = selectedReportTabFilters?.quickFilterValues?.join(" ");
            fetchReportData(filters, searchPhrase?.length !== 1 ? searchPhrase : "");
        }
    }, [toogleFetchReport]);

    useEffect(() => {
        if (jobId && selectedReportTab && !firstRender?.current) {
            if (
                Number(reportPage) !== paginationModel.page ||
                Number(reportSize) !== paginationModel.pageSize
            ) {
                dispatch(GlobalStateActions[product?.productName].setReportsPageSize({[String(jobId)]: paginationModel.pageSize.toString()}));
                setSearchParams(
                    `?${new URLSearchParams({
                        jobId: selectedJob
                            ? selectedJob.id.toString()
                            : String(jobId),
                        report: selectedReportTab.id.toString(),
                        page: paginationModel.page.toString(),
                        size: paginationModel.pageSize.toString(),
                    })}`
                );
            }
            setIsLoading(true);
            const filters = getMappedFilters(selectedReportTabFilters);
            const searchPhrase = selectedReportTabFilters?.quickFilterValues?.join(" ");
            fetchReportData(filters, searchPhrase?.length !== 1 ? searchPhrase : "");
        } else {
            firstRender.current = false;
        }
    }, [paginationModel]);

    useEffect(() => {
        if (
            Number(reportPage) !== paginationModel.page ||
            Number(reportSize) !== paginationModel.pageSize
        ) {
            dispatch(GlobalStateActions[product?.productName].setReportsPageSize({[String(jobId)]: reportSize || "100"}));
            setPaginationModel({
                page: Number(reportPage),
                pageSize: Number(reportSize) || ALLOCATOR_REPORT_PAGE_SIZES.SMALL,
            });
        }
    }, [reportPage, reportSize]);

    useEffect(() => {
        const parsedRows = rows?.map((row: GridRowModel) =>
            getParsedRow(row, correctedAddresses, row?.id, row?.key)
        );
        setRows(parsedRows);

        const parsedColumns = columns?.map((column: GridColDef) =>
            getParsedColumn(
                column?.field,
                column?.headerName,
                column?.type,
                column?.filterable,
                correctedAddresses,
                selectedJobStatus?.name,
                column["valueOptions"],
                parsedRows,
                reportColumnsWidth[String(reportId)]?.[column?.field]
            )
        );
        setColumns(parsedColumns);
    }, [correctedAddresses]);

    const fetchReportData = async (filters: AllocatorFilters[] = [], searchPhrase: string = "") => {

        try {
            const report = await allocatorService.generateReportData(
                Number(reportId),
                {
                    jobId: Number(jobId),
                    columns: [],
                    filters,
                    searchPhrase,
                },
                paginationModel.page,
                paginationModel.pageSize
            );

            setRowCountState(report?.page?.totalElements);

            dispatch(
                GlobalStateActions[product?.productName].setReportColumns(
                    Object.keys(report?.columns)
                )
            );
            dispatch(
                GlobalStateActions[product?.productName].setSelectedFileName(
                    report?.meta?.filename
                )
            );
            dispatch(
                GlobalStateActions[product?.productName].setSelectedJobStatus(
                    report?.meta?.jobStatus
                )
            );
            dispatch(
                GlobalStateActions[product?.productName].setStateAbbr(
                    report?.meta?.state
                )
            );
            dispatch(
                GlobalStateActions[product?.productName].setDownloadOptions(
                    report?.meta?.downloadOptions
                )
            );
            dispatch(
                GlobalStateActions[product?.productName].setExportOptions(
                    report?.meta?.exportOptions
                )
            );

            const parsedRows = report?.page?.content?.map(
                (row: AllocatorReportRow, index: number) =>
                    getParsedRow(
                        row,
                        correctedAddresses,
                        row?.source_row_id?.value ?? index,
                        row?.[REPORT_EDIT_FIELDS.NORMAL_CITY]?.value +
                            row?.[REPORT_EDIT_FIELDS.NORMAL_STATE]?.value +
                            row?.[REPORT_EDIT_FIELDS.NORMAL_STREET]?.value +
                            row?.[REPORT_EDIT_FIELDS.NORMAL_ZIP]?.value
                    )
            );
            setRows(parsedRows);

            const parsedColumns = Object.entries(report?.columns)?.map(
                ([key, value]) => {
                    return getParsedColumn(
                        key,
                        value?.displayName,
                        value?.type,
                        value?.filterable,
                        correctedAddresses,
                        report?.meta?.jobStatus?.name,
                        report?.meta?.predefinedSearchData?.[key],
                        parsedRows,
                        reportColumnsWidth[String(reportId)]?.[key]
                    );
                }
            );
            setColumns(parsedColumns);
            
            if (selectedReportTab?.name === ALLOCATOR_REPORT_TYPES.ACTIVITY) {
                setTimeout(() => {
                    apiRef?.current?.autosizeColumns({
                        columns: getAutosizedColumnFields(),
                        includeOutliers: false,
                        includeHeaders: false,
                        expand: true 
                    })
                }, 10);
            }
        } catch (error) {
            enqueueSnackbar("Error fetching report data", { variant: "error" });
        } finally {
            dispatch(
                GlobalStateActions[product?.productName].setReportTabsLoading(
                    false
                )
            );
            setIsLoading(false);
        }
    };

    const handleCellClick = useCallback(
        (params: GridCellParams, event: MouseEvent) => {
            if (!params.isEditable) {
                return;
            }
            // Ignore portal
            if (
                (event.target as any).nodeType === 1 &&
                !event.currentTarget.contains(event.target as Element)
            ) {
                return;
            }
            setTimeout(() => {
                setCellModesModel((prevModel) =>
                    getUpdatedCellModesModel(prevModel, params)
                );
            }, 0);
        },
        []
    );

    const handleCellModesModelChange = useCallback(
        (newModel: GridCellModesModel) => {
            setCellModesModel(newModel);
        },
        []
    );

    const processRowUpdate = useCallback((newRow: GridRowModel) => {
        if (
            newRow?.key !==
            newRow?.[REPORT_EDIT_FIELDS.NORMAL_CITY]?.value +
                newRow?.[REPORT_EDIT_FIELDS.NORMAL_STATE]?.value +
                newRow?.[REPORT_EDIT_FIELDS.NORMAL_STREET]?.value +
                newRow?.[REPORT_EDIT_FIELDS.NORMAL_ZIP]?.value
        ) {
            dispatch(
                GlobalStateActions[product?.productName].setCorrectedAddresses(
                    newRow
                )
            );
        } else
            dispatch(
                GlobalStateActions[
                    product?.productName
                ].setRemoveCorrectedAddress(newRow?.key)
            );
        return newRow;
    }, []);

    const onFilterChange = ((filterModel: GridFilterModel) => {
        clearTimeout(timeout);

        const filters = getMappedFilters(filterModel);
        const searchPhrase = filterModel?.quickFilterValues?.join(" ");
        
        timeout = setTimeout(() => {
            dispatch(GlobalStateActions[product?.productName].setReportsFilterState({[String(jobId) + String(reportId)]: filterModel}));
            fetchReportData(filters, searchPhrase?.length !== 1 ? searchPhrase : "");
        }, 1000);
    });

    const openReportContextMenu = (event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();

        const rowId = Number(event?.currentTarget?.getAttribute("data-id"));
        const record = rows.find((row: GridRowModel) => row?.id === rowId);
        const isOnReview =
            selectedJobStatus?.name === JOB_STATUS_TYPES.ON_REVIEW;
        const isAllowedReportTab =
            selectedReportTab?.name ===
                ALLOCATOR_REPORT_TYPES.ALLOCATION_DETAIL ||
            selectedReportTab?.name ===
                ALLOCATOR_REPORT_TYPES.MATCH_EXCEPTIONS ||
            selectedReportTab?.name === ALLOCATOR_REPORT_TYPES.NON_TAXABLE;

        if (record?.key && isOnReview && isAllowedReportTab) {
            setSelectedRow(record);
            setReportContextMenuPosition(
                reportContextMenuPosition === null
                    ? {
                          mouseX: event.clientX,
                          mouseY: event.clientY,
                      }
                    : // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                      null
            );
        }
    };

    const onColumnResize = (params: GridColumnResizeParams) => {
        clearTimeout(timeout);
        
        timeout = setTimeout(() => {
            dispatch(
                GlobalStateActions[product?.productName].setReportColumnsWidth(
                    {
                        [String(reportId)]: {
                            [params?.colDef?.field]: params?.width
                        }
                    }
                )
            );
        }, 500);
    }

    const getAutosizedColumnFields = () => {
        return columns.reduce((filtered: string[], column: GridColDef) => {
            if (!reportColumnsWidth[String(reportId)]?.[column?.field]) {
                filtered.push(column.field);
            }
            return filtered;
        }, [])
    }

    const handleCloseReportContextMenu = () => {
        setSelectedRow({});
        setReportContextMenuPosition(null);
    };

    if (reportTabsLoading || isLoading) return <Loader />;

    return (
        <Box className="report-grid-container">
            <StyledDataGrid
                $rowId={selectedRow?.id}
                autosizeOnMount={selectedReportTab?.name === ALLOCATOR_REPORT_TYPES.ACTIVITY}
                autosizeOptions={{ 
                    columns: getAutosizedColumnFields(),
                    includeOutliers: false,
                    includeHeaders: false,
                    expand: true 
                }}
                apiRef={apiRef}
                rows={rows}
                columns={columns}
                disableRowSelectionOnClick
                disableColumnMenu
                disableColumnReorder
                density="compact"
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
                }
                filterMode="server"
                filterModel={selectedReportTabFilters}
                onFilterModelChange={onFilterChange}
                initialState={{ pinnedColumns: { left: ["policyNumber", "sourceRow.policyNumber"] } }}
                pagination={Boolean(rows?.length)}
                paginationMode="server"
                paginationModel={paginationModel}
                rowCount={rowCountState}
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={[
                    ALLOCATOR_REPORT_PAGE_SIZES.SMALL,
                    ALLOCATOR_REPORT_PAGE_SIZES.MEDIUM,
                    ALLOCATOR_REPORT_PAGE_SIZES.LARGE,
                    ALLOCATOR_REPORT_PAGE_SIZES.EXTRA_LARGE
                ]}
                isCellEditable={(params: GridCellParams) => !!params?.row?.key}
                processRowUpdate={processRowUpdate}
                cellModesModel={cellModesModel}
                onCellModesModelChange={handleCellModesModelChange}
                onCellClick={handleCellClick}
                onColumnResize={onColumnResize}
                slots={{
                    toolbar: ReportToolbar,
                    noRowsOverlay: NoDataOverlay,
                    noResultsOverlay: NoDataOverlay,
                    pagination: NumberedPagination
                }}
                slotProps={{
                    row: {
                        onContextMenu: openReportContextMenu,
                    },
                    baseSelect: {
                        native: false
                    },
                    filterPanel: {
                        sx: {
                            "& .MuiDataGrid-panelContent": { maxHeight: "300px" },
                            "& .MuiFormLabel-root.Mui-focused": { color: colors.primaryColor },
                            "& .MuiInput-root:after": {borderBottom: `1px solid ${colors.primaryColor}`},
                            "& .MuiButton-root": { color: colors.primaryColor },
                            "& .MuiInput-root": { minWidth: 120 },
                        },
                    }
                }}
            />
            <ReportContextMenu
                position={reportContextMenuPosition}
                selectedRow={selectedRow}
                handleCloseReportContextMenu={handleCloseReportContextMenu}
            />
        </Box>
    );
};

export default ReportDataGrid;
