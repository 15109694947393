import React, { FC } from "react";
import { Account, Product } from "src/types";
import {
    StyledListItem,
    StyledListItemHeader,
} from "../ExtensiveSearchModal.styled";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";

interface AccountSearchResultProps {
    account: Account;
    closeModal: () => void;
}

const AccountSearchResult: FC<AccountSearchResultProps> = ({
    account,
    closeModal,
}) => {
    const product: Product = useAppSelector(
        (state) => state.Product.value,
    ) as Product;

    const dispatch = useAppDispatch();

    const handleClick = () => {
        dispatch(
            GlobalStateActions[product?.productName].setSelectedAccount(
                account,
            ),
        );

        dispatch(
            GlobalStateActions[product?.productName]?.setSelectedComponent(
                "Account",
            ),
        );

        closeModal();
    };

    return (
        <StyledListItem itemType="account" onClick={handleClick}>
            <div className="list-item-icon">
                <SwitchAccountIcon />
            </div>
            <div>
                <StyledListItemHeader>Account</StyledListItemHeader>
                <div>Name: {account?.name}</div>
                <div>Number: {account?.accountNumber}</div>
            </div>
        </StyledListItem>
    );
};

export default AccountSearchResult;
