import React, { FC, useState } from "react";
import { useSearchParams } from "react-router-dom";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { StyledOutlinedButton } from "src/products/Allocator/Allocator.styled";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { ALLOCATOR_SEARCH_PARAMS, FixMeLater } from "src/types";
import { AllocatorService } from "src/services";
import ConfirmationDialog from "../../../ConfirmationDialog/ConfirmationDialog";
import { useSnackbar } from "notistack";

const AllocateAgent: FC = () => {
    const allocatorService = AllocatorService.getInstance();

    const [open, setOpen] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const jobId = searchParams.get(ALLOCATOR_SEARCH_PARAMS.JOB_ID);
    const product: FixMeLater = useAppSelector((state) => state?.Product?.value);

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();

    const handleAllocate = async () => {
        handleClose();
        dispatch(
            GlobalStateActions[product?.productName].setReportTabsLoading(
                true
            )
        );

        try {
            const response = await allocatorService.allocateOnAgentAddress(
                Number(jobId),
            );
            dispatch(
                GlobalStateActions[product?.productName].setToogleFetchReport()
            );
            enqueueSnackbar(response?.message, { variant: "success" });
        } catch (error) {
            enqueueSnackbar("Error allocating on agent address", { variant: "error" });
        } finally {
            dispatch(
                GlobalStateActions[product?.productName].setReportTabsLoading(
                    false
                )
            );
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const actionPrimary = {
        text: "Yes",
        action: handleAllocate
    }

    const actionSecondary = {
        text: "No",
        action: handleClose
    }
    

    return (
        <>
            <StyledOutlinedButton
                variant="outlined"
                sx={{ margin: "0 10px" }}
                onClick={handleClickOpen}
            >
                Allocate on Agent Address
            </StyledOutlinedButton>
            <ConfirmationDialog 
                open={open}
                title={"The Agent’s addresses will be used to allocate all of the non-taxable policies."}
                content={"The action cannot be undone. Do you want to continue?"}
                actionPrimary={actionPrimary}
                actionSecondary={actionSecondary}
                handleClose={handleClose}
            />
        </>
    );
};

export default AllocateAgent;
