import styled from "styled-components";
import colors from "src/styles/colors.scss";
import { RadioGroup } from "@mui/material";

export const StyledRadioGroup = styled(RadioGroup)`
    margin-left: 10px;

    .MuiRadio-colorPrimary {
        color: ${colors.primaryColor};
    }

    .Mui-disabled {
        color: ${colors.grey};
    }
`;