import React, { FC, useState } from "react";
import { Modal, TextField, ListItem, ListItemText } from "@mui/material";
import { debounce } from "lodash";
import {
    StyledListContainer,
    StyledPaper,
} from "./ExtensiveSearchModal.styled";
import { mockSearchResults } from "./ExtensiveSearchModal.mock";
import "./ExtensiveSearchModal.scss";
import Loader from "../Loader/Loader";
import AccountSearchResult from "./components/AccountSearchResult";
import SubAccountSearchResult from "./components/SubAccountSearchResult";
import CompanySearchResult from "./components/CompanySearchResult";
import UserSearchResult from "./components/UserSearchResult";
import GroupSearchResult from "./components/GroupSearchResult";
import {
    Account,
    Company,
    FixMeLater,
    SubAccountWithCompanies,
    User,
} from "src/types";
import { ExtensiveSearchService } from "src/services/ExtensiveSearchService";

interface SearchResult {
    accounts: [Account];
    subaccounts: [SubAccountWithCompanies];
    companies: [Company];
    users: [User];
    groups: [FixMeLater];
}

interface ExtensiveSearchModalProps {
    open: boolean;
    onClose: () => void;
}

const ExtensiveSearchModal: FC<ExtensiveSearchModalProps> = ({
    open,
    onClose,
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [searchResults, setSearchResults] = useState<FixMeLater | null>(null);

    const extensiveSearchService = ExtensiveSearchService.getInstance();

    const mockSearch = (query: string): FixMeLater => {
        console.log("Searching for:", query);
        console.log("Mock search results:", mockSearchResults);
        return mockSearchResults;
    };

    const debouncedSearch = debounce(async (query: string) => {
        if (query === "") {
            return;
        }
        setLoading(true);

        try {
            const searchResults = await extensiveSearchService.search(query);
            // Simulated async search logic with mock data
            // const searchResults = mockSearch(query);
            setSearchResults(searchResults);
        } catch (error) {
            console.error("Error searching:", error);
        }

        setLoading(false);
    }, 300);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
        setSearchQuery(query);
        debouncedSearch(query);
    };

    const handleModalClose = () => {
        onClose();
        setSearchQuery("");
    };

    const renderSearchResults = () => {
        return (
            <>
                {searchResults?.accounts?.map((account, index) => (
                    <AccountSearchResult
                        key={`account-search-result-${index}`}
                        account={account}
                        closeModal={onClose}
                    />
                ))}

                {searchResults?.subAccounts?.map((subAccount, index) => (
                    <SubAccountSearchResult
                        key={`subaccount-search-result-${index}`}
                        subAccount={subAccount}
                        closeModal={onClose}
                    />
                ))}

                {searchResults?.companies?.map((company, index) => (
                    <CompanySearchResult
                        key={`company-search-result-${index}`}
                        company={company}
                        closeModal={onClose}
                    />
                ))}

                {searchResults?.users?.map((user, index) => (
                    <UserSearchResult
                        key={`user-search-result-${index}`}
                        user={user}
                        closeModal={onClose}
                    />
                ))}

                {searchResults?.groups?.map((group, index) => (
                    <GroupSearchResult
                        key={`group-search-result-${index}`}
                        group={group}
                        closeModal={onClose}
                    />
                ))}
            </>
        );
    };

    const searchResultCount =
        (searchResults?.accounts?.length || 0) +
        (searchResults?.subaccounts?.length || 0) +
        (searchResults?.companies?.length || 0) +
        (searchResults?.users?.length || 0) +
        (searchResults?.groups?.length || 0);

    return (
        <Modal open={open} onClose={handleModalClose}>
            <div className="extensive-search-modal-container">
                <StyledPaper>
                    <TextField
                        id="search-input"
                        label="Search"
                        variant="outlined"
                        fullWidth
                        value={searchQuery}
                        onChange={handleSearchChange}
                        autoFocus
                    />
                    <StyledListContainer>
                        {loading && (
                            <ListItem>
                                <Loader />
                            </ListItem>
                        )}
                        {!loading &&
                            searchResultCount > 0 &&
                            renderSearchResults()}

                        {!loading && searchResultCount === 0 && searchQuery && (
                            <ListItem>
                                <ListItemText primary="No results found." />
                            </ListItem>
                        )}

                        {!loading && !searchQuery && (
                            <ListItem>
                                <ListItemText primary="Start typing to search." />
                            </ListItem>
                        )}
                    </StyledListContainer>

                    {!loading && searchResultCount > 0 && searchQuery && (
                        <div className="search-result-count">
                            Found {searchResultCount} results for {searchQuery}
                        </div>
                    )}
                </StyledPaper>
            </div>
        </Modal>
    );
};

export default ExtensiveSearchModal;
