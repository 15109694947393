import React, { FC, useMemo, useState } from "react";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import BusinessIcon from "@mui/icons-material/Business";

import { useAppSelector } from "src/hooks";
import { FixMeLater, SubAccountWithCompanies } from "src/types";
import { StyledNoDataFound } from "../../../../AccountAdmin.styled";
import SubAccountModal from "../../modals/SubAccountModal/SubAccountModal";
import { FrameCard } from "src/uikit";

import "./SubAccounts.scss";

const SubAccounts: FC = () => {
    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value,
    );
    const selectedAccount = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccount,
    );
    const selectedAccountSubAccountsWithCompanies: SubAccountWithCompanies[] =
        useAppSelector(
            (state) =>
                state?.[product?.productName]?.value
                    ?.selectedAccountSubAccountsWithCompanies,
        );

    const [subAccountsModalOpen, setSubAccountsModalOpen] =
        useState<boolean>(false);

    const SubAccountsTreeView = useMemo(
        () => (
            <SimpleTreeView
                aria-label="sub-accounts navigator"
                slots={{
                    expandIcon: ExpandMoreIcon,
                    collapseIcon: ChevronRightIcon,
                }}
                className="tree"
            >
                {selectedAccountSubAccountsWithCompanies &&
                    selectedAccountSubAccountsWithCompanies.map(
                        (subAccount, index) => {
                            const nodeId = `subaccount-${index}`;
                            return (
                                <TreeItem
                                    className="tree__item"
                                    itemId={nodeId}
                                    label={subAccount.name}
                                    key={subAccount.id}
                                >
                                    {subAccount.companies &&
                                        subAccount.companies.map(
                                            (company, companyIndex) => (
                                                <TreeItem
                                                    itemId={`${nodeId}-${companyIndex}`}
                                                    label={
                                                        <span className="label">
                                                            <BusinessIcon className="label__icon" />
                                                            {company.name}
                                                        </span>
                                                    }
                                                    key={company.id}
                                                />
                                            ),
                                        )}
                                </TreeItem>
                            );
                        },
                    )}
            </SimpleTreeView>
        ),
        [selectedAccountSubAccountsWithCompanies],
    );

    const noDataFoundMessage = selectedAccount
        ? "There are no sub-accounts associated with this account."
        : "Please select a group.";

    return (
        <div className="sub-accounts-container">
            <FrameCard
                title="SUB-ACCOUNTS"
                actions={[
                    {
                        text: "Add Sub-Account",
                        onClick: () => setSubAccountsModalOpen(true),
                    },
                ]}
            >
                {selectedAccountSubAccountsWithCompanies?.length === 0 ? (
                    <StyledNoDataFound>{noDataFoundMessage}</StyledNoDataFound>
                ) : (
                    <>{SubAccountsTreeView}</>
                )}
            </FrameCard>
            <SubAccountModal
                open={subAccountsModalOpen}
                onClose={() => setSubAccountsModalOpen(false)}
            />
        </div>
    );
};

export default SubAccounts;
