import React, { FC } from "react";
import { GridToolbarFilterButton } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useAppSelector } from "src/hooks";
import {
    ALLOCATOR_REPORT_TYPES,
    AllocatorJobStatus,
    AllocatorReportTab,
    FixMeLater,
    JOB_STATUS_TYPES,
} from "src/types";
import { StyledToolbarQuickFilter} from "src/products/Allocator/Allocator.styled";
import ReportDownloadButton from "../ReportDownloadButton/ReportDownloadButton";
import { StyledReportToolbar } from "./ReportToolbar.styled";
import EditMode from "../EditMode/EditMode";
import colors from "src/styles/colors.scss";
import AllocateAgent from "../AllocateAgent/AllocateAgent";

const ReportToolbar: FC = () => {
    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value,
    );
    const selectedReportTab: AllocatorReportTab = useAppSelector(
        (state) => state?.[product?.productName]?.value?.selectedReportTab,
    );
    const selectedJobStatus: AllocatorJobStatus = useAppSelector(
        (state) =>
            state?.[product?.productName]?.value?.jobs?.selectedJobStatus,
    );
    const stateAbbr: string = useAppSelector(
        (state) => state?.[product?.productName]?.value?.stateAbbr
    );

    const hasEditMode =
        selectedJobStatus?.name === JOB_STATUS_TYPES.ON_REVIEW &&
        (selectedReportTab?.name === ALLOCATOR_REPORT_TYPES.ALLOCATION_DETAIL ||
            selectedReportTab?.name ===
                ALLOCATOR_REPORT_TYPES.MATCH_EXCEPTIONS ||
            selectedReportTab?.name === ALLOCATOR_REPORT_TYPES.NON_TAXABLE);

    const hasAllocateAgent = 
        selectedReportTab?.name === ALLOCATOR_REPORT_TYPES.NON_TAXABLE &&
        selectedJobStatus?.name === JOB_STATUS_TYPES.ON_REVIEW &&
        stateAbbr === "SC"

    return (
        <StyledReportToolbar>
            <Box>
                <GridToolbarFilterButton
                    slotProps={{
                        button: { sx: {
                            color: colors.primaryColor,
                            fontWeight: 600,
                            minWidth: "32px",
                        } },
                    }}
                />
                <StyledToolbarQuickFilter sx={{ margin: "0 20px" }} />
                <ReportDownloadButton />
                {hasAllocateAgent && <AllocateAgent />}
            </Box>
            {hasEditMode && <EditMode />}
        </StyledReportToolbar>
    );
};

export default ReportToolbar;
