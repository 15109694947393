// src/components/DataTable/ConfirmationDialog.tsx
import React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { StyledFlexContainer } from "src/components/FolderNodeDialog/FolderNodeDialog.styled";

interface ConfirmationDialogProps {
    open: boolean;
    title: string;
    message: string;
    onClose: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    open,
    title,
    message,
    onClose,
}) => (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <InfoIcon
                color="info"
                style={{ marginRight: 20 }}
                fontSize="medium"
            />
            {title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
            <StyledFlexContainer justify={"center"}>
                <Button onClick={onClose} color="primary">
                    OK
                </Button>
            </StyledFlexContainer>
        </DialogActions>
    </Dialog>
);

export default ConfirmationDialog;
