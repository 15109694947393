import React from "react";
import { Menu, MenuItem, PopoverPosition } from "@mui/material";

export type ContextMenuItem = {
    label: string;
    onClick: () => void;
    disabled?: boolean;
};

interface DataTableContextMenuProps {
    open: boolean;
    items: Array<ContextMenuItem>
    onClose: () => void;
    position: PopoverPosition;
}

const DataTableContextMenu: React.FC<DataTableContextMenuProps> = ({
    open,
    items,
    onClose,
    position,
}) => {

    return (
        <Menu
            open={open}
            onClose={onClose}
            anchorReference="anchorPosition"
            anchorPosition={position}
        >
            {
                items.map((item, index) => (
                    <MenuItem
                        key={index}
                        onClick={item.onClick}
                        disabled={item.disabled}
                    >
                        {item.label}
                    </MenuItem>
                ))
            }
        </Menu>
    );
};

export default DataTableContextMenu;
