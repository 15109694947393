import React, { MouseEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { FixMeLater, State, Tree } from "src/types";
import ReturnNodeMenu from "../ReturnNodeMenu/ReturnNodeMenu";
import "./ReturnNode.scss";
import { getReturnNodeIcon } from "./ReturnNodeUtil";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

interface ReturnNodeProps {
    returnNode: FixMeLater;
    folderNode: FixMeLater;
    tabKey: string;
}

const ReturnNode: React.FC<ReturnNodeProps> = ({
    returnNode,
    folderNode,
    tabKey,
}) => {
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );

    if (!product) return null;

    const dropdownStateView: boolean = useAppSelector((state) => {
        return state?.UserOptions?.value?.productPreferences[
            product.productName
        ]?.dropdownStateView;
    });

    const tree: Tree = useAppSelector(
        (state) => state[product?.productName]?.value?.tree
    );

    const selectedState: State = useSelector(
        (state) => state?.[product?.productName]?.value?.state
    );

    const validJurisdiction = (folderId: string): boolean => {
        return Number(folderId) > 0 && Number(folderId) < 62;
    };
    const companies = useAppSelector((state) => state?.Companies?.value);

    const tabsProperties = useAppSelector(
        (state) => state?.Tabs
    )?.tabsProperties;

    const company = useAppSelector(
        (state) => state?.[product?.productName]?.value?.company
    );

    const setDataFromURL = () => {
        const returnFolderId = searchParams.get("returnFolderId");
        const returnNodeId = searchParams.get("returnNodeId");

        if (returnFolderId && returnNodeId) {
            const folderNode = tree?.folderNodes.find(
                (folder) => folder?.id === returnFolderId
            );
            const returnNode = folderNode?.returnNodes.find(
                (node) => node?.id === returnNodeId
            );
            if (folderNode && returnNode) {
                const folderId = dropdownStateView
                    ? selectedState?.id.toString()
                    : folderNode?.id.toString();

                const newFolderNode = {
                    ...folderNode,
                    id: folderId,
                };

                dispatch(
                    GlobalStateActions[product?.productName].setFolderNode(
                        newFolderNode
                    )
                );

                dispatch(
                    GlobalStateActions[product?.productName].setReturnNode(
                        returnNode
                    )
                );

                if (
                    product?.productName == "Premium" &&
                    validJurisdiction(newFolderNode.id)
                ) {
                    dispatch(
                        GlobalStateActions[
                            product?.productName
                        ].setSelectedJurisdictionForSBP(
                            Number(newFolderNode.id)
                        )
                    );
                }
            }
        }
    };

    if (
        searchParams.has("returnFolderId") &&
        searchParams.has("returnNodeId")
    ) {
        setDataFromURL();
    }

    const [returnTreeNodeMenuAnchorEl, setReturnTreeNodeMenuAnchorEl] =
        useState<HTMLElement | null>(null);

    const handleReturnNodeClick = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();

        dispatch(
            GlobalStateActions[product?.productName].setFolderNode(folderNode)
        );

        dispatch(
            GlobalStateActions[product?.productName].setReturnNode(returnNode)
        );

        if (dropdownStateView) {
            const compOption = companies.find((comp) => {
                return comp.id == folderNode.id;
            });
            if (compOption) {
                dispatch(
                    GlobalStateActions[product.productName].setCompany(
                        compOption
                    )
                );
            }
        }
        if (
            product?.productName == "Premium" &&
            validJurisdiction(folderNode.id)
        ) {
            dispatch(
                GlobalStateActions[
                    product?.productName
                ].setSelectedJurisdictionForSBP(Number(folderNode.id))
            );
        }
    };

    const openReturnNodeMenu = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        setReturnTreeNodeMenuAnchorEl(event.currentTarget);
    };

    const handleCloseReturnNodeMenu = (event) => {
        event.stopPropagation();
        setReturnTreeNodeMenuAnchorEl(null);
    };

    const getReturnNodeClass = (status) => {
        switch (status) {
            case "UPDATED":
                return "return-node-update";
            case "NOT_UPDATED":
                return "return-node-not-update";
            default:
                return "";
        }
    };
    return (
        <div
            className={`return-node-container ${
                tabsProperties[tabKey]?.isActive &&
                tabsProperties[tabKey]?.returnId === returnNode?.id
                    ? "selected-return-node-container"
                    : ""
            }`}
            onClick={(e) => handleReturnNodeClick(e)}
            onContextMenu={(e) => {
                e.preventDefault(); // prevent the default behaviour when right clicked
                openReturnNodeMenu(e);
            }}
            data-testid="return-node-container"
        >
            <div className="return-node-icon-container">
                {getReturnNodeIcon(
                    returnNode?.formStatus,
                    returnNode?.isLocked,
                    returnNode?.submissionId !== ""
                )}
            </div>
            <div
                className={`return-node-name-container  ${getReturnNodeClass(
                    returnNode?.formStatus
                )}`}
            >
                {returnNode?.displayName}
            </div>

            <ReturnNodeMenu
                anchorEl={returnTreeNodeMenuAnchorEl}
                handleCloseReturnNodeMenu={handleCloseReturnNodeMenu}
                returnNode={returnNode}
                folderNode={folderNode}
            />
        </div>
    );
};

export default ReturnNode;
