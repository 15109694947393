import React, { CSSProperties, FC } from "react";
import "./SpacedText.scss"; // Import the CSS file for static styles

// Define props for SpacedText component
interface SpacedTextProps {
    runsInput: string;
    value: string;
    reversed: boolean;
    scale: number;
    style: CSSProperties; // Updated the type for style
    onClick: () => void;
    alignment: "LEFT" | "RIGHT"; // Added alignment prop type
}

// Constant for twips value
const twips = 20;

// Main SpacedText component
const SpacedText: FC<SpacedTextProps> = ({
    runsInput,
    value,
    reversed,
    scale,
    style,
    onClick,
    alignment,
}) => {
    // Parse runsInput to get an array of runs with x and characterPosition
    const runsArray = runsInput.split(",").map((item, index, array) => {
        const [x, characterPosition] = item.split(":");
        const accumulatedX =
            index > 0
                ? array
                      .slice(0, index)
                      .reduce(
                          (sum, run) => sum + parseFloat(run.split(":")[0]),
                          0
                      )
                : 0;

        return {
            xInit: accumulatedX,
            characterPosition: parseInt(characterPosition),
            xEnd: x === "*" ? value.length : accumulatedX + parseFloat(x),
        };
    });

    // Dynamic styles for SpacedText container
    const dynamicStyles: CSSProperties = {
        ...style,
    };

    return (
        <div
            className="spaced-text-container"
            style={dynamicStyles}
            onClick={onClick}
        >
            {/* Map through runsArray and render each spaced character */}
            {runsArray.map((run, i) => (
                <span
                    key={i}
                    className="spaced-text-char"
                    style={{
                        [alignment.toLowerCase()]: `${(run.characterPosition / twips) * scale}px`,
                    }}
                >
                    {/* Render substring based on alignment */}
                    {alignment === "RIGHT"
                        ? value.substring(value.length - run.xEnd, value.length - run.xInit)
                        : value.substring(run.xInit, run.xEnd)}
                </span>
            ))}
        </div>
    );
};

export default SpacedText;
