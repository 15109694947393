
export enum NoteFieldType {
    TEXT = "T",
    NUMBER = "N",
    LABEL = "L",
    CHECKBOX = "B",
    COMBO_BOX = "C",
    PAGE = "P",
}
export interface ReturnDocumentNoteType {
    createdReturnId: number;
    pageId: number;
    fieldType: NoteFieldType;
    fieldId: string;
    noteText: string;
    createdBy: string;
    createdAt: number;
    editedBy: string;
    editedAt: number;
    id: number | undefined;
}

export interface ReturnDocumentNoteId {
    createdReturnId: number;
    pageId: number;
    fieldType: NoteFieldType;
    fieldId: number;
}

export interface ReturnDocumentNoteWithReturnIdentifierDTO {
    companyId: number;
    productId: number;
    taxYearId: number;
    folderId: number;
    moduleId: number;
    returnId: number;
    retalFolderId: number;
    paymentRequestFormId: number;
    returnDocumentNoteEntity: ReturnDocumentNoteType;
}

export interface ReturnDocumentNoteState {
    newFieldNotePage?: number;
    newFieldNoteId: string | undefined;
    newFieldNoteType: NoteFieldType;
    focusedFieldNotePage?: number;
    focusedFieldNoteId: string | undefined;
    focusedFieldNoteType: NoteFieldType;
    showNotes: boolean;
}
