import { Document, Page } from "react-pdf";

import styled from "styled-components";


export const StyledDocument = styled(Document)`
    margin-left: auto;
    margin-right: auto;
`;

export const StyledPage = styled(Page)`
    border: 1px solid #e0e0e0;
    margin-bottom: 20px;
`;