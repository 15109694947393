import React from "react";
import { FixMeLater, ReturnNode } from "src/types";
import { isSBPSchedule } from "../util/DataTable.util";
import { selectAllRows } from "../util/CopyAndPaste.util";

const SelectAllRowsButton: React.FC<{
    returnNode: ReturnNode;
    apiRef: FixMeLater;
}> = ({ returnNode, apiRef }) => {
    return (
        <div
            className="select-all-rows-button-container"
            style={{ top: isSBPSchedule(returnNode) ? "53px" : "6px" }}
            onClick={() => selectAllRows(apiRef)}
        >
            <div className="select-all-rows-button" />
        </div>
    );
};

export default SelectAllRowsButton;
