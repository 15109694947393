import { Button, Modal, TextField, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";
import Loader from "src/components/Loader/Loader";
import ModalError from "src/components/ModalError/ModalError";
import { emailRegex, phoneRegex } from "src/constants/Regex";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { UserService } from "src/services";
import { FixMeLater } from "src/types";
import {
    StyledFlexContainer,
    StyledForm,
    StyledFormContainer,
    StyledModalContainer,
} from "../../../../AccountAdmin.styled";
import "./UserModal.scss";

const UserModal: FC<{ open: boolean; onClose: () => void }> = ({
    open,
    onClose,
}) => {
    const userService = UserService.getInstance();
    const dispatch = useAppDispatch();

    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value,
    );
    const selectedAccount = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccount,
    );
    const selectedAccountUser = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccountUser,
    );
    const selectedAccountUsers = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccountUsers,
    );

    const initialFormData = {
        firstName: selectedAccountUser?.firstName || "",
        middleInitial: selectedAccountUser?.middleInitial || "",
        lastName: selectedAccountUser?.lastName || "",
        preferredName: selectedAccountUser?.preferredName || "",
        title: selectedAccountUser?.title || "",
        phone: selectedAccountUser?.officePhone || "",
        email: selectedAccountUser?.email || "",
    };

    const [formData, setFormData] = useState(initialFormData);

    const [validationErrors, setValidationErrors] = useState({
        email: "",
        middleInitial: "",
        phone: "",
    });

    useEffect(() => {
        setFormData(initialFormData);
    }, [selectedAccountUser]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        if (name === "middleInitial" && value.length > 1) {
            setValidationErrors({
                ...validationErrors,
                [name]: "Middle Initial can have at most 1 character.",
            });
        } else if (name === "phone" && !phoneRegex.test(value)) {
            setValidationErrors({
                ...validationErrors,
                phone: "Phone Number must be 10 digits and contain no special characters",
            });
        } else if (name === "email" && !emailRegex.test(value)) {
            setValidationErrors({
                ...validationErrors,
                email: "Email address is invalid.",
            });
        } else {
            setValidationErrors({
                ...validationErrors,
                [name]: "",
            });
        }

        setFormData({ ...formData, [name]: value });
    };

    const isEmailAlreadyUsed = (emailInput) => {
        return selectedAccountUsers.some(
            (user) =>
                user.email === emailInput && user.id !== selectedAccountUser.id,
        );
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (Object.values(validationErrors).some((error) => error)) {
            // If there are validation errors, prevent form submission
            return;
        }

        if (isEmailAlreadyUsed(formData.email)) {
            setValidationErrors({
                ...validationErrors,
                email: "This email is used for an existing account. Try another.",
            });
            return;
        }

        try {
            setIsLoading(true);

            const updatedUser: FixMeLater = {
                // when move to typescript use UpdateUserPayload type
                id: selectedAccountUser?.id,
                accountId: selectedAccount?.id,
                email: formData.email,
                personalInfo: {
                    firstName: formData.firstName,
                    middleInitial: formData.middleInitial,
                    lastName: formData.lastName,
                    preferredName: formData.preferredName,
                    title: formData.title,
                    officePhone: formData.phone,
                },
                accessControlGroups: selectedAccountUser.accessControlGroup,
                active: true,
            };

            const updatedResponse: FixMeLater =
                await userService.updateUser(updatedUser);

            if (updatedResponse) {
                dispatch(
                    GlobalStateActions[
                        product?.productName
                    ].setSelectedAccountUser(updatedResponse),
                );

                const updatedAccountUsers: FixMeLater[] | undefined =
                    selectedAccountUsers?.map((u: FixMeLater) =>
                        u?.id === updatedResponse?.id ? updatedResponse : u,
                    );

                dispatch(
                    GlobalStateActions[
                        product?.productName
                    ].setSelectedAccountUsers(updatedAccountUsers),
                );
            }

            onClose();
            setFormData(initialFormData);
            setSnackbarOpen(true);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setError(null);
        onClose();
    };

    return (
        <>
            <Modal open={open} onClose={handleCancel}>
                <div>
                    <StyledModalContainer>
                        <StyledFormContainer>
                            <Typography variant="h5">Edit User</Typography>
                            <StyledForm onSubmit={handleSubmit}>
                                <StyledFlexContainer>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                    />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="middleInitial"
                                        label="Middle Initial"
                                        name="middleInitial"
                                        value={formData.middleInitial}
                                        onChange={handleInputChange}
                                        inputProps={{ maxLength: 1 }}
                                        error={!!validationErrors.middleInitial}
                                        helperText={
                                            validationErrors.middleInitial
                                        }
                                    />
                                </StyledFlexContainer>

                                <StyledFlexContainer>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                    />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="preferredName"
                                        label="Preferred Name"
                                        name="preferredName"
                                        value={formData.preferredName}
                                        onChange={handleInputChange}
                                    />
                                </StyledFlexContainer>

                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="title"
                                    label="Title"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleInputChange}
                                    inputProps={{
                                        maxLength: 25,
                                    }}
                                />

                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    error={!!validationErrors.email}
                                    helperText={validationErrors.email}
                                />

                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="phone"
                                    label="Phone"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    error={!!validationErrors.phone}
                                    helperText={validationErrors.phone}
                                />

                                <StyledFlexContainer justify={"center"}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={
                                            !!validationErrors.email ||
                                            !!validationErrors.phone
                                        }
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        color="secondary"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </StyledFlexContainer>
                            </StyledForm>
                        </StyledFormContainer>
                        {isLoading && <Loader />}
                        {error && <ModalError error={error} />}
                    </StyledModalContainer>
                </div>
            </Modal>
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message="User details updated successfully!"
                severity="success"
            />
        </>
    );
};

export default UserModal;
