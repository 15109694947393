interface FolderNodeAttributes {
    displayOrder: number;
    displayName: string;
    abbreviation: string;
    releaseNoteChangeIndicator: ReleaseNoteChangeIndicator | string;
    releaseNoteId?: string;
    isRetalNeeded: boolean;
}

enum ReleaseNoteChangeIndicator {
    GREEN = "1",
    YELLOW = "2",
    RED = "3",
    NONE = "",
}

interface SubReturnNode {
    id: number;
    displayCents: boolean;
    isAlwaysActive: boolean;
    isDomestic: boolean;
    isForeign: boolean;
    displayName: string;
    displayOrder: number;
    returnStatus: string;
    scheduleType: ScheduleType;
    resourceId: string;
    owningJurisdictionId?: number;
    owningModuleIds: number[];
}

enum FilingType {
    EFILE = "E",
    ONLINE = "O",
    OPTINS = "OPTins",
    NONE = "",
}

enum CompanySubType {
    LIFE_INSURANCE = "1",
    PC_INSURANCE = "2",
    HMO = "3",
    RISK_RETENTION = "4",
    TITLE = "5",
    LIFE_HEALTH = "6",
    PC_HEALTH = "7",
    NONE = "",
}

export enum ScheduleType {
    RETURN = "RETURN",
    REGULAR = "REGULAR",
    SUMMARY = "SUMMARY",
    VARIABLE_COLUMN = "VARIABLE_COLUMN",
    VARIABLE_ROW = "VARIABLE_ROW",
    STATUS = "STATUS",
    STATE_INFO = "STATE_INFO",
    LOGIN = "LOGIN",
    REPORT = "REPORT",
}

enum ManualEfileStatus {
    NOT_AVAILABLE = 0,
    AVAILABLE_CURRENT_YEAR = 1,
    AVAILABLE_CURRENT_YEAR_PLUS_ONE = 2,
}

export interface ReturnNode {
    id: number | string;
    displayCents?: boolean;
    isAlwaysActive?: boolean;
    isDomestic: boolean;
    isForeign: boolean;
    displayName: string;
    displayOrder: number;
    returnStatus: string;
    scheduleType: ScheduleType | string;
    resourceId: string;
    owningJurisdictionId?: number;
    owningModuleIds?: number[];
    isHidden: boolean;
    municipalId?: number | string;
    returnInstructionPdf?: string;
    filingType: FilingType | string;
    efile: boolean;
    efileEmail: boolean;
    isReady: boolean;
    canExport?: boolean;
    companySubType?: CompanySubType;
    manualEfileStatus?: ManualEfileStatus;
    retalInputScheduleStates?: string[];
    domesticAnnualReturnIds?: number[];
    foreignAnnualReturnIds?: number[];
    isOceanMarine?: boolean;
    isAutoEfile?: boolean;
    coversheetPages?: number[];
    subReturnNodes?: SubReturnNode[];
    isRetalReturn: boolean;
    activateRetal: boolean;
    retalFolderId?: number | string;
    isActive: boolean;
    isLocked: boolean;
    formStatus?: string,
    submissionId?: string
}

export interface FolderNode {
    id: number | string;
    attributes: FolderNodeAttributes;
    returnNodes: ReturnNode[];
}

export interface Tree {
    folderNodes: FolderNode[];
}
