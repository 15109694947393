import { WebExtensionService, WebLoginService } from "src/services";
import {
    DataGridRow,
    ExtMessageResponse,
    ExtMessageResponseStatus,
    LoginData,
    ReturnNode,
} from "src/types";

export const openLogin = async (
    row: DataGridRow,
    returnNode: ReturnNode,
    handleSnackbar,
): Promise<void> => {
    const webLoginService = WebLoginService.getInstance();
    const webExtensionService = WebExtensionService.getInstance();

    const message = `Web login for ${returnNode?.displayName}`;

    const loginData: LoginData = await webLoginService.getLoginDataByRowId(row);

    handleSnackbar(message, "info", 1000);

    const loginResponseData: ExtMessageResponse =
        await webExtensionService.doLogin(loginData);

    if (loginResponseData.status === ExtMessageResponseStatus.ERROR) {
        handleSnackbar(
            message + " failed : " + loginResponseData.message,
            "error",
        );
        return;
    }

    if (loginResponseData.status === ExtMessageResponseStatus.SUCCESS) {
        handleSnackbar(message + " completed", "success");
        return;
    }

    if (loginResponseData.status === ExtMessageResponseStatus.WARNING) {
        handleSnackbar(message + " completed with warnings", "warning");
        return;
    }
};
