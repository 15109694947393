import {
    GridColDef,
    GridFilterInputValue,
    GridFilterItem,
    GridFilterModel,
    GridRenderCellParams,
    GridRowScrollEndParams,
    getGridSingleSelectOperators,
    getGridStringOperators,
    useGridApiRef,
} from "@mui/x-data-grid-premium";
import React, { FC, useCallback, useState } from "react";
import Loader from "src/components/Loader/Loader";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AllocatorJob, AllocatorJobsMeta, FixMeLater, REPORT_COLUMN_TYPES } from "src/types";
import JobCard from "../JobCard/JobCard";
import { StyledJobList } from "./JobListGrid.styled";
import JobListToolbar from "./components/JobListToolbar/JobListToolbar";
import colors from "src/styles/colors.scss";
import { QUARTER_OPTIONS } from "../../constant";
import NoJobsOverlay from "./components/NoDataOverlay/NoJobsOverlay";
import { formatFilterValue } from "src/services/Utility";

interface JobListGridProps {
    jobList: AllocatorJob[];
    page: number;
    totalPages: number;
    isLoading: boolean;
    isDragActive: boolean;
    jobsMeta?: AllocatorJobsMeta;
}

const JobListGrid: FC<JobListGridProps> = ({
    jobList,
    page,
    totalPages,
    isLoading,
    isDragActive,
    jobsMeta,
}) => {
    const [isInputSearchFilled, setIsInputSearchFilled] = useState<boolean>(false);
    const singleSelectOperators = getGridSingleSelectOperators().filter(operator => operator.value !== "isAnyOf");
    const stringOperators = [
        ...getGridStringOperators(),
        {  
            label: "is not",
            value: "not",
            getApplyFilterFn: (filterItem: GridFilterItem) => filterItem.value,
            InputComponent: GridFilterInputValue
        }
    ]
    const columns: GridColDef[] = [
        {
            field: "source.filename",
            headerName: "File Name",
            sortable: false,
            minWidth: 220,
            renderCell: (params: GridRenderCellParams) => (
                <JobCard job={params?.row} />
            ),
            valueGetter: (value, row) =>
                row?.filename,
            filterOperators: stringOperators,
        },
        {
            field: "source.state",
            headerName: "State",
            type: REPORT_COLUMN_TYPES.SINGLE_SELECT,
            valueOptions: jobsMeta?.supportedStates,
            filterOperators: singleSelectOperators,
        },
        {
            field: "source.quarter",
            headerName: "Quarter",
            type: REPORT_COLUMN_TYPES.SINGLE_SELECT,
            valueOptions: QUARTER_OPTIONS,
            filterOperators: singleSelectOperators,
        },
        {
            field: "source.year",
            headerName: "Year",
            type: REPORT_COLUMN_TYPES.SINGLE_SELECT,
            valueOptions: Array.from({ length: new Date().getFullYear() - 2019}, (_, i) => (new Date().getFullYear() - i).toString()),
            filterOperators: singleSelectOperators,
        },
        {
            field: "status",
            headerName: "Status",
            type: REPORT_COLUMN_TYPES.SINGLE_SELECT,
            valueOptions: jobsMeta?.statuses,
            filterOperators: singleSelectOperators,
        },
        {
            field: "approvedDate",
            headerName: "Approval Date",
            type: REPORT_COLUMN_TYPES.DATE,
        },
        {
            field: "completionDate",
            headerName: "Completion Date",
            type: REPORT_COLUMN_TYPES.DATE,
        },

    ];

    const apiRef = useGridApiRef();

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const isJobSearchActive: boolean = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.isJobSearchActive
    );

    let timeout: ReturnType<typeof setTimeout>;

    const dispatch = useAppDispatch();

    const handleOnRowsScrollEnd = (params: GridRowScrollEndParams) => {
        if (params?.visibleRowsCount && !isLoading && page < totalPages) {
            dispatch(
                GlobalStateActions[product?.productName].setJobsPage(page + 1)
            );
        }
    };

    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        clearTimeout(timeout);
        const filters = filterModel?.items?.filter(item => !!item?.value || item?.operator === "isEmpty" || item?.operator === "isNotEmpty")
        .map(item => {
            return {
                field: item?.field,
                value: formatFilterValue(item?.value),
                operator: item?.operator,
                booleanOperator: filterModel?.logicOperator?.toUpperCase()
            }
        })

        const searchPhrase = filterModel?.quickFilterValues?.join(" ");
        setIsInputSearchFilled(!!searchPhrase?.length);
        
        timeout = setTimeout(() => {
            dispatch(
                GlobalStateActions[product?.productName].setJobsFilterState({ filters, searchPhrase: searchPhrase?.length !== 1 ? searchPhrase : ""})
            );
            dispatch(
                GlobalStateActions[product?.productName].setJobsPage(0)
            );
            apiRef.current.scrollToIndexes({rowIndex: 0, colIndex: 0});
        }, 1000);
    }, []);

    if (isLoading && !jobList?.length) return <Loader />;

    return (
        <StyledJobList
            apiRef={apiRef}
            rowHeight={130}
            rows={!isDragActive? jobList : []}
            columnHeaderHeight={0}
            columns={columns}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnReorder
            disableVirtualization
            hideFooter
            filterMode="server"
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        "source.state": false,
                        "source.quarter": false,
                        "source.year": false,
                        "status": false,
                        "approvedDate": false,
                        "completionDate": false,
                    },
                },
            }}
            localeText={{
                toolbarFilters: isJobSearchActive? "" : "Filters",
            }}
            onFilterModelChange={onFilterChange}
            onRowsScrollEnd={handleOnRowsScrollEnd}
            slots={{
                toolbar: JobListToolbar,
                noRowsOverlay: NoJobsOverlay,
                noResultsOverlay: NoJobsOverlay,
            }}
            slotProps={{
                baseSelect: {
                    native: false
                },
                toolbar: {
                    isInputSearchFilled,
                    isDragActive,
                },
                filterPanel: {
                    sx: {
                        "& .MuiDataGrid-panelContent": { maxHeight: "300px" },
                        "& .MuiFormLabel-root.Mui-focused": { color: colors.primaryColor },
                        "& .MuiInput-root:after": {borderBottom: `1px solid ${colors.primaryColor}`},
                        "& .MuiButton-root": { color: colors.primaryColor },
                        "& .MuiInput-root": { minWidth: 120 },
                    },
                }
            }}
        />
    );
};

export default JobListGrid;
