import {
    Date,
    DateFormat,
    DecimalFormat,
    NumberTextFormat,
    PlainTextFormat,
} from "enterprise-formatting-lib";

export function removeCharacters(
    originalString: string,
    charactersToRemove: string[],
): string {
    // Create a regular expression pattern that matches any of the characters to remove
    // Escape special characters and join them with a pipe (|) to create an OR condition in regex
    const regexPattern = charactersToRemove
        .map((char) => {
            // Escape special regex characters
            if (
                [
                    "-",
                    "\\",
                    "^",
                    "$",
                    "*",
                    "+",
                    "?",
                    ".",
                    "(",
                    ")",
                    "|",
                    "[",
                    "]",
                    "{",
                    "}",
                ].includes(char)
            ) {
                return `\\${char}`;
            } else {
                return char;
            }
        })
        .join("|");

    // Replace occurrences of the characters with an empty string
    const cleanedString = originalString.replace(
        new RegExp(regexPattern, "g"),
        "",
    );

    return cleanedString;
}

// expected fields on an object for Date Formatting
type DayMonthYear = { day: number; month: number; year: number };

type FormattingFieldType = string | number | DayMonthYear;

class Formatter {
    /**
     * @Assumption Text & Number fields are the only fields that need formatting
     * Returns a value formatted using the formatting rules in a template field
     * @param templateField the template field to pull formatting and assign value
     * @param fieldValue the value to format
     * @returns a formatted string of the fieldValue
     */
    static format(templateField: any, fieldValue: FormattingFieldType): string {
        let formattedValue;
        switch (templateField.fieldType) {
            case "NumberFieldTemplate":
                formattedValue = this.formatNumberField(
                    templateField,
                    fieldValue,
                );
                break;
            case "TextFieldTemplate":
                formattedValue = this.formatTextField(
                    templateField,
                    fieldValue,
                );
                break;
            default:
                formattedValue = String(fieldValue);
                break;
        }

        return templateField.excludedChar &&
            templateField.excludedChar.length > 0
            ? removeCharacters(formattedValue, templateField.excludedChar)
            : formattedValue;
    }
    private static formatNumberField(
        templateField: any,
        fieldValue: FormattingFieldType,
    ) {
        switch (templateField.numericFormatType) {
            case "FORMAT_DOUBLE":
                templateField = {
                    ...templateField,
                    excludedChar: templateField.excludedChar.filter(
                        (char) => char !== ".",
                    ),
                };
                return Formatter.properlyFormatDecimal(
                    templateField,
                    fieldValue,
                );
            case "FORMAT_PERCENT":
                return new DecimalFormat(
                    templateField.decimalPrecision + 2,
                    templateField.format,
                    templateField.excludedChar,
                ).formatDecimal(
                    // empty string yields null behavior.
                    // refer to enterprise-formatting-lib Kotlin Source
                    fieldValue === "" ? null : Number(fieldValue),
                );
            default:
                // fallback
                return String(fieldValue);
        }
    }

    private static properlyFormatDecimal(
        templateField: any,
        fieldValue: FormattingFieldType,
    ): string {
        let decimalPrecision = templateField.decimalPrecision;
        const fieldString = fieldValue
            .valueOf()
            .toString()
            .replace(/[^0-9.-]/g, "");
        let evaluatedString = "";

        if (fieldString.charAt(0) === "-" && templateField.format.length >= 2) {
            evaluatedString = templateField.format[1];
        } else if (templateField.format.length >= 1) {
            evaluatedString = templateField.format[0];
        }

        const decimalIndex = evaluatedString.indexOf(".");

        // If a decimal exists, run sanitizeDecimalString with a count of '0' characters after the decimal
        if (decimalIndex >= 0) {
            decimalPrecision = evaluatedString
                .substring(decimalIndex)
                .replace(/[^0]/g, "").length;
        }

        return new DecimalFormat(
            decimalPrecision,
            templateField.format,
            templateField.excludedChar,
        ).formatDecimal(
            // empty string yields null behavior.
            // refer to enterprise-formatting-lib Kotlin Source
            fieldValue === "" ? null : Number(fieldValue),
        );
    }

    private static formatTextField(
        templateField: any,
        fieldValue: FormattingFieldType,
    ) {
        switch (templateField.textFormatType) {
            case "FORMAT_NUMBER_TEXT":
                return new NumberTextFormat(
                    templateField.format,
                ).formatNumberText(
                    // empty string yields null behavior.
                    // refer to enterprise-formatting-lib Kotlin Source
                    fieldValue === "" ? null : String(fieldValue),
                );
            case "FORMAT_TEXT":
                if (templateField.format !== "") {
                    return new PlainTextFormat(
                        templateField.format,
                    ).formatPlainText(String(fieldValue));
                } else {
                    return String(fieldValue);
                }
            case "FORMAT_DATE":
                // return an empty string if the date turned out not to be a valid date object
                return isDayMonthYear(fieldValue)
                    ? new DateFormat(templateField.format).formatDate(
                          new Date(
                              fieldValue.day,
                              fieldValue.month,
                              fieldValue.year,
                          ),
                      )
                    : "";
            default:
                // fallback
                return String(fieldValue);
        }
    }

    static parseDate(dateString: string): DayMonthYear {
        const [year, month, day] = dateString.split("-").map(Number);
        return { day, month, year };
    }
}

function isDayMonthYear(date: any): date is DayMonthYear {
    return date.day && date.month && date.year;
}
export default Formatter;
