import { SupportingWorksheetResponse, SupportingWorkshetRequest, SupportingWorkshetUpdateRequest } from "src/types";
import { BaseAPIService } from "./BaseApiService";
import { getParamsString, getQueryParams } from "src/utils";

export class SupportingWorksheetService {
    private static _instance: SupportingWorksheetService;
    private readonly BASE_PATH: string = "/api/supportingworksheet";
    private api: BaseAPIService = BaseAPIService.getInstance();

    private constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH}${path}`;
    }

    getSupportingWorksheets(data: SupportingWorkshetRequest): Promise<SupportingWorksheetResponse> {
        return this.api.get<SupportingWorksheetResponse>(
            this.getPath(`?${getParamsString(getQueryParams(data))}`)
        );
    }

    addRowToWorksheet(data: SupportingWorkshetRequest): Promise<SupportingWorksheetResponse> {
        return this.api.post<SupportingWorksheetResponse>(this.getPath("/addrow"), data);
    }

    deleteRowFromWorksheet(data: SupportingWorkshetRequest, rowIdx): Promise<SupportingWorksheetResponse> {
        return this.api.delete<SupportingWorksheetResponse>(this.getPath("/deleterow/"+rowIdx), data);
    }

    updateCellInWorksheet(data: SupportingWorkshetUpdateRequest): Promise<SupportingWorksheetResponse> {
        return this.api.put<SupportingWorksheetResponse>(this.getPath("/update"), data);
    }

    public static getInstance(): SupportingWorksheetService {
        if (!SupportingWorksheetService._instance) {
            SupportingWorksheetService._instance = new SupportingWorksheetService();
        }
        return SupportingWorksheetService._instance;
    }
}