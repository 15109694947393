import React, { FC } from "react";
import { Product, SubAccount, SubAccountWithCompanies } from "src/types";
import {
    StyledListItem,
    StyledListItemHeader,
} from "../ExtensiveSearchModal.styled";
import ContactsIcon from "@mui/icons-material/Contacts";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AccountService } from "src/services";

interface SubAccountSearchResultProps {
    subAccount: SubAccount;
    closeModal: () => void;
}

const SubAccountSearchResult: FC<SubAccountSearchResultProps> = ({
    subAccount,
    closeModal,
}) => {
    const product: Product = useAppSelector(
        (state) => state.Product.value,
    ) as Product;

    const accounts = useAppSelector(
        (state) => state?.[product?.productName].value?.accounts || [],
    );

    const dispatch = useAppDispatch();

    const accountService = AccountService.getInstance();

    const handleClick = async () => {
        const account = accounts.find(
            (account) => account?.id === subAccount?.parentId,
        );

        // There is a circular dependency in the backend with the subaccount and company
        // So here, making an api call to get the subAccounts with the companies
        // and then setting the selected subAccount with companies

        try {
            // Get all subaccounts with companies for the selected account
            const selectedAccountSubAccountsWithCompanies: SubAccountWithCompanies[] =
                await accountService.getAccountSubAccountsWithCompanies(
                    account?.id,
                );

            // Find the selected subaccount with companies that matches the selected subaccount from the search results
            const subAccountWithCompanies =
                selectedAccountSubAccountsWithCompanies.find(
                    (selectedAccountSubaccountWithCompanies) =>
                        selectedAccountSubaccountWithCompanies?.id ===
                        subAccount?.id,
                );

            dispatch(
                GlobalStateActions[product?.productName]?.setSelectedAccount(
                    account,
                ),
            );

            dispatch(
                GlobalStateActions[product?.productName]?.setSelectedComponent(
                    "Account",
                ),
            );

            // We do not have a selected indicator for subaccounts
            // dispatch(
            //     GlobalStateActions[
            //         product?.productName
            //     ]?.setSelectedAccountSubWithCompanies(subAccountWithCompanies),
            // );
        } catch (error) {
            console.error(error);
        }

        closeModal();
    };

    return (
        <StyledListItem itemType="subaccount" onClick={handleClick}>
            <div className="list-item-icon">
                <ContactsIcon />
            </div>
            <div>
                <StyledListItemHeader>SubAccount</StyledListItemHeader>
                <div>Name: {subAccount?.name}</div>
            </div>
        </StyledListItem>
    );
};

export default SubAccountSearchResult;
