import { Jurisdiction } from "src/types";
import { BaseAPIService } from "./BaseApiService";

export class SBPJurisdictionService {
    private static _instance: SBPJurisdictionService;
    private readonly BASE_PATH: string = "/api/sbpjurisdiction";
    private api: BaseAPIService = BaseAPIService.getInstance();

    getSBPJurisdictionsByYear(year: number): Promise<Jurisdiction[]> {
        return this.api.get<Jurisdiction[]>(`${this.BASE_PATH}/${year}`);
    }

    public static getInstance(): SBPJurisdictionService {
        if (!SBPJurisdictionService._instance) {
            SBPJurisdictionService._instance = new SBPJurisdictionService();
        }
        return SBPJurisdictionService._instance;
    }
}
