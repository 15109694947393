import { CreateReturnPDFPayload, MimeType, QueryParams, ReturnPDFResponseDownloadable } from "src/types";
import { BaseAPIService } from "./BaseApiService";
import { getParamsString } from "src/utils";

export class ReturnPDFService {
    private static _instance: ReturnPDFService;
    private readonly BASE_PATH: string = "/api/return-pdf";
    private readonly BASE_PATH_INSTRUCTIONS: string = "/api/return-instructions-pdf";
    private readonly TIME_ZONE: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    private api: BaseAPIService = BaseAPIService.getInstance();

    constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    private getPathInstructions(path: string): string {
        return `${this.BASE_PATH_INSTRUCTIONS + path}`;
    }

    async getReturnPDF(params: QueryParams): Promise<Blob> {
        const response = await this.api.request(
            this.getPath(`?${getParamsString(params)}`),
            "GET",
            undefined,
            {
                headers: {
                    Accept: MimeType.PDF,
                    "Content-Type": MimeType.PDF,
                },
            },
            true
        );
        return response.blob();
    }

    async getReturnPDFInstruccions(params: QueryParams): Promise<Blob> {
        const response = await this.api.request(
            this.getPathInstructions(`?${getParamsString(params)}`),
            "GET",
            undefined,
            {
                headers: {
                    Accept: MimeType.PDF,
                    "Content-Type": MimeType.PDF,
                },
            },
            true
        );
        return response.blob();
    }

    async generateDownloadableReturnPDF(data: CreateReturnPDFPayload): Promise<ReturnPDFResponseDownloadable> {
        const response = await this.api.post(
            this.getPath(""),
            data,
            undefined,
            true,
        );

        const contentDispositionHeader = response.headers.get(
            "Content-Disposition"
        ) ?? "";

        return response.blob().then((blob) => ({ blob, contentDispositionHeader }));
    }

    async generateDownloadableReturnsBulk(data: CreateReturnPDFPayload): Promise<ReturnPDFResponseDownloadable> {
        const response = await this.api.post(
            this.getPath(`/bulk?tz=${this.TIME_ZONE}`),
            data,
            undefined,
            true,
        );

        const contentDispositionHeader = response.headers.get(
            "Content-Disposition"
        ) ?? "";

        return response.blob().then((blob) => ({ blob, contentDispositionHeader }));
    }

    async generateDownloadableAllPdf(data: CreateReturnPDFPayload): Promise<ReturnPDFResponseDownloadable> {
        const response = await this.api.post(
            this.getPath(`/download-all`),
            data,
            undefined,
            true,
        );

        const contentDispositionHeader = response.headers.get(
            "Content-Disposition"
        ) ?? "";

        return response.blob().then((blob) => ({ blob, contentDispositionHeader }));
    }


    public static getInstance(): ReturnPDFService {
        if (!ReturnPDFService._instance) {
            ReturnPDFService._instance = new ReturnPDFService();
        }
        return ReturnPDFService._instance;
    }
}
