import { DataGridRow, LoginData, ReturnDocumentId } from "src/types";
import { BaseAPIService } from "./BaseApiService";

export class WebLoginService {
    private static _instance: WebLoginService;
    private api: BaseAPIService = BaseAPIService.getInstance();

    private readonly BASE_PATH: string = "/api/weblogin";

    private constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    public async getLoginDataByRowId(row: DataGridRow): Promise<LoginData> {
        return this.api.get<LoginData>(
            this.getPath(`/credentials-by-row?loginScheduleRowId=${row.id}`),
        );
    }

    public async getLoginDataByReturnId(
        returnId: ReturnDocumentId,
    ): Promise<LoginData> {
        let {
            companyId,
            productId,
            taxYearId,
            folderId,
            moduleId,
            returnId: id,
            retalFolderId,
        } = returnId;
        return this.api.get<LoginData>(
            this.getPath(
                `/credentials-by-return-id?companyId=${companyId}&productId=${productId}&taxYear=${taxYearId}&folderId=${folderId}&moduleId=${moduleId}&returnId=${id}&retalFolderId=${retalFolderId}`,
            ),
        );
    }

    public static getInstance() {
        if (!WebLoginService._instance) {
            WebLoginService._instance = new WebLoginService();
        }
        return WebLoginService._instance;
    }
}
