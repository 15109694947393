import React, { useEffect } from "react";
import {
    GridCellParams,
    GridColDef,
    GridRowParams,
    GridValidRowModel,
    useGridApiRef,
} from "@mui/x-data-grid-premium";
import { SupportingWorksheetService } from "src/services";
import {
    SupportingWorksheetCell,
    SupportingWorksheetCellType,
    SupportingWorksheetResponse,
    SupportingWorksheetRow,
    SupportingWorksheetRowType,
    SupportingWorkshetRequest,
} from "src/types";

import {
    DataGridPremiumStyled,
    SupportingWorksheetHeader,
    SupportingWorksheetLoading,
    SupportingWorksheetStyled,
} from "./SupportingWorksheet.styled";
import { Button, ButtonGroup, Chip } from "@mui/material";
import Loader from "../Loader/Loader";
import { PlaylistAdd, PlaylistRemove, Sync } from "@mui/icons-material";
import { formatCell } from "./SupportingWorksheetFormatter";

interface SupportingWorksheetProps {
    supportingWorkSheetId: number;
    companyId: number;
    productId: number;
    taxYearId: number;
    folderId: string;
    moduleId: string;
    returnId: string;
    retalFolderId: string;
    setTitle: React.Dispatch<React.SetStateAction<string>>;
}

const SupportingWorksheet: React.FC<SupportingWorksheetProps> = ({
    supportingWorkSheetId,
    companyId,
    productId,
    taxYearId,
    folderId,
    moduleId,
    returnId,
    retalFolderId,
    setTitle,
}) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const supportingWorksheetService = SupportingWorksheetService.getInstance();

    const [rows, setRows] = React.useState<Array<any>>([]);
    const [columns, setColumns] = React.useState<GridColDef[]>([]);
    const [supportingWorksheetData, setSupportingWorksheetData] =
        React.useState<SupportingWorksheetResponse>();
    const [canAddRows, setCanAddRows] = React.useState<boolean>(false);
    const apiRef = useGridApiRef();

    useEffect(() => {
        fetchData();
    }, [supportingWorkSheetId]);

    useEffect(() => {
        if (supportingWorksheetData) {
            const canAddRows =
                apiRef.current.getRowsCount() <
                supportingWorksheetData.maxRows + 1;
            setCanAddRows(canAddRows);
        }
    }, [supportingWorksheetData]);

    const getRequestData = (): SupportingWorkshetRequest => {
        return {
            companyId,
            productId,
            taxYearId,
            folderId,
            moduleId,
            returnId,
            retalFolderId,
            supportingWorkSheetId,
        };
    };

    const setDataGridData = (data: SupportingWorksheetResponse) => {
        setSupportingWorksheetData(data);
        let columns: GridColDef[] = [];
        let rows: Array<any> = [];

        setTitle(data.title);

        data.columns.map((column) => {
            columns.push({
                field: column.colNumber,
                headerName: column.description,
                width: column.width,
                renderHeader: (params) => {
                    return (
                        <SupportingWorksheetHeader
                            dangerouslySetInnerHTML={{
                                __html: params.colDef.headerName || "",
                            }}
                        />
                    );
                },
                renderCell: (params) => {
                    const cellData: SupportingWorksheetCell =
                        params.row[params.colDef.field];
                    const isOverride =
                        cellData.override !== undefined &&
                        cellData.override !== "";
                    return (
                        <span
                            style={{
                                color: isOverride ? "red" : "blue",
                            }}
                        >
                            {params.value}
                        </span>
                    );
                },
                valueGetter: (value: SupportingWorksheetCell) => {
                    return formatCell(value);
                },
                valueSetter: (
                    value: string,
                    row,
                    column,
                ): GridValidRowModel => {
                    row[column.field].override = value;
                    return row;
                },
                sortable: false,
                filterable: false,
                disableReorder: true,
                disableColumnMenu: true,
                editable: true, // with this flag enable i allow isCellEditable to be called
            });
        });

        data.rows.map((row, idx) => {
            let rowData = {
                id: idx,
                rowType: row.rowType,
            };

            row.cells.map((cell) => {
                rowData[cell.colNumber] = cell;
            });
            rows.push(rowData);
        });

        setRows(rows);
        setColumns(columns);
    };

    const fetchData = async () => {
        setLoading(true);
        const data: SupportingWorkshetRequest = getRequestData();

        const supportingWorksheet: SupportingWorksheetResponse =
            await supportingWorksheetService
                .getSupportingWorksheets(data)
                .then((res) => {
                    setLoading(false);
                    return res;
                });
        setDataGridData(supportingWorksheet);
    };

    const addRow = async () => {
        if (supportingWorksheetData) {
            if (canAddRows) {
                setLoading(true);
                const data: SupportingWorkshetRequest = getRequestData();
                const sw: SupportingWorksheetResponse =
                    await supportingWorksheetService
                        .addRowToWorksheet(data)
                        .then((res) => {
                            setLoading(false);
                            return res;
                        });
                setDataGridData(sw);
            }
        }
    };

    const deleteRow = async () => {
        const data: SupportingWorkshetRequest = getRequestData();
        const selectedRowIdx = getSelectedRowIdx();
        if (selectedRowIdx) {
            setLoading(true);
            const supportingWorksheet: SupportingWorksheetResponse =
                await supportingWorksheetService
                    .deleteRowFromWorksheet(data, selectedRowIdx)
                    .then((res) => {
                        setLoading(false);
                        return res;
                    });
            setDataGridData(supportingWorksheet);
            apiRef.current.setRowSelectionModel([]);
        }
    };

    const isCellEditable = (params: GridCellParams) => {
        if (loading) return false;
        const columnName = params.colDef.field;
        const cellData: SupportingWorksheetCell = params.row[columnName];

        if (cellData.isEditable !== undefined && cellData.isEditable === false)
            return false;

        switch (cellData.cellType) {
            case SupportingWorksheetCellType.TextField:
                return true;
            case SupportingWorksheetCellType.NumberField:
                return true;
            case SupportingWorksheetCellType.LabelField:
                return false;
            default:
                return false;
        }
    };

    const getUpdatedSupportingWorksheet = () => {
        const rows: Array<Record<number, SupportingWorksheetCell> | null> =
            apiRef.current.getAllRowIds().map((id) => {
                return apiRef.current.getRow(id);
            });

        let newSupportingWorksheet: SupportingWorksheetResponse = Object.assign(
            {},
            supportingWorksheetData,
        );

        if (rows.length > 0) {
            newSupportingWorksheet.rows.forEach((row, rowIdx) => {
                let dataTableRow = rows[rowIdx];
                row.cells.forEach((cell) => {
                    let newOverride = "";
                    if (dataTableRow) {
                        newOverride = dataTableRow[cell.colNumber].override;
                    }
                    cell.override = newOverride;
                });
            });
        } else {
            console.error("No rows found in the data grid");
        }

        return newSupportingWorksheet;
    };

    const updateData = async () => {
        if (supportingWorksheetData) {
            const data: any = {
                supportingWorkSheetIdDTO: getRequestData(),
                supportingWorkSheet: getUpdatedSupportingWorksheet(),
            };
            const updatedSupportingWorksheet =
                await supportingWorksheetService.updateCellInWorksheet(data);
            setDataGridData(updatedSupportingWorksheet);
        }
    };

    const onCellEditStop = async (params: GridCellParams, event) => {
        setLoading(true);
        await updateData().finally(() => {
            setLoading(false);
        });
    };

    const getToolbar = () => {
        return (
            <div
                style={{
                    display: "flex",
                    padding: 8,
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <ButtonGroup
                    variant="outlined"
                    aria-label="Loading button group"
                >
                    <Button
                        startIcon={<PlaylistAdd />}
                        disabled={loading || !canAddRows}
                        onClick={() => {
                            addRow();
                        }}
                    >
                        Add Row
                    </Button>
                    <Button
                        startIcon={<PlaylistRemove />}
                        disabled={loading || !isRowsSelected()}
                        onClick={() => {
                            deleteRow();
                        }}
                    >
                        Delete Row
                    </Button>
                </ButtonGroup>
                {loading && (
                    <Chip
                        size="small"
                        variant="outlined"
                        label="loading"
                        color="default"
                        icon={<Sync />}
                    />
                )}
            </div>
        );
    };

    const getSelectedRowIdx = () => {
        return apiRef.current.getSelectedRows().values().next()?.value?.id;
    };

    const isRowsSelected = (): boolean => {
        return !!getSelectedRowIdx();
    };

    const isRowSelectable = (
        params: GridRowParams<GridValidRowModel>,
    ): boolean => {
        const row: SupportingWorksheetRow =
            params.row as SupportingWorksheetRow;
        return row.rowType === SupportingWorksheetRowType.USER_DEFINED;
    };

    return (
        <SupportingWorksheetStyled
            $minWidth={supportingWorksheetData?.width || 400}
            $minHeight={supportingWorksheetData?.height || 300}
        >
            {rows.length > 0 ? (
                <DataGridPremiumStyled
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    isCellEditable={isCellEditable}
                    onCellEditStop={onCellEditStop}
                    isRowSelectable={isRowSelectable}
                    slots={{
                        toolbar: getToolbar,
                    }}
                    checkboxSelection
                    disableMultipleRowSelection
                    hideFooter
                />
            ) : (
                <SupportingWorksheetLoading
                    $minWidth={supportingWorksheetData?.width || 400}
                    $minHeight={supportingWorksheetData?.height || 300}
                >
                    <Loader />
                </SupportingWorksheetLoading>
            )}
        </SupportingWorksheetStyled>
    );
};

export default SupportingWorksheet;
