import { configureStore } from "@reduxjs/toolkit";
import accountAdminReducer from "./slices/AccountAdminSlice";
import agentReducer from "./slices/AgentSlice";
import allocatorReducer from "./slices/AllocatorSlice";
import companiesReducer from "./slices/CompaniesSlice";
import statesReducer from "./slices/StatesSlice";
import formsPlusReducer from "./slices/FormsPlusSlice";
import premiumTaxReducer from "./slices/PremiumSlice";
import productReducer from "./slices/ProductSlice";
import productsReducer from "./slices/ProductsSlice";
import selfReducer from "./slices/SelfSlice";
import tabsReducer from "./slices/TabsSlice";
import municipalReducer from "./slices/MunicipalSlice";
import userOptionsReducer from "./slices/UserOptionsSlice";
import { productMiddleware } from "./middlewares/productMiddleware";

const store = configureStore({
    reducer: {
        Product: productReducer,
        Premium: premiumTaxReducer,
        FormsPlus: formsPlusReducer,
        Agent: agentReducer,
        Municipal: municipalReducer,
        Products: productsReducer,
        Self: selfReducer,
        Companies: companiesReducer,
        States: statesReducer,
        "Account Admin": accountAdminReducer,
        Allocator: allocatorReducer,
        Tabs: tabsReducer,
        UserOptions: userOptionsReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(productMiddleware),
});

export default store;

// https://react-redux.js.org/using-react-redux/usage-with-typescript#define-root-state-and-dispatch-types
export type TTSState = ReturnType<typeof store.getState>;
export type TTSDispatch = typeof store.dispatch;
