import React from "react";
import {
    GridColumnMenuColumnsItem,
    GridColumnMenuFilterItem,
    GridColumnMenuProps,
    GridPinnedColumnPosition,
} from "@mui/x-data-grid";
import {
    PushPinOutlined as PushPinIcon,
    RemoveCircleOutline as RemoveCircleOutlineIcon,
} from "@mui/icons-material";
import { Divider, MenuItem, Stack } from "@mui/material";
import { GridColumnMenuGroupingItem } from "@mui/x-data-grid-premium";

export const CustomColumnMenu = (props: GridColumnMenuProps, apiRef) => {
    const itemProps = {
        colDef: props.colDef,
        onClick: props.hideMenu,
    };

    const pinToLeft = () => {
        apiRef.current.pinColumn(
            props.colDef.field,
            GridPinnedColumnPosition.LEFT,
        );
    };

    const unPin = () => {
        apiRef.current.unpinColumn(props.colDef.field);
    };

    const isPinned: GridPinnedColumnPosition | boolean =
        apiRef.current.isColumnPinned(props.colDef.field);

    return (
        <>
            <Stack px={0.5} py={0.5}>
                <MenuItem
                    onClick={() => {
                        isPinned ? unPin() : pinToLeft();
                    }}
                >
                    {isPinned ? (
                        <RemoveCircleOutlineIcon
                            sx={{
                                marginRight: "12px",
                                fill: "rgba(0, 0, 0, 0.54)",
                            }}
                        />
                    ) : (
                        <PushPinIcon
                            sx={{
                                marginRight: "12px",
                                fill: "rgba(0, 0, 0, 0.54)",
                            }}
                        />
                    )}{" "}
                    {isPinned ? "Unpin" : "Pin"}
                </MenuItem>
                <GridColumnMenuFilterItem {...itemProps} />
                <GridColumnMenuGroupingItem {...itemProps} />
            </Stack>
            <Divider />
            <Stack px={0.5} py={0.5}>
                <GridColumnMenuColumnsItem {...itemProps} />
            </Stack>
        </>
    );
};
