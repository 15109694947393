import { GridColDef, GridRowModel } from "@mui/x-data-grid-premium";
import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Loader from "src/components/Loader/Loader";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { AllocatorService } from "src/services/AllocatorService";
import {
    ALLOCATOR_SEARCH_PARAMS,
    AllocatorJobStatisticsData,
    FixMeLater,
} from "src/types";
import { StyledDataGrid } from "../../Allocator.styled";
import { useSnackbar } from "notistack";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";

const JobSummaryGrid: FC = () => {
    const allocatorService = AllocatorService.getInstance();

    const [rows, setRows] = useState<GridRowModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchParams] = useSearchParams();
    const jobId = searchParams.get(ALLOCATOR_SEARCH_PARAMS.JOB_ID);
    const columns: GridColDef[] = [
        {
            field: "matchCode",
            headerName: "Match Code",
            sortable: false,
            flex: 1,
            minWidth: 110,
            maxWidth: 140,
        },
        {
            field: "matchDescription",
            headerName: "Match Description",
            sortable: false,
            flex: 1,
            minWidth: 200,
        },
        {
            field: "recordsCount",
            headerName: "# of records",
            sortable: false,
            flex: 1,
            minWidth: 110,
            maxWidth: 160,
        },
        {
            field: "percentage",
            headerName: "% of records",
            sortable: false,
            flex: 1,
            minWidth: 110,
            maxWidth: 160,
        },
        {
            field: "premiumAmount",
            headerName: "Total of Source Premiums",
            sortable: false,
            flex: 1,
            minWidth: 140,
            maxWidth: 250,
        },
        {
            field: "premiumAmountPercentage",
            headerName: "% of Source Premiums",
            sortable: false,
            flex: 1,
            minWidth: 140,
        },
    ];

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const reportTabsLoading: boolean = useAppSelector(
        (state) => state?.[product?.productName]?.value?.reportTabsLoading
    );

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const fetchJobStatistics = async () => {
            setIsLoading(true);

            try {
                const statistics = await allocatorService.getJobStatistics(
                    Number(jobId)
                );

                setRows(
                    statistics?.data?.map(
                        (row: AllocatorJobStatisticsData, index: number) => {
                            return {
                                ...row,
                                id: index,
                            };
                        }
                    )
                );
                dispatch(
                    GlobalStateActions[product?.productName].setSelectedFileName(
                        statistics?.meta?.filename
                    )
                );
                dispatch(
                    GlobalStateActions[product?.productName].setSelectedJobStatus(
                        statistics?.meta?.jobStatus
                    )
                );
                dispatch(
                    GlobalStateActions[product?.productName].setStateAbbr(
                        statistics?.meta?.state
                    )
                );
                dispatch(
                    GlobalStateActions[product?.productName].setExportOptions(
                        statistics?.meta?.exportOptions
                    )
                );
            } catch (error) {
                enqueueSnackbar("Error fetching Summary statistics", { variant: "error" });
            } finally {
                dispatch(
                    GlobalStateActions[product?.productName].setReportTabsLoading(
                        false
                    )
                );
                setIsLoading(false);
            }
        };

        if (Number(jobId)) {
            fetchJobStatistics();
        }
    }, []);

    if (reportTabsLoading || isLoading) return <Loader />;

    return (
        <StyledDataGrid
            rows={rows}
            columns={columns}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnReorder
            disableColumnResize
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
            }
            sx={{ ".MuiDataGrid-columnSeparator": { display: "none" } }}
        />
    );
};

export default JobSummaryGrid;
