import styled from "styled-components";

export const FieldToolbarStyled = styled.div`
    display: flex;
    align-items: center;
    padding: 2px;
    border-radius: 20px;
    z-index: 11;
    background-color: white;
    border: 1px solid #354560;
`;