import React, { FC, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { GridColDef } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { AccountAdminActionsReportService } from "src/services/AdminToolActionsReportService";
import { ActionRecord } from "src/types";
import "./ActionsReport.scss";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import Loader from "src/components/Loader/Loader";

const ActionsReport: FC = () => {
    const [actions, setActions] = useState<ActionRecord[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const accountAdminActionsReportService =
        AccountAdminActionsReportService.getInstance();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response =
                    await accountAdminActionsReportService.getActionsReport();
                setActions(response);
                setError(null);
            } catch (error) {
                console.error("Error fetching actions report:", error);
                setError(
                    "Failed to load actions report. Please try again later."
                );
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const columns: GridColDef[] = [
        { field: "id", headerName: "ID", width: 90 },
        { field: "actionType", headerName: "Action Type", width: 150 },
        { field: "whenActionHappened", headerName: "When", width: 180 },
        { field: "whoDidAction", headerName: "Who Did Action", width: 180 },
        { field: "traceId", headerName: "Trace ID", width: 180 },
        { field: "outcome", headerName: "Outcome", width: 120 },
    ];

    return (
        <div className="actions-report-container">
            {error ? (
                <ErrorMessage error={error} />
            ) : isLoading ? (
                <Loader color="white" />
            ) : (
                <Box sx={{ height: 400, width: "100%" }}>
                    <DataGrid rows={actions} columns={columns} />
                </Box>
            )}
        </div>
    );
};

export default ActionsReport;
