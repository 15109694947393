import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Table,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import Loader from "src/components/Loader/Loader";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AccountService } from "src/services";
import { FixMeLater } from "src/types";
import {
    StyledFlexContainer,
    StyledHeader,
    StyledNoDataFound,
    StyledOpenModalButton,
    StyledOpenModalButtonText,
    StyledPaper,
    StyledTableCell,
    StyledTableContainer,
    StyledTableRow,
} from "../../../../AccountAdmin.styled";
import GroupListModal from "../../modals/GroupListModal/GroupListModal";
import "./GroupList.scss";
import GroupListItemMenu from "./components/GroupListItemMenu/GroupListItemMenu";
import { FrameCard } from "src/uikit";

const GroupList = () => {
    const accountService = AccountService.getInstance();
    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarStatus, setSnackbarStatus] = useState<string>("success");

    const [groupListModalOpen, setGroupListModalOpen] =
        useState<boolean>(false);

    const [rightClickedGroup, setRightClickedGroup] =
        useState<FixMeLater>(null);
    const [groupListItemMenuAnchorEl, setGroupListItemMenuAnchorEl] =
        useState<HTMLElement | null>(null);

    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value,
    );
    const selectedAccount = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccount,
    );
    const selectedAccountGroup = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccountGroup,
    );
    const selectedAccountGroups = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccountGroups,
    );

    useEffect(() => {
        const fetchSelectedAccountGroups = async () => {
            setIsLoading(true);
            try {
                const selectedAccountGroups =
                    await accountService.getAccountGroups(selectedAccount?.id);
                dispatch(
                    GlobalStateActions[
                        product?.productName
                    ].setSelectedAccountGroups(selectedAccountGroups),
                );

                dispatch(
                    GlobalStateActions[
                        product?.productName
                    ].setSelectedAccountGroup(selectedAccountGroups?.[0]),
                );
            } catch (error) {
                setError(error as Error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSelectedAccountGroups();
    }, [selectedAccount]);

    const openGroupListItemMenu = (
        event: React.MouseEvent<HTMLElement>,
        group: FixMeLater,
    ) => {
        event.stopPropagation();
        setGroupListItemMenuAnchorEl(event.currentTarget);
        setRightClickedGroup(group);
    };

    const deleteGroup = async (group: FixMeLater) => {
        try {
            await accountService.deleteAccountGroup(group);
            const updatedGroups = selectedAccountGroups?.filter(
                (g) => g?.id !== group?.id,
            );
            dispatch(
                GlobalStateActions[
                    product?.productName
                ].setSelectedAccountGroups(updatedGroups),
            );
            dispatch(
                GlobalStateActions[
                    product?.productName
                ].setSelectedAccountGroup(updatedGroups?.[0]),
            );

            setSnackbarMessage("User group deleted successfully!");
            setSnackbarStatus("success");
            setSnackbarOpen(true);
        } catch (error) {
            console.error("Error in deleteGroup:", error);
            setSnackbarMessage("Unable to delete group!");
            setSnackbarStatus("error");
            setSnackbarOpen(true);
        }
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDialog = async () => {
        handleCloseDialog();
        if (rightClickedGroup) {
            await deleteGroup(rightClickedGroup);
        }
    };

    if (error) return <ErrorMessage error={error} />;

    if (!error && isLoading) return <Loader />;

    return (
        <>
            <div className="group-list-container">
                <FrameCard
                    title="GROUPS"
                    actions={[
                        {
                            text: "Add Group",
                            onClick: () => setGroupListModalOpen(true),
                        },
                    ]}
                >
                    {selectedAccountGroups?.length !== 0 ? (
                        <StyledTableContainer>
                            <Table>
                                {selectedAccountGroups?.map(
                                    (group: FixMeLater) => (
                                        <StyledTableRow
                                            $isSelected={
                                                selectedAccountGroup?.id ===
                                                group?.id
                                            }
                                            key={group.id}
                                            onClick={() => {
                                                dispatch(
                                                    GlobalStateActions[
                                                        product?.productName
                                                    ].setSelectedAccountGroup(
                                                        group,
                                                    ),
                                                );
                                            }}
                                            onContextMenu={(e) => {
                                                e.preventDefault();
                                                openGroupListItemMenu(e, group);
                                            }}
                                        >
                                            <StyledTableCell>
                                                {group.name}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ),
                                )}
                            </Table>
                        </StyledTableContainer>
                    ) : (
                        <StyledNoDataFound>
                            There are no groups associated with this account.
                        </StyledNoDataFound>
                    )}
                </FrameCard>
            </div>
            <GroupListModal
                open={groupListModalOpen}
                onClose={() => setGroupListModalOpen(false)}
            />
            <GroupListItemMenu
                group={rightClickedGroup}
                anchorEl={groupListItemMenuAnchorEl}
                handleCloseGroupListItemMenu={() =>
                    setGroupListItemMenuAnchorEl(null)
                }
                onDelete={handleOpenDialog}
            />

            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Confirm Action</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to perform this action? <br />
                        Deleting a group migrates those group&apos;s companies
                        to the parent account level rather than removing company
                        access entirely
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <StyledFlexContainer justify={"center"}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={handleConfirmDialog}
                        >
                            Confirm
                        </Button>
                        <Button
                            type="button"
                            variant="outlined"
                            color="secondary"
                            onClick={handleCloseDialog}
                        >
                            Cancel
                        </Button>
                    </StyledFlexContainer>
                </DialogActions>
            </Dialog>
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message="User group deleted successfully!"
                severity="success"
            />
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message={snackbarMessage}
                severity={snackbarStatus}
            />
        </>
    );
};

export default GroupList;
