import { HttpMethod, MimeType } from "src/types";
import { HttpError } from "src/utils/HttpError";

export class BaseAPIService {
    private static instance: BaseAPIService;
    private baseUrl: string = `${process.env.REACT_APP_STRATUS_API_BASE_URL}`;
    private tokenGenerator!: () => Promise<string>;

    constructor() {}

    public static getInstance(): BaseAPIService {
        if (!BaseAPIService.instance) {
            BaseAPIService.instance = new BaseAPIService();
        }
        return BaseAPIService.instance;
    }

    public getBaseUrl(): string {
        return this.baseUrl;
    }

    public async getToken(): Promise<string> {
        return await this.tokenGenerator();
    }    

    public async getHeadersWithAuthorization(headers: Record<string, string>): Promise<Record<string, string>> {
        const token = await this.tokenGenerator();
        return {
            ...headers,
            Authorization: `Bearer ${token}`,
        };
    }

    public setTokenGenerator(tokenGenerator: () => Promise<string>) {
        this.tokenGenerator = tokenGenerator;
    }

    private getUrl(path: string): string {
        return `${this.baseUrl + path}`;
    }

    private async getInitialHeaders(): Promise<Record<string, string>> {
        const initialHeaders = await this.getHeadersWithAuthorization({
            "Content-Type": "application/json",
            "User-Agent": ""
        });
        return initialHeaders;
    }

    public async request<T>(
        path: string,
        method: HttpMethod,
        data: any,
        config: RequestInit | undefined
    ): Promise<T>;
    public async request<T>(
        path: string,
        method: HttpMethod,
        data: any,
        config: RequestInit | undefined,
        returnRawResponse?: boolean
    ): Promise<Response>;
    public async request<T>(
        path: string,
        method: HttpMethod,
        data: any,
        config: RequestInit | undefined,
        returnRawResponse?: boolean
    ): Promise<T | Response> {
        console.log("useragent is : " + navigator.userAgent);
        const options: RequestInit = {
            ...config,
            method: method,
            headers: {
                ...(await this.getInitialHeaders()),
                ...config?.headers,
            },
            body: data ? JSON.stringify(data) : null,
        };

        // For Uploading Files using multipart/form-data Content Type must not be specified
        // https://muffinman.io/blog/uploading-files-using-fetch-multipart-form-data/
        if (options?.headers?.["Content-Type"] === MimeType.MULTIPART_FORM_DATA) {
            delete options?.headers?.["Content-Type"];
            options.body = data ?? null;
        }

        const response = await fetch(this.getUrl(path), options);

        if (!response.ok) {
            throw new HttpError(response.statusText, await response.text());
        }

        if (returnRawResponse) return response;

        try {
            return await response.json();
        } catch (e) {
            console.error("Failed to parse the response as JSON. Returning the raw response. Error details:", e);
            return response;
        }
    }

    public async get<T>(url: string, config?: RequestInit): Promise<T>;
    public async get<T>(
        url: string,
        config?: RequestInit,
        returnRawResponse?: boolean
    ): Promise<Response>;
    public async get<T>(
        url: string,
        config?: RequestInit,
        returnRawResponse?: boolean
    ): Promise<T | Response> {
        return this.request(url, "GET", undefined, config, returnRawResponse);
    }

    public async post<T>(
        url: string,
        data?: any,
        config?: RequestInit
    ): Promise<T>;
    public async post<T>(
        url: string,
        data?: any,
        config?: RequestInit,
        returnRawResponse?: boolean
    ): Promise<Response>;
    public async post<T>(
        url: string,
        data?: any,
        config?: RequestInit,
        returnRawResponse?: boolean
    ): Promise<T | Response> {
        return this.request<T>(url, "POST", data, config, returnRawResponse);
    }

    public async put<T>(
        url: string,
        data?: any,
        config?: RequestInit
    ): Promise<T>;
    public async put<T>(
        url: string,
        data?: any,
        config?: RequestInit,
        returnRawResponse?: boolean
    ): Promise<Response>;
    public async put<T>(
        url: string,
        data?: any,
        config?: RequestInit,
        returnRawResponse?: boolean
    ): Promise<T | Response> {
        return this.request<T>(url, "PUT", data, config, returnRawResponse);
    }

    public async delete<T>(
        url: string,
        data?: any,
        config?: RequestInit
    ): Promise<T>;
    public async delete<T>(
        url: string,
        data?: any,
        config?: RequestInit,
        returnRawResponse?: boolean
    ): Promise<Response>;
    public async delete<T>(
        url: string,
        data?: any,
        config?: RequestInit,
        returnRawResponse?: boolean
    ): Promise<T | Response> {
        return this.request<T>(url, "DELETE", data, config, returnRawResponse);
    }
}
