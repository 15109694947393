import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { State } from "src/types";

interface StatesState {
    value: State[];
}

const initialState: StatesState = {
    value: [],
};

export const StatesSlice = createSlice({
    name: "States",
    initialState,
    reducers: {
        setStates: (state, action: PayloadAction<State[]>) => {
            state.value = action.payload;
        },
    },
});

export const { setStates } = StatesSlice.actions;
export default StatesSlice.reducer;
