// TriTech Roles
export enum TRITECH_ROLE {
    CSR = 2147483625,
    TritechAdmin = 2147483627,
    TritechServiceAdmin = 2147483624,
    ClientAccountAdmin = 3,
}

export enum AttachmentType {
    SYSTEM_DEFINED = "SYSTEM_DEFINED",
    USER_DEFINED = "USER_DEFINED",
}
