import {
    GridCellSelectionModel,
    GridColDef,
    GridRowId,
} from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { addressScheduleId } from "src/constants/Schedules";
import { RowDTO, ReturnNode, DataGridRow } from "src/types";
import { checkDisplayOrderInRowDTOs } from "./DataTable.util";

export const selectAllRows = (
    apiRef: React.MutableRefObject<GridApiPremium>,
): void => {
    const columnFields = apiRef.current
        .getAllColumns()
        .map((column) => column.field);
    const rowIds = apiRef.current.getAllRowIds();
    const colNum = columnFields?.length ?? 0;
    const colRows = rowIds?.length ?? 0;
    const totalCells = colNum * colRows;
    const currentSelectedCellsNumber =
        apiRef?.current.getSelectedCellsAsArray().length;
    const areAllCellsSelected = totalCells === currentSelectedCellsNumber;
    const gridCellSelectionModel: GridCellSelectionModel = {};

    rowIds?.forEach((rowId) => {
        gridCellSelectionModel[rowId] = {};
        columnFields?.forEach((column) => {
            gridCellSelectionModel[rowId][column] = true;
        });
    });

    if (areAllCellsSelected) {
        apiRef.current.setCellSelectionModel({});
    } else {
        apiRef.current.setCellSelectionModel(gridCellSelectionModel);
        // In order to copy using Ctrl+C the focus needs to be in the grid
        apiRef.current.setCellFocus(rowIds[0], columnFields[0]);
    }
};

export const selectColumnCells = (
    apiRef: React.MutableRefObject<GridApiPremium>,
    columnName: string,
) => {
    const currentSelectedCells = apiRef?.current.getSelectedCellsAsArray();
    const rowIds = apiRef?.current.getAllRowIds();
    const isSameColumn = currentSelectedCells[0]?.field === columnName;
    const isAllColSelected = currentSelectedCells.length === rowIds.length;

    let gridCellSelectionModel: GridCellSelectionModel = {};

    rowIds.forEach((rowId) => {
        gridCellSelectionModel[rowId] = {};
        gridCellSelectionModel[rowId][columnName] = true;
    });

    if (isAllColSelected && isSameColumn) {
        apiRef.current.setCellSelectionModel({});
    } else {
        apiRef.current.setCellSelectionModel(gridCellSelectionModel);
    }
};

const getCellValue = (
    id: string,
    column: string,
    apiRef?: React.MutableRefObject<GridApiPremium>,
): string => {
    return apiRef?.current?.getCellValue?.(id, column) ?? "";
};

export const convertSelectedCellsForExcelWithHeaders = (
    data: GridCellSelectionModel,
    columns: GridColDef[],
    apiRef?,
): string => {
    const rowIds = Object.keys(data);
    const columnHeaders = Object.keys(Object.values(data)[0] ?? "");
    const firstColumnName = columns[0]?.headerName ?? "";

    const columnHeaderRow = columnHeaders.includes(firstColumnName)
        ? [...columnHeaders].join("\t")
        : [firstColumnName, ...columnHeaders].join("\t");

    const rows = rowIds.map((id) => {
        const rowData = columnHeaders
            .filter((column) => column !== firstColumnName)
            .map((column) => getCellValue(id, column, apiRef))
            .join("\t");
        return `${getCellValue(id, firstColumnName, apiRef)}\t${rowData}`;
    });

    return [columnHeaderRow, ...rows].join("\n");
};

export const convertSelectedCellsForExcel = (
    data: GridCellSelectionModel,
    apiRef?,
): string => {
    const rows = Object.entries(data).map(([id, columns]) =>
        Object.keys(columns)
            .map((column) => getCellValue(id, column, apiRef))
            .join("\t"),
    );
    return rows.join("\n");
};

// TODO: add tests
export const convertSelectedRowsForExcel = (
    selectedRows: GridRowId[],
    rowDTOs: RowDTO[],
    returnNode: ReturnNode,
): string => {
    let text = "";
    const canUseDisplayOrder = checkDisplayOrderInRowDTOs(rowDTOs);
    selectedRows.forEach((rowIndex, index) => {
        const selectedRow = rowDTOs.find((x) => {
            const rowIdx = canUseDisplayOrder ? x.displayOrder : x.rowId;
            return rowIdx == rowIndex;
        })!!;

        const cells =
            selectedRow.cellGroups[
                returnNode.id !== addressScheduleId
                    ? 0
                    : selectedRow.currentIndex
            ].cells;

        const concatenatedValues: string[] = [];
        cells.slice().forEach((item) => {
            const value = item.ov !== "" ? item.ov : item.v;
            concatenatedValues.push(value);
        });

        const rowText = concatenatedValues.join("\t");
        text += rowText;

        if (index !== selectedRows.length - 1) {
            text += "\n";
        }
    });
    return text;
};

// TODO: add tests
export const convertSelectedRowsForExcelWithHeaders = (
    selectedRows: GridRowId[],
    columns: GridColDef[],
    rowDTOs: RowDTO[],
    returnNode: ReturnNode,
): string => {
    let text = "";
    const columnNames = columns.map((column) => column.headerName).join("\t");
    text += columnNames + "\n";
    text += convertSelectedRowsForExcel(selectedRows, rowDTOs, returnNode);
    return text;
};

export const handleCopy = (
    rowSelected,
    rowDTOs,
    returnNode,
    cellSelected,
    apiRef,
    handleCloseMenu,
) => {
    let clipboardText = "";
    if (rowSelected.length > 0) {
        clipboardText = convertSelectedRowsForExcel(
            rowSelected,
            rowDTOs,
            returnNode,
        );
    } else {
        clipboardText = convertSelectedCellsForExcel(cellSelected, apiRef);
    }
    navigator.clipboard.writeText(clipboardText).then(() => {
        handleCloseMenu();
    });
};

export const handleCopyWithHeaders = (
    rowSelected,
    columns,
    rowDTOs,
    returnNode,
    cellSelected,
    apiRef,
    handleCloseMenu,
) => {
    let clipboardText = "";
    if (rowSelected.length > 0) {
        clipboardText = convertSelectedRowsForExcelWithHeaders(
            rowSelected,
            columns,
            rowDTOs,
            returnNode,
        );
    } else {
        clipboardText = convertSelectedCellsForExcelWithHeaders(
            cellSelected,
            columns,
            apiRef,
        );
    }
    navigator.clipboard.writeText(clipboardText).then(() => {
        handleCloseMenu();
    });
};

export const handlePaste = async (
    setIsPasteAvailable,
    handleSnackbar,
    handleCloseMenu,
    rows,
    columns,
    cellSelected,
    processRowUpdate,
) => {
    let clipboardText = "";
    try {
        const clipboardText = await navigator.clipboard.readText();
        setIsPasteAvailable(!!clipboardText);
    } catch (error) {
        setIsPasteAvailable(false);
        console.error(error);
        const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;

        // Customize the error message based on the OS
        const errorMessage = `The paste function is not available in the browser. Use ${
            isMac ? "Command+V" : "Control+V"
        } instead`;
        handleSnackbar(errorMessage, "error");
        return;
    } finally {
        handleCloseMenu();
    }

    try {
        clipboardText = await navigator.clipboard.readText();
        const rowsToPaste = clipboardText
            .split("\n")
            .map((row) => row.split("\t").map((cell) => cell.trim()));

        const updatedRows: DataGridRow[] = [...rows];
        const previousRows: DataGridRow[] = [...rows];

        let startRowIdx = 0;
        let startColIdx = 0;

        if (Object.keys(cellSelected).length > 0) {
            const selectedRowId = Object.keys(cellSelected)[0];
            const selectedColumnId = Object.keys(
                cellSelected[selectedRowId],
            )[0];
            startRowIdx =
                rows.findIndex((row) => String(row.id) === selectedRowId) ?? 0;

            startColIdx =
                columns.findIndex(
                    (col) => col.headerName === selectedColumnId,
                ) ?? 0;
        }

        rowsToPaste.forEach((row, rowIdx) => {
            const targetRowIdx = startRowIdx + rowIdx;
            if (targetRowIdx < updatedRows.length) {
                const updatedRow = { ...updatedRows[targetRowIdx] };
                row.forEach((cellValue, colIdx) => {
                    const targetColIdx = startColIdx + colIdx;
                    if (targetColIdx >= 0 && targetColIdx < columns.length) {
                        const targetColField = columns[targetColIdx].field;
                        if (targetColField) {
                            const isReturnOrNewLine =
                                cellValue.includes("\n") ||
                                cellValue.includes("\r");
                            updatedRow[targetColField] = isReturnOrNewLine
                                ? ""
                                : cellValue;
                        }
                    }
                });

                updatedRows[targetRowIdx] = updatedRow;
                processRowUpdate(updatedRow, previousRows[targetRowIdx]);
            }
        });
    } catch (error) {
        console.error(error);
        handleSnackbar(`There has been an error . ${error}`, "error");
    } finally {
        handleCloseMenu();
    }
};
