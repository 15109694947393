import React, { FC, useRef } from "react";
import { StyledClearButton, StyledRadioGroup, StyledSignatureInput, StyledTypingTab } from "./TypingTab.styled";
import { FormControlLabel, Radio } from "@mui/material";

interface TypingTabProps {
    index: number;
    value: number;
    isLoading: boolean;
    textSignature: string;
    setTextSignature: (text: string) => void;
    signatureFont: string;
    setSignatureFont: (font: string) => void;
}

const TypingTab: FC<TypingTabProps> = ({ 
    value, 
    index, 
    isLoading, 
    textSignature, 
    setTextSignature,
    signatureFont,
    setSignatureFont
}) => {
    const textFieldRef = useRef<HTMLInputElement>(null);

    const clearSignatureInput = () => {
        if (textFieldRef.current) {
            textFieldRef.current.value = "";
            setTextSignature("");
        }
    }

    return (
        <StyledTypingTab
            role="tabpanel"
            id={`signature-tabpanel-${index}`}
            aria-labelledby={`signature-tab-${index}`}
            $isHidden={value !== index}
        >
            <StyledSignatureInput
                placeholder="Signature"
                ref={textFieldRef}
                disabled={isLoading}
                $signatureFont={signatureFont}
                onChange={(e) => setTextSignature(e?.target?.value)}
            />
            <StyledClearButton
                disabled={isLoading}
                disableRipple
                $isTextSignatureFilled={!!textSignature?.length}
                onClick={clearSignatureInput}
            >
                { textSignature?.length ? "Clear signature" : "Sign here" }
            </StyledClearButton>
            <StyledRadioGroup
                value={signatureFont}
                onChange={(e) => setSignatureFont(e?.target?.value)}
            >
                <FormControlLabel
                    value={"Arabella"}
                    control={<Radio size="small"/>}
                    label={textSignature?.trim() || "Signature"}
                    disabled={isLoading}
                    sx={{ "& .MuiFormControlLabel-label": { fontFamily: "Arabella" } }}
                />
                <FormControlLabel
                    value={"GreatVibes-Regular"}
                    control={<Radio size="small"/>}
                    label={textSignature?.trim() || "Signature"}
                    disabled={isLoading}
                    sx={{ "& .MuiFormControlLabel-label": { fontFamily: "GreatVibes-Regular" } }}
                />
                <FormControlLabel
                    value={"Montez-Regular"}
                    control={<Radio size="small"/>}
                    label={textSignature?.trim() || "Signature"}
                    disabled={isLoading}
                    sx={{ "& .MuiFormControlLabel-label": { fontFamily: "Montez-Regular" } }}
                />
                <FormControlLabel
                    value={"Sacramento-Regular"}
                    control={<Radio size="small"/>}
                    label={textSignature?.trim() || "Signature"}
                    disabled={isLoading}
                    sx={{ "& .MuiFormControlLabel-label": { fontFamily: "Sacramento-Regular" } }}
                />
            </StyledRadioGroup>
        </StyledTypingTab>
    );
};

export default TypingTab;
