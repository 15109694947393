import { Tooltip } from "@mui/material";
import React, { CSSProperties, FC, useEffect, useState } from "react";
import {
    FixMeLater,
    NumField,
    TextField,
    CheckField,
    ReturnPage,
} from "src/types";
import DynamicColors from "src/utils/DynamicColors";
import "./ComboBoxField.scss";
import { getFieldId } from "../PdfInputFields.util";
import { useDebugMode } from "src/hooks/debugMode";
import { useDebugModeDisplayHidden } from "../../../hooks/debugModeDisplayHidden";

interface ComboBoxFieldProps {
    scale: number;
    templateField: FixMeLater;
    value: {
        value: string | number | boolean;
        isOverride: boolean;
        documentField: NumField | TextField | CheckField;
    };
    updateReturnDocument: (
        fieldType: string,
        documentNode: NumField | TextField | CheckField,
        page: ReturnPage,
        overrideValue: string | number | boolean,
    ) => void;
    isLocked?: boolean;
    returnPage: ReturnPage;
    id: string;
}

const ComboBoxField: FC<ComboBoxFieldProps> = ({
    scale,
    templateField,
    value,
    updateReturnDocument,
    isLocked,
    id,
    returnPage,
}) => {
    const { position, size } = templateField.screenBox;

    const {
        options,
        toolTip,
        tabIndex,
        isEnabled,
        overrideState,
        style,
        // fieldType,
        // id,
    } = templateField;

    const pageTabIndex = returnPage.attributes.displayOrder * 100 + tabIndex;

    const [selectedOption, setSelectedOption] = useState<string>(
        value?.value.toString() ?? "",
    );

    const debugMode = useDebugMode();
    const debugModeDisplayHidden = useDebugModeDisplayHidden();

    useEffect(() => {
        // Update inputValue when value.value changes
        setSelectedOption(value?.value.toString() ?? "");
    }, [value]);

    const handleChange = (event) => {
        const newValue = event?.target?.value;
        if (newValue != value.value) {
            updateReturnDocument(
                "textFields",
                value?.documentField,
                returnPage,
                newValue,
            );
        }
        setSelectedOption(event?.target?.value);
    };

    const dynamicTextFieldColor = DynamicColors.calculateDynamicTextFieldColor(
        templateField?.isEnabled,
        overrideState,
        value?.isOverride,
        debugModeDisplayHidden
    );
    const dynamicBackgroundColor =
        DynamicColors.calculateDynamicBackgroundColor(
            templateField?.isEnabled,
            templateField?.overrideState,
            debugModeDisplayHidden
        );

    // Dynamic styles
    const dynamicStyles: CSSProperties = {
        backgroundColor: dynamicBackgroundColor,
        color: dynamicTextFieldColor,
        left: `${position?.left * scale}px`,
        top: `${position?.top * scale}px`,
        height: `${size?.height * scale}px`,
        width: `${size?.width * scale}px`,
        textAlign: (style?.alignment || "left")?.toLowerCase(),
        fontSize: `${(style?.fontSize || 10) * scale}px`,
        fontWeight: style?.bold ? "bold" : "normal",
        fontStyle: style?.italic ? "italic" : "normal",
        fontFamily: style?.fontName || "inherit",
        cursor: isLocked ? "not-allowed" : "",
    };

    return (
        <Tooltip title={toolTip} placement="top">
            {debugMode ? (
                <input
                    className="combo-box-field-container"
                    style={dynamicStyles}
                    value={"CB" + templateField.id}
                    readOnly={true}
                    tabIndex={pageTabIndex}
                />
            ) : (
                <select
                    className="combo-box-field-container"
                    style={dynamicStyles}
                    id={id}
                    data-testid={id}
                    value={selectedOption}
                    onChange={handleChange}
                    tabIndex={pageTabIndex}
                    disabled={!isEnabled || isLocked}
                    title="ComboBoxField"
                >
                    {options.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            )}
        </Tooltip>
    );
};

export default ComboBoxField;
