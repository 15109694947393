import React, { FC } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { FixMeLater } from "src/types";

interface FormsPlusCompanyOptionsProps {
    formData: FixMeLater;
    onFormDataChange: (newData: FixMeLater) => void;
}

const FormsPlusCompanyOptions: FC<FormsPlusCompanyOptionsProps> = ({
    formData,
    onFormDataChange,
}) => {
    const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        const fieldName = name.split(".")[1];
        onFormDataChange({
            formsplus: {
                ...formData.formsplus,
                [fieldName]: checked,
            },
        });
    };

    return (
        <>
            <FormControlLabel
                control={
                    <Switch
                        checked={
                            formData?.jsonOptions?.formsplus
                                ?.someFormsPlusOption
                        }
                        onChange={handleToggleChange}
                        name="formsplus.someFormsPlusOption"
                    />
                }
                label="FormsPlus Option 1"
            />
        </>
    );
};

export default FormsPlusCompanyOptions;
