import React, { FC } from "react";
import {
    StyledListItem,
    StyledListItemHeader,
} from "../ExtensiveSearchModal.styled";
import { Company, Product } from "src/types";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";

interface CompanySearchResultProps {
    company: Company;
    closeModal: () => void;
}

const CompanySearchResult: FC<CompanySearchResultProps> = ({
    company,
    closeModal,
}) => {
    const product: Product = useAppSelector(
        (state) => state.Product.value,
    ) as Product;

    const accounts = useAppSelector(
        (state) => state?.[product?.productName].value?.accounts || [],
    );

    const dispatch = useAppDispatch();

    const handleClick = () => {
        const account = accounts.find(
            (account) => account?.id === company?.accountId,
        );

        dispatch(
            GlobalStateActions[product?.productName]?.setSelectedAccount(
                account,
            ),
        );

        dispatch(
            GlobalStateActions[product?.productName]?.setSelectedComponent(
                "Companies",
            ),
        );

        dispatch(
            GlobalStateActions[product?.productName]?.setSelectedAccountCompany(
                company,
            ),
        );

        closeModal();
    };

    return (
        <StyledListItem itemType="company" onClick={handleClick}>
            <div className="list-item-icon">
                <ApartmentIcon />
            </div>
            <div>
                <StyledListItemHeader>Company</StyledListItemHeader>
                <div>Name: {company?.name}</div>
                <div>FEIN #: {company?.federalId}</div>
            </div>
        </StyledListItem>
    );
};

export default CompanySearchResult;
