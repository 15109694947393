import styled from "styled-components";
import { GridToolbarContainer } from "@mui/x-data-grid";
import colors from "src/styles/colors.scss";

interface StyledJobListToolbarProps {
    $isDragActive?: boolean;
    $isSearchActive?: boolean;
    $isDisabled?: boolean;
}

export const StyledJobListToolbar = styled(GridToolbarContainer)<StyledJobListToolbarProps>`
    padding: 0 30px 6px 0;
    visibility: ${(props) => props.$isDragActive ? "hidden": "visible" };
    pointer-events: ${(props) => props.$isDisabled ? "none": "auto" };

    .MuiBox-root {
        width: 100%;
        height: 45px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
    }

    .MuiButton-root {
        color: ${(props) => props.$isDisabled ? colors.grey: colors.primaryColor };
        font-weight: 600;
        min-width: 32px; 
    }

    .MuiIconButton-root {
        color: ${(props) => props.$isDisabled ? colors.grey: colors.primaryColor };
    }

    .MuiButton-startIcon {
        margin-right: ${(props) => props.$isSearchActive ? "0px": "8px" };
    }

    .MuiBadge-badge {
        background-color: ${(props) => props.$isDisabled ? colors.grey: colors.primaryColor };
    }
`;
