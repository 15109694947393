import React from "react";
import { EmptyState, Undraw } from "src/uikit";
import { StyledCustomNoData } from "../DataTable.styled";

export const customNoData = () => {
    return (
        <StyledCustomNoData data-testid="custom-no-data-component">
            <EmptyState
                graphic={<Undraw fileName="NoData.svg" height={100} />}
                title="No Data Found"
                text="The table currently has no data to display. Please check back later for any new updates or additions."
                actions={[]}
            />
        </StyledCustomNoData>
    );
};
