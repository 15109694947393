// Important! this type should be the same as in stratus app
export enum ExtAction {
    // Actions sent from Stratus
    STRATUS_CREATE_TAB = "STRATUS_CREATE_TAB",
    STRATUS_FILL_DATA = "STRATUS_FILL_DATA",
    // Actions sent from Extension
    FILL_DATA = "FILL_DATA",
}

export enum ExtMessageResponseStatus {
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
    WARNING = "WARNING",
}

export type ExtMessage = {
    action: ExtAction;
    data?: any;
};

export type ExtMessageResponse<T = any> = {
    status: ExtMessageResponseStatus;
    message: string;
    data?: T;
};
export type InputData = {
    label?: string;
    name?: string;
    id?: string;
    value: string;
};

export type LoginData = {
    url: string;
    inputData: InputData[];
};