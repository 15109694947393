import { scheduleSBPIdLifePage2 } from "src/constants/Schedules";
import { Company, FolderNode, ReturnNode, ScheduleType } from "src/types";

export function isCompanyInformationFolderNode(
    folderNode: FolderNode
): boolean {
    return folderNode?.attributes?.displayName === "Company Information";
}

export function isSecondPageSBPReturnNode(returnNode: ReturnNode): boolean {
    return returnNode?.id === scheduleSBPIdLifePage2;
}

export function isReturnNodeActive(returnNode: ReturnNode): boolean {
    return returnNode?.isActive;
}

export function hasReturnNodeScheduleTypeReturn(
    returnNode: ReturnNode
): boolean {
    return returnNode?.scheduleType === ScheduleType.RETURN;
}

export function isDomicileFolderNode(folderNode: FolderNode, company: Company) {
    return folderNode?.id === company?.stateOfDomicile;
}

export function isRetalReturnOrRetalWorksheet(
    returnNode: ReturnNode,
    folderNode: FolderNode
): boolean {
    return (
        returnNode?.retalFolderId !== folderNode?.id ||
        returnNode?.retalFolderId === "108"
    );
}

export function isDomesticReturnNode(returnNode: ReturnNode): boolean {
    return returnNode?.isDomestic;
}

export function isForeignReturnNode(returnNode: ReturnNode): boolean {
    return returnNode?.isForeign;
}

/**
 * This function contain all the filters that need to be applied to the return nodes before rendering them.
 * @param folderNode
 * @param company
 * @returns boolean -- true if should be rendered, false if should not be rendered
 */
export function filterForReturnNodes(
    returnNode: ReturnNode,
    folderNode: FolderNode,
    company?: Company,
    includeInactive: boolean = false,
    includeRetal: boolean = false
): boolean {
    if (
        returnNode.id == "4" &&
        returnNode.scheduleType !== ScheduleType.RETURN
    ) {
        return true;
    }
    if (
        isCompanyInformationFolderNode(folderNode) &&
        isSecondPageSBPReturnNode(returnNode)
    ) {
        return false;
    }

    if (returnNode.scheduleType === ScheduleType.RETURN) {
        // Do not render inactive return nodes
        if (includeInactive || isReturnNodeActive(returnNode)) {
            if (
                includeRetal ||
                !isRetalReturnOrRetalWorksheet(returnNode, folderNode)
            ) {
                return company && isDomicileFolderNode(folderNode, company)
                    ? isDomesticReturnNode(returnNode) // Include only domestic returns if the folder node is the domicile folder node
                    : isForeignReturnNode(returnNode); // Include only foreign returns if the folder node is not the domicile folder node
            } else return false; // Exclude Retal return and Retal worksheet
        } else return false; // Exclude inactive return nodes
    } else return true; // Include all non-return nodes
}

/**
 * Apply filters to the folder nodes before rendering them.
 * @param folderNode
 * @returns boolean -- true if should be rendered, false if should not be rendered
 */
export function filterForFolderNodes(folderNode: FolderNode) {
    // Filter the folders that are not common
    return folderNode?.attributes?.displayName !== "Common";
}
