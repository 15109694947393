import { useEffect, useState } from "react";

export const useDebugModeDisplayHidden = () => {
    const [debugModeDisplayHidden, setDebugModeDisplayHidden] = useState(false);
  
    useEffect(() => {
      const handleDisplayHiddenGlobalChange = () => {
        setDebugModeDisplayHidden((window as any).TRITECH?.DEBUG?.DISPLAY_HIDDEN_FIELDS);
      };
  
      window.addEventListener('debugModeDisplayHiddenChange', handleDisplayHiddenGlobalChange);
  
      handleDisplayHiddenGlobalChange();
  
      return () => {
        window.removeEventListener('debugModeDisplayHiddenChange', handleDisplayHiddenGlobalChange);
      };
    }, []);
  
    return debugModeDisplayHidden;
  };