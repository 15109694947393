import { Table, TableBody } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import Loader from "src/components/Loader/Loader";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AccountService } from "src/services";
import { Company, FixMeLater } from "src/types";
import { FrameCard } from "src/uikit";
import {
    StyledNoDataFound,
    StyledTableCell,
    StyledTableRow,
} from "../../../../AccountAdmin.styled";
import AccountCompaniesModal from "../../modals/AccountCompaniesModal/AccountCompaniesModal";
import "./AccountCompanies.scss";

const AccountCompanies: FC = () => {
    const accountService = AccountService.getInstance();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const [accountCompaniesModalOpen, setAccountCompaniesModalOpen] =
        useState<boolean>(false);

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value,
    );
    const selectedAccount = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccount,
    );
    const selectedAccountCompany = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccountCompany,
    );
    const selectedAccountCompanies = useAppSelector(
        (state) =>
            state?.[product?.productName].value?.selectedAccountCompanies,
    );

    const dispatch = useAppDispatch();

    useEffect(() => {
        const fetchSelectedAccountCompanies = async () => {
            if (selectedAccount) {
                setIsLoading(true);
                try {
                    const selectedAccountCompaniesData: Company[] =
                        await accountService.getAccountCompanies(
                            selectedAccount?.id,
                        );

                    dispatch(
                        GlobalStateActions[
                            product?.productName
                        ].setSelectedAccountCompanies(
                            selectedAccountCompaniesData,
                        ),
                    );

                    dispatch(
                        GlobalStateActions[
                            product?.productName
                        ].setSelectedAccountCompany(
                            selectedAccountCompaniesData?.[0],
                        ),
                    );
                } catch (error) {
                    console.error(error);
                    setError(error as Error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchSelectedAccountCompanies();
    }, [selectedAccount]);

    const noDataFound = selectedAccountCompanies?.length === 0;

    if (error)
        return (
            <ErrorMessage
                error={`Error fetching account companies: ${error.toString()}`}
            />
        );

    if (!error && isLoading) return <Loader />;

    return (
        <>
            <div className="account-companies-container">
                <FrameCard
                    title="COMPANIES"
                    actions={[
                        {
                            text: "Add Company",
                            onClick: () => setAccountCompaniesModalOpen(true),
                        },
                    ]}
                >
                    {noDataFound ? (
                        <StyledNoDataFound>
                            No companies found for this account.
                        </StyledNoDataFound>
                    ) : (
                        <Table>
                            <TableBody>
                                {selectedAccountCompanies?.map((company) => (
                                    <StyledTableRow
                                        key={company.id}
                                        $isSelected={
                                            selectedAccountCompany?.id ==
                                            company?.id
                                        }
                                        onClick={() => {
                                            dispatch(
                                                GlobalStateActions[
                                                    product?.productName
                                                ].setSelectedAccountCompany(
                                                    company,
                                                ),
                                            );
                                        }}
                                    >
                                        <StyledTableCell>
                                            {company.name}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </FrameCard>
            </div>
            <AccountCompaniesModal
                open={accountCompaniesModalOpen}
                onClose={() => setAccountCompaniesModalOpen(false)}
            />
        </>
    );
};

export default AccountCompanies;
