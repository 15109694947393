import {
    Date,
    DateFormat,
    DecimalFormat,
    NumberTextFormat,
    PlainTextFormat,
} from "enterprise-formatting-lib";
import {
    SupportingWorksheetCell,
    SupportingWorksheetCellType,
} from "src/types";

type DayMonthYear = { day: number; month: number; year: number };

export function formatCell(cell: SupportingWorksheetCell) {
    const { cellType, formatType, formatString, override, defaultValue } = cell;

    const value = override || defaultValue;

    switch (cellType) {
        case SupportingWorksheetCellType.NumberField:
            return formatNumberField(value, formatType, 0);
        case SupportingWorksheetCellType.TextField:
            return formatTextField(formatType, formatString, value);
        case SupportingWorksheetCellType.LabelField:
            return String(cell.tag);
        default:
            return String(value);
    }
}

function formatTextField(
    formatType: string,
    formatString: string,
    value: string,
): string {
    switch (formatType) {
        case "FORMAT_NUMBER_TEXT":
            return new NumberTextFormat(formatString).formatNumberText(
                value === "" ? null : String(value),
            );
        case "FORMAT_TEXT":
            if (formatString !== "") {
                return new PlainTextFormat(formatString).formatPlainText(
                    String(value),
                );
            } else {
                return String(value);
            }
        case "FORMAT_DATE":
            return isDayMonthYear(value)
                ? new DateFormat(formatString).formatDate(
                      new Date(value.day, value.month, value.year),
                  )
                : "";
        default:
            return String(value);
    }
}

function formatNumberField(
    value: string,
    formatType: string,
    decimalPrecision: number,
    excludedChar: string[] = [],
    formatSpec: string[] = [],
) {
    switch (formatType) {
        case "FORMAT_DOUBLE":
            return properlyFormatDecimal(
                value,
                decimalPrecision,
                excludedChar,
                formatSpec,
            );
        case "FORMAT_PERCENT":
            return new DecimalFormat(
                decimalPrecision + 2,
                formatSpec,
                excludedChar,
            ).formatDecimal(value === "" ? null : Number(value));
        default:
            return String(value);
    }
}

function properlyFormatDecimal(
    value: string,
    decimalPrecision: number,
    excludedChar: string[] = [],
    formatSpec: string[] = [],
): string {
    const fieldString = value
        .valueOf()
        .toString()
        .replace(/[^0-9.-]/g, "");
    let evaluatedString = "";

    if (fieldString.charAt(0) === "-" && formatSpec.length >= 2) {
        evaluatedString = formatSpec[1];
    } else if (formatSpec.length >= 1) {
        evaluatedString = formatSpec[0];
    }

    const decimalIndex = evaluatedString.indexOf(".");

    // If a decimal exists, run sanitizeDecimalString with a count of '0' characters after the decimal
    if (decimalIndex >= 0) {
        decimalPrecision = evaluatedString
            .substring(decimalIndex)
            .replace(/[^0]/g, "").length;
    }

    return new DecimalFormat(
        decimalPrecision,
        formatSpec,
        excludedChar,
    ).formatDecimal(
        // empty string yields null behavior.
        // refer to enterprise-formatting-lib Kotlin Source
        value === "" ? null : Number(value),
    );
}

function isDayMonthYear(date: any): date is DayMonthYear {
    return date.day && date.month && date.year;
}
