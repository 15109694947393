import { FixMeLater, QueryParams } from "src/types";
import { BaseAPIService } from "./BaseApiService";

export class EncryptionService {
    private static _instance: EncryptionService;
    private readonly BASE_PATH: string = "/api/encryption";
    private api: BaseAPIService = BaseAPIService.getInstance();

    private constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    private getParamsString(params: QueryParams): string {
        return new URLSearchParams(params).toString();
    }

    async decrypt(params: QueryParams): Promise<{decryptedText: string}> {
        const response = await this.api.get<{decryptedText: string}>(
            this.getPath(`/decrypt?${this.getParamsString(params)}`)
        );
        return response;
    }

    public static getInstance(): EncryptionService {
        if (!EncryptionService._instance) {
            EncryptionService._instance = new EncryptionService();
        }
        return EncryptionService._instance;
    }
}