import styled from 'styled-components';

interface IconProps {
    backgroundColor: string;
}

export const MainIcon = styled.div<IconProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: ${(props) => props.backgroundColor};
`;

export const SubIcon = styled.div<IconProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    background-color: ${(props) => props.backgroundColor};
    position: absolute;
    right: 0px;
    bottom: 0px;
    border-radius: 2px;
`;

export const SupIcon = styled.div<IconProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    background-color: ${(props) => props.backgroundColor};
    position: absolute;
    right: -4px;
    top: -4px;
    border-radius: 2px;
`;