import React, { useEffect, useRef, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import { getOverlayFields } from "src/components/PdfViewer/PdfViewer.util.overlayFields";
import { DEFAULT_ZOOM_SCALE } from "src/constants";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { useDebugModeDisplayHidden } from "src/hooks/debugModeDisplayHidden";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { GraphqlService, ReturnDocumentService } from "src/services";
import { returnTemplateQuery } from "src/services/GQLQueries";
import { getModuleId } from "src/services/Utility";
import {
    CheckField,
    Company,
    FixMeLater,
    FolderNode,
    NumField,
    Product,
    PushReturnDocument,
    QueryParams,
    ReturnDocument,
    ReturnDocumentNoteState,
    ReturnNode,
    ReturnPage,
    ReturnTemplate,
    State,
    TextField,
    UserOptions,
    ValidationResponse,
} from "src/types";
import { generateUniqueId, getQueryParams } from "src/utils";
import { useDebouncedCallback } from "use-debounce";
import CustomSnackbar from "../../CustomSnackbar/CustomSnackbar";
import {
    fetchDocument,
    fetchOnlyDocument,
    fetchPdf,
    handleResponse,
} from "../PdfViewer.util";
import { PdfDocument } from "../PdfDocument/PdfDocument";
import { StyledPdfRender } from "./PdfRender.styled";

export interface PdfRenderProps {
    documentKey: string;
    returnDocument: ReturnDocument | undefined;
    setReturnDocument: React.Dispatch<
        React.SetStateAction<ReturnDocument | undefined>
    >;
    returnTemplate: ReturnTemplate | undefined;
    setReturnTemplate: React.Dispatch<
        React.SetStateAction<ReturnTemplate | undefined>
    >;
    setValidationResponse: React.Dispatch<
        React.SetStateAction<ValidationResponse | null>
    >;
    setPendingChanges: React.Dispatch<React.SetStateAction<boolean>>;
    isPaymentRequest?: boolean;
    product: Product;
    company: Company;
    folderNode: FolderNode;
    returnNode: ReturnNode;
    isActive: boolean;
    isSplitedScreen: boolean;
    onClickHyperlinkCallback?: (hyperlink: string) => void;
    triggerCount?: number;
    onVisiblePageCb: (page: ReturnPage) => void;
    selectedReturnPage?: ReturnPage;
}

const PdfRender: React.FC<PdfRenderProps> = ({
    documentKey,
    returnDocument,
    setReturnDocument,
    returnTemplate,
    setReturnTemplate,
    setValidationResponse,
    setPendingChanges,
    isPaymentRequest = false,
    product,
    company,
    folderNode,
    returnNode,
    isActive,
    isSplitedScreen,
    onClickHyperlinkCallback,
    triggerCount,
    onVisiblePageCb,
    selectedReturnPage,
}) => {
    if (!product) return null;

    /* ===== SERVICES ===== */

    const returnDocumentService = ReturnDocumentService.getInstance();
    const graphqlService = GraphqlService.getInstance();

    /* ===== STATES ===== */

    // To force a re-render after queue updates
    const [, forceUpdate] = useState(0);
    const [error, setError] = useState<FixMeLater>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("error");
    const [pagesData, setPagesData] = useState<
        {
            page: ReturnPage;
            pageUniqueId: string;
            overlayFields?: React.ReactNode[];
        }[]
    >([]);
    const [pdfFile, setPdfFile] = useState<Blob | null>(null);
    const [pageLoaded, setPageLoaded] = useState(false);
    const userOptions: UserOptions | undefined = useAppSelector(
        (state) => state.UserOptions.value
    );

    /* ===== REDUX ===== */
    const dispatch = useAppDispatch();

    const debugModeDisplayHidden = useDebugModeDisplayHidden();

    const dropdownStateView: boolean = useAppSelector((state) => {
        return state?.UserOptions?.value?.productPreferences[
            product.productName
        ]?.dropdownStateView;
    });

    const selectedState: State = useAppSelector(
        (state) => state?.[product?.productName]?.value?.state
    );

    const tabsProperties = useAppSelector(
        (state) => state?.Tabs?.tabsProperties
    );

    const splitScreen = useAppSelector((state) => state?.Tabs?.splitScreen);

    const isSynchronizedScrollEnabled = useAppSelector(
        (state) =>
            state?.[product?.productName]?.value?.synchronizedScroll?.isEnabled
    );

    const isSynchronizedScroll = useAppSelector(
        (state) =>
            state?.[product?.productName]?.value?.synchronizedScroll
                ?.isSynchronized
    );

    const synchronizedScrollPosition = useAppSelector(
        (state) =>
            state?.[product?.productName]?.value?.synchronizedScroll
                ?.scrollPosition
    );

    const toogleFetch = useAppSelector((state) => state?.Tabs?.toogleFetch);

    const municipalState: string = useAppSelector(
        (state) => state?.Municipal?.value?.selectedState?.abbrev
    );

    /* ===== REFS ===== */

    // Queue refs to handle changes synchronously
    const inputQueueRef = useRef<
        {
            fieldType: string;
            documentNode: NumField | TextField | CheckField;
            returnPage: ReturnPage;
            overrideValue: string | number | boolean;
        }[]
    >([]);

    const pendingQueueRef = useRef<
        {
            fieldType: string;
            documentNode: NumField | TextField | CheckField;
            returnPage: ReturnPage;
            overrideValue: string | number | boolean;
        }[]
    >([]);

    // Ref for synchronous isPushing tracking
    const isPushingRef = useRef(false);

    // Ref for the pdf viewer container
    const pdfViewerRef = useRef<HTMLDivElement>(null);

    /* ===== CONSTANTS & VARIABLES ===== */

    const scale = Number(
        userOptions.globalPreferences?.zoom || DEFAULT_ZOOM_SCALE
    );

    const returnDocumentId = {
        companyId: dropdownStateView ? folderNode?.id?.toString() : company.id,
        productId: product.productId.toString(),
        taxYearId: product.taxYear.toString(),
        folderId: dropdownStateView
            ? selectedState?.id.toString()
            : folderNode?.id.toString(),
        moduleId: getModuleId(
            product,
            company ? (dropdownStateView ? folderNode : company) : {},
            municipalState
        ),
        returnId: returnNode.id.toString(),
        retalFolderId: returnNode.retalFolderId?.toString()!!,
        paymentRequestFormId: isPaymentRequest
            ? "1171"
            : returnNode?.id.toString() ?? null,
    };

    const requestFetchReturnDocument: QueryParams = getQueryParams({
        ...returnDocumentId,
        taxYear: returnDocumentId.taxYearId,
    });

    const requestFetchPdf: QueryParams = getQueryParams({
        ...returnDocumentId,
        returnId: isPaymentRequest ? "1171" : returnDocumentId.returnId,
        retalFolderId: isPaymentRequest
            ? "108"
            : returnDocumentId.retalFolderId,
    });

    const isManualSave =
        returnTemplate?.properties?.find(
            (property) => property?.key === "SaveType"
        )?.value === "MANUAL" || false;

    const visiblePages =
        returnTemplate?.returnPages?.filter((page) => {
            // Check if it's the Payment Voucher page
            const isPaymentVoucherPage =
                page.attributes.caption === "Payment Voucher";
            // Conditionally filter out the Payment Voucher page
            if (
                isPaymentVoucherPage &&
                folderNode?.id !== returnNode?.retalFolderId
            ) {
                page.attributes.isVisible = false;
                return true;
            }

            return page.attributes.isVisible || isPaymentRequest;
        }) || [];

    /* ===== EFFECTS ===== */

    useEffect(() => {
        setIsLoading(true);
        fetchPdf(requestFetchPdf, setPdfFile);
        fetchReturnTemplate(returnDocumentId, setReturnTemplate);
    }, []);

    useEffect(() => {
        if (isActive && isPaymentRequest) {
            fetchOnlyDocument(requestFetchReturnDocument, setReturnDocument);
        }
    }, [isActive, tabsProperties[documentKey]?.isLocked]);

    useEffect(() => {
        if (pdfFile && returnDocument && returnTemplate) {
            setIsLoading(false);
        }
    }, [pdfFile, returnDocument, returnTemplate]);

    useEffect(() => {
        if (returnTemplate) {
            setPagesData(getAllOverlayFields());
        }
    }, [scale, returnDocument, returnTemplate, debugModeDisplayHidden]);

    useEffect(() => {
        if (triggerCount !== undefined && triggerCount > 0) {
            pushDocument({ ...returnDocument });
        }
    }, [triggerCount]);

    useEffect(() => {
        // If another active return or schedule is updated, fetch the document to reflect the changes if there are any
        if (toogleFetch && isActive) {
            fetchDocument(
                requestFetchReturnDocument,
                setReturnDocument,
                setValidationResponse
            );
            dispatch(GlobalStateActions.setToogleFetch());
        }
    }, [toogleFetch]);

    useEffect(() => {
        if (isSynchronizedScroll && isSynchronizedScrollEnabled) {
            pdfViewerRef.current?.scroll({
                top: synchronizedScrollPosition,
            });
        }
    }, [synchronizedScrollPosition]);

    useEffect(() => {
        const el = document.getElementById(
            generateUniqueId(
                product,
                company,
                returnNode,
                selectedReturnPage,
                undefined,
                undefined,
                folderNode?.id.toString(),
                `${isSplitedScreen ? "SS" : ""}`
            )
        );
        if (el) {
            pdfViewerRef.current?.scroll({
                top: el.offsetTop,
            });
        }
    }, [selectedReturnPage]);

    /* ===== FUNCTIONS ===== */

    const handleSnackbar = (message: string, severity: string) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const getAllOverlayFields = (): Array<{
        page: ReturnPage;
        pageUniqueId: string;
        overlayFields: React.ReactNode[];
    }> => {
        if (returnTemplate?.returnPages) {
            return returnTemplate?.returnPages
                .sort((a, b) => {
                    return a.attributes.pageOrder - b.attributes.pageOrder;
                })
                .map((returnPage) => {
                    return {
                        page: returnPage,
                        pageUniqueId: generateUniqueId(
                            product,
                            company,
                            returnNode,
                            returnPage,
                            undefined,
                            undefined,
                            folderNode?.id.toString(),
                            `${isSplitedScreen ? "SS" : ""}`
                        ),
                        overlayFields: getOverlayFields(
                            returnTemplate?.fieldTemplates,
                            returnPage,
                            scale,
                            returnDocument,
                            updateReturnDocument,
                            isPaymentRequest,
                            company,
                            product,
                            returnNode,
                            requestFetchReturnDocument,
                            onClickHyperlinkCallback ?? (() => {}),
                            debugModeDisplayHidden
                        ),
                    };
                });
        } else {
            return [];
        }
    };

    const fetchReturnTemplate = async (params, setReturnTemplate) => {
        const returnInput = { ...params };
        try {
            const fetchedReturnTemplate = await graphqlService.fetchData<{
                returnTemplate: ReturnTemplate;
            }>(returnTemplateQuery, { returnInput });

            setReturnTemplate(fetchedReturnTemplate?.returnTemplate);
        } catch (error) {
            console.error("Error fetching return template:", error);
        }
    };

    const pushDocument = async (
        updatedReturnDocument,
        showMessages: boolean = true
    ) => {
        updatedReturnDocument.returnVersion++;

        // Push the updated return document to the service
        try {
            const response: PushReturnDocument =
                await returnDocumentService.pushReturnDocument(
                    updatedReturnDocument
                );
            // Update the local state based on the response
            const updatedDocument = handleResponse(
                updatedReturnDocument,
                response
            );

            setValidationResponse(response.validationResponse);
            setReturnDocument(updatedDocument);

            // To update the other open return document or schedule if split screen is enabled
            // set the return or schedule just got updated to true
            // so that the other return document will be re-rendered and set it to false again
            if (splitScreen) {
                dispatch(GlobalStateActions.setToogleFetch());
            }

            // Display success message
            if (showMessages) {
                handleSnackbar("saved", "success");
            }
        } catch (error: FixMeLater) {
            const message = JSON.parse(error?.body)?.message;

            // Handle and display error message
            if (message === "Attempted to push when return is out of date") {
                handleSnackbar(
                    `Not saved.\nThe return has been updated outside of this session. please try again Error: ${error.message}`,
                    "error"
                );
                setIsLoading(true);
                await fetchDocument(
                    requestFetchReturnDocument,
                    setReturnDocument,
                    setValidationResponse
                );
                setIsLoading(false);
            } else if (
                message === "Attempted to update return that was already locked"
            ) {
                handleSnackbar(message, "error");

                setIsLoading(true);
                await fetchDocument(
                    requestFetchReturnDocument,
                    setReturnDocument,
                    setValidationResponse
                );
                setIsLoading(false);
            } else {
                // For other errors
                handleSnackbar(
                    `There has been an error saving data. ${error}`,
                    "error"
                );
            }
        }
    };

    // Debounced API call
    const debouncedPushDocument = useDebouncedCallback(() => {
        if (inputQueueRef.current.length === 0 || isPushingRef.current) return;

        const updatedReturnDocument = { ...returnDocument };
        inputQueueRef.current.forEach(
            ({ fieldType, documentNode, returnPage, overrideValue }) => {
                const returnDocumentPage = updatedReturnDocument?.pages?.find(
                    (x) => x.pageOrder === returnPage?.attributes.pageOrder
                );
                if (returnDocumentPage) {
                    const updatedReturnDocumentPage = {
                        ...returnDocumentPage,
                        [fieldType]: returnDocumentPage[fieldType].map((x) =>
                            x.id === documentNode.id
                                ? { ...x, overrideValue: overrideValue }
                                : x
                        ),
                    };

                    updatedReturnDocument.pages =
                        updatedReturnDocument?.pages?.map((page) =>
                            page.pageOrder === returnPage?.attributes.pageOrder
                                ? updatedReturnDocumentPage
                                : page
                        );
                }
            }
        );

        if (isManualSave) {
            setPendingChanges(true);
            dispatch(GlobalStateActions.setPendingChange(documentKey));
            setReturnDocument(updatedReturnDocument);
        } else {
            // Call the pushDocument function after accumulating changes
            isPushingRef.current = true; // Update the ref instead of state
            pushDocument(updatedReturnDocument).then(() => {
                isPushingRef.current = false; // Update the ref after push completion

                // Move items from pendingQueueRef to inputQueueRef
                if (pendingQueueRef.current.length > 0) {
                    inputQueueRef.current = [...pendingQueueRef.current];
                    pendingQueueRef.current = [];
                    forceUpdate((n) => n + 1); // Force re-render to ensure the latest state is reflected
                    debouncedPushDocument(); // Trigger the debounced API call again for the new inputs
                } else {
                    inputQueueRef.current = [];
                    forceUpdate((n) => n + 1); // Force re-render after clearing queue
                }
            });
        }
    }, 500);

    // Modify the updateReturnDocument function to accumulate changes and trigger debounced call
    function updateReturnDocument(
        fieldType: string,
        documentNode: NumField | TextField | CheckField,
        returnPage: ReturnPage,
        overrideValue: string | number | boolean
    ): void {
        if (isPushingRef.current) {
            // If an API call is pending, add new inputs to the pendingQueueRef
            pendingQueueRef.current = [
                ...pendingQueueRef.current,
                { fieldType, documentNode, returnPage, overrideValue },
            ];
        } else {
            // Otherwise, add to the main inputQueueRef
            inputQueueRef.current = [
                ...inputQueueRef.current,
                { fieldType, documentNode, returnPage, overrideValue },
            ];
            debouncedPushDocument(); // Trigger the debounced function
        }

        // Force an update to ensure component re-renders and reflects the state changes
        forceUpdate((n) => n + 1);
    }

    const handleSynchronizedScrollPositionUpdate = (scrollPos) => {
        dispatch(
            GlobalStateActions[
                product?.productName
            ]?.setSynchronizedScrollPosition(scrollPos)
        );
    };

    const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        if (isSynchronizedScrollEnabled && isSynchronizedScroll) {
            handleSynchronizedScrollPositionUpdate(e.currentTarget.scrollTop);
        } else {
            pdfViewerRef.current?.scroll({
                top: e.currentTarget.scrollTop,
            });
        }
    };

    if (error)
        return (
            <ErrorMessage
                error={`Error fetching the pdf: ${error.toString()}`}
            />
        );

    return (
        <StyledPdfRender
            data-testid="pdf-render"
            ref={pdfViewerRef}
            onScroll={handleScroll}
        >
            {isLoading && (
                <Backdrop
                    sx={{ zIndex: 19, position: "absolute" }}
                    open={isLoading}
                >
                    <CircularProgress sx={{ color: "white" }} />
                </Backdrop>
            )}
            <PdfDocument
                file={pdfFile}
                data={pagesData.filter((data) =>
                    visiblePages.some(
                        (page) =>
                            page.attributes.pageOrder ===
                            data.page.attributes.pageOrder
                    )
                )}
                scale={scale}
                onLoadSuccess={() => setPageLoaded(true)}
                onPageVisible={onVisiblePageCb}
            />

            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </StyledPdfRender>
    );
};

export default PdfRender;
