import { FixMeLater } from "src/types";

export const handlePrintExport = (apiRef, documentKey, handleSnackbar) => {
    // Access the columns and rows from the DataGrid
    const columns = apiRef.current
        .getAllColumns()
        .filter(
            (col) =>
                col.field !== "__check__" && col.field !== "__detail_panel__",
        ); // Filter out non-data columns
    const rows = apiRef.current.getRowModels();

    // Define max rows and columns per page
    const maxColumnsPerPage = 6; // Adjust this number based on your desired columns per page
    const maxRowsPerPage = 32; // Adjust this number based on your desired rows per page

    // Calculate the number of row sets and pages per row set
    const totalRowSets = Math.ceil(rows.size / maxRowsPerPage);
    const totalPagesPerRowSet = Math.ceil(columns.length / maxColumnsPerPage);

    // Create the HTML for the table, split into multiple pages if necessary
    let tableHtml = `
        <html>
        <head>
            <title>${documentKey}</title>
            <style>
                /* Add custom styles here */
                body { font-family: Arial, sans-serif; padding: 32px }
                table { width: 100%; border-collapse: collapse; }
                th, td { border: 1px solid #ddd; padding: 8px; }
                td { text-align: left; }
                th { background-color: #f2f2f2; text-align: left; }
                tr { height: 36px; }
                @media print {
                    table { page-break-inside: avoid; }
                }
            </style>
        </head>
        <body>
    `;

    for (let rowSetIndex = 0; rowSetIndex < totalRowSets; rowSetIndex++) {
        const startRow = rowSetIndex * maxRowsPerPage;
        const endRow = startRow + maxRowsPerPage;
        const rowsForPage: FixMeLater = Array.from(rows.entries()).slice(
            startRow,
            endRow,
        );

        for (let pageIndex = 0; pageIndex < totalPagesPerRowSet; pageIndex++) {
            const startCol = pageIndex * maxColumnsPerPage;
            const endCol = startCol + maxColumnsPerPage;
            const columnsForPage = columns.slice(startCol, endCol);

            tableHtml += `
                <table>
                    <thead>
                        <tr>
                            ${columnsForPage.map((col) => `<th>${col.headerName}</th>`).join("")}
                        </tr>
                    </thead>
                    <tbody>
                        ${rowsForPage
                            .map(
                                ([id, row]) => `
                                <tr>
                                    ${columnsForPage.map((col) => `<td>${row[col.field]}</td>`).join("")}
                                </tr>
                            `,
                            )
                            .join("")}
                    </tbody>
                </table>
            `;

            // Add a page break only if it's not the last page
            if (
                pageIndex < totalPagesPerRowSet - 1 ||
                rowSetIndex < totalRowSets - 1
            ) {
                tableHtml += '<div style="page-break-after:always;"></div>';
            }
        }
    }

    tableHtml += `
        </body>
        </html>
    `;

    // Open a new window and write the table HTML
    const newWindow = window.open(
        "Exported From Stratus",
        "_blank",
        "width=800,height=600",
    );

    if (!newWindow) {
        handleSnackbar(
            "Please enable pop-ups for this site to print.",
            "error",
        );
        return;
    }

    newWindow.document.write(tableHtml);
    newWindow.document.title = `${documentKey}.pdf`;
    newWindow.document.close();

    // Trigger the print dialog
    newWindow.focus();
    newWindow.print();
    newWindow.close();
};
