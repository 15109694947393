import React, { FC, useEffect, useState } from "react";
import "./CompanyOptions.scss";
import { useDispatch } from "react-redux";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { Company, FixMeLater, Product } from "src/types";
import { useAppSelector } from "src/hooks";
import { Box, IconButton, Typography, Tabs, Tab, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MunicipalCompanyOptions from "./ProductCompanyOptions/MunicipalCompanyOptions";
import FormsPlusCompanyOptions from "./ProductCompanyOptions/FormsPlusCompanyOptions";
import PremiumCompanyOptions from "./ProductCompanyOptions/PremiumCompanyOptions";
import JurisdictionOptions from "./JurisdictionOptions";
import { CompanyOptionsService } from "src/services/CompanyOptionsService";
import { CompanyOptionsEntity } from "src/types/CompanyOptionsService.types";
import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";
import Loader from "src/components/Loader/Loader";
import { User } from "@auth0/auth0-react";

const CompanyOptions: FC = () => {
    const dispatch = useDispatch();
    const self: User | undefined = useAppSelector(
        (state) => state?.Self
    )?.value;

    const product: Product | undefined = useAppSelector(
        (state) => state?.Product?.value
    );

    if (!product) return null;

    const company: Company | null = useAppSelector(
        (state) => state[product?.productName]?.value?.company
    );
    if (!company) return null;

    // Create an instance of the CompanyOptionsService
    const companyOptionsService = CompanyOptionsService.getInstance();

    const [formData, setFormData] = useState<CompanyOptionsEntity>({
        companyId: company.id,
        jsonOptions: {
            premium: {
                qualifiedForRHOInCO: false,
                calcNJPremiumsAccordingNJSA: false,
            },
            formsplus: {
                someFormsPlusOption: false,
            },
            municipal: {
                assessTaxOnPolicyholder: false,
                assessCollectionFee: false,
                reportCityPremiums: false,
                generateMinimumTax: false,
                payTaxWithReconciliation: false,
                thresholdAmount: "0.0",
            },
        },
    });

    const [initialFormData, setInitialFormData] =
        useState<CompanyOptionsEntity>(formData);
    const [activeTab, setActiveTab] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("error");

    const handleSnackbar = (message: string, severity: string) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    if (!product) return <div>Please select a product to continue!</div>;

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const handleCloseCompanyOptions = () => {
        if (
            hasChanges() &&
            !window.confirm(
                "You have unsaved changes. Do you really want to leave?"
            )
        ) {
            return;
        } else {
            dispatch(
                GlobalStateActions[product?.productName]?.setCompanyOptionsOpen(
                    false
                )
            );
        }
    };

    // Fetch company options on component mount
    useEffect(() => {
        const fetchCompanyOptions = async () => {
            try {
                const options = await companyOptionsService.getCompanyOptions(
                    company.id
                );
                if (options && Object.keys(options).length > 0) {
                    setFormData(options);
                    setInitialFormData(options);
                }
            } catch (error) {
                handleSnackbar("Failed to fetch company options", "error");
            } finally {
                setIsLoading(false);
            }
        };

        fetchCompanyOptions();
    }, []);

    // Function to save company options
    const handleSave = async () => {
        try {
            await companyOptionsService.setCompanyOptions(formData);
            handleSnackbar("Company options saved successfully", "success");
            setInitialFormData(formData);

            dispatch(GlobalStateActions.setCompanyOptionChanges(false));
        } catch (error) {
            handleSnackbar(
                `Failed to update company options: ${error}`,
                "error"
            );
        }
    };

    const handleFormDataChange = (newData: FixMeLater) => {
        setFormData((prevData) => ({
            ...prevData,
            jsonOptions: {
                ...prevData.jsonOptions,
                ...newData,
            },
        }));
    };

    // Function to check if form data has changed
    const hasChanges = () => {
        const hasChanges =
            JSON.stringify(formData) !== JSON.stringify(initialFormData);
        return hasChanges;
    };

    useEffect(() => {
        dispatch(GlobalStateActions.setCompanyOptionChanges(hasChanges()));
    }, [formData]);
    return (
        <div className="company-options-container">
            <IconButton
                className="close-button"
                onClick={handleCloseCompanyOptions}
            >
                <CloseIcon />
            </IconButton>
            <Typography variant="h6" className="header">
                {product?.productName} Options
            </Typography>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className="tabs-section">
                        <Tabs
                            value={activeTab}
                            onChange={handleTabChange}
                            aria-label="company options tabs"
                        >
                            <Tab label="Company" />
                            {/* this is to hide Jurisdiction tab on 11/07/2024 */}
                            {/*<Tab label="Jurisdictions" />*/}
                        </Tabs>
                    </div>
                    <Box className="toggle-section">
                        {activeTab === 0 && (
                            <>
                                {product?.productName === "Municipal" && (
                                    <MunicipalCompanyOptions
                                        formData={formData}
                                        onFormDataChange={handleFormDataChange}
                                    />
                                )}
                                {product?.productName === "FormsPlus" && (
                                    <FormsPlusCompanyOptions
                                        formData={formData}
                                        onFormDataChange={handleFormDataChange}
                                    />
                                )}
                                {product?.productName === "Premium" && (
                                    <PremiumCompanyOptions
                                        formData={formData}
                                        onFormDataChange={handleFormDataChange}
                                    />
                                )}
                            </>
                        )}
                        {activeTab === 1 && (
                            <JurisdictionOptions
                                formData={formData}
                                onFormDataChange={handleFormDataChange}
                            />
                        )}
                    </Box>
                    <div className="save-button-container">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                            disabled={!hasChanges}
                        >
                            Save
                        </Button>
                    </div>
                </>
            )}
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </div>
    );
};

export default CompanyOptions;
