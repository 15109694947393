import styled from "styled-components";

export const StyledPdfRender = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    align-items: center;
    width: 100%;

    &::-webkit-scrollbar-track {
        background: transparent;
        border: 1px solid #e0e0e0;
    }

    &::-webkit-scrollbar-thumb-vertical {
        width: 12px;
        height: 12px !important;
    }

`;
