import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App.tsx";
import reportWebVitals from "./reportWebVitals.ts";
import store from "../src/redux/store.ts";
import { Provider } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";
import { ThemeProvider } from "styled-components";
import { THEME } from "./constants/Theme.ts";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        cacheLocation="localstorage"
        authorizationParams={{
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
        }}
    >
        <Provider store={store}>
            <ThemeProvider theme={THEME}>
                <App />
            </ThemeProvider>
        </Provider>
    </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
