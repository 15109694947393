import exp from "constants";
import styled from "styled-components";

type SidebarContainerProps = {
    toolbarWidth: number;
    width: number;
    open?: boolean;
};

type SidebarWrapperProps = {
    width: number;
};

export const SidebarContainer = styled.div<SidebarContainerProps>`
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: ${(props) => {
        if (props.open) {
            return 0;
        } else {
            return -(props.width+5);
        }

    }}px;
    background: transparent;
    z-index: 19;
    height: 100%;
    transition: right 0.3s;
`;

export const SidebarToolbar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
`;

export const SidebarWrapper = styled.div<SidebarWrapperProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: ${(props) =>  props.width}px;
    border-left: 1px solid #354560;
`;

export const SidebarContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% - 60px);
    width: 100%;
    background-color: white;
    padding-right: 60px;
    /* overflow: scroll; */
`;

export const SidebarHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    width: 100%;
    padding: 8px 16px;
    background-color: #4d648a;
    color: white;
    * {
        fill: white;
    }
`;

export const SidebarTitle = styled.h3`
    margin-left: 8px;
    user-select: none;
`;

export const SidebarResizeHandle = styled.div`
    position: relative;
    left: 0;
    top: 0;
    width: 2px;
    height: 100%;
    cursor: col-resize;
    background: darkgray;
`;