import React, { FC, useState, useRef } from "react";
import { Tooltip } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { IconButtonStyled } from "./FloatingWindow.styled";
import "./FloatingWindow.scss";

interface FloatingWindowProps {
    children: React.ReactNode;
    visible?: boolean;
    setVisible: (visible: boolean) => void;
    onClose?: () => void;
    title: string | undefined;
}

const FloatingWindow: FC<FloatingWindowProps> = ({
    children,
    visible = true,
    setVisible,
    title,
    onClose,
}) => {
    const [isDragging, setIsDragging] = useState(false);
    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);

    const windowRef = useRef<HTMLDivElement>(null);

    const handleMouseDown = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        ref: HTMLDivElement | null,
    ) => {
        setIsDragging(true);
        if (ref) {
            setOffsetX(e.clientX - ref.getBoundingClientRect().left);
            setOffsetY(e.clientY - ref.getBoundingClientRect().top);
        }
        e.stopPropagation();
    };

    const handleMouseMove = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        ref: HTMLDivElement | null,
    ) => {
        if (isDragging && ref) {
            ref.style.left = e.clientX - offsetX + "px";
            ref.style.top = e.clientY - offsetY + "px";
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };
    return (
        <>
            {visible && (
                <div
                    ref={windowRef}
                    className={`floating-window ${visible ? "visible" : ""}`}
                >
                    <div
                        onMouseDown={(
                            e: React.MouseEvent<HTMLDivElement, MouseEvent>,
                        ) => handleMouseDown(e, windowRef?.current)}
                        onMouseMove={(
                            e: React.MouseEvent<HTMLDivElement, MouseEvent>,
                        ) => handleMouseMove(e, windowRef?.current)}
                        onMouseUp={handleMouseUp}
                        onMouseLeave={handleMouseUp}
                        className="header"
                    >
                        <div className="title-container">
                            <div className="window-title">{title}</div>
                        </div>
                        <IconButtonStyled
                            onClick={() => {
                                setVisible(false);
                                if (onClose) onClose();
                            }}
                            data-testid="close-button"
                        >
                            <Tooltip title="Close Window" placement="top">
                                <CloseIcon />
                            </Tooltip>
                        </IconButtonStyled>
                    </div>
                    <div className="window-content">{children}</div>
                </div>
            )}
        </>
    );
};

export default FloatingWindow;
