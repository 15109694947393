import React from "react";
import { Document, Page } from "react-pdf";
import { ReturnPage } from "src/types";
import { PageWrapper } from "./PageWrapper";
import { StyledDocument, StyledPage } from "./PdfDocument.styled";

type PdfDocumentProps = {
    file: Blob | null;
    onLoadSuccess: () => void;
    onPageVisible: (page: ReturnPage) => void;
    data: {
        page: ReturnPage;
        pageUniqueId: string;
        overlayFields?: React.ReactNode[];
    }[];
    scale: number;
};

export const PdfDocument: React.FC<PdfDocumentProps> = ({
    file,
    data,
    scale,
    onLoadSuccess,
    onPageVisible,
}) => {
    const LOADING_MESSAGE = "Loading...";
    let [documentLoaded, setDocumentLoaded] = React.useState(false);

    return (
        <StyledDocument
            file={file}
            loading={LOADING_MESSAGE}
            onSourceSuccess={() => {
                setDocumentLoaded(true);
                onLoadSuccess;
            }}
        >
            {data.map((data) => (
                <PageWrapper
                    page={data.page}
                    onVisible={onPageVisible}
                    pageLoaded={documentLoaded}
                    key={data.pageUniqueId}
                    id={data.pageUniqueId}
                >
                    <StyledPage
                        renderAnnotationLayer={false}
                        className="pdf-viewer-page"
                        pageNumber={data.page.attributes.pageOrder + 1}
                        scale={scale}
                    >
                        {data.overlayFields}
                    </StyledPage>
                </PageWrapper>
            ))}
        </StyledDocument>
    );
};
