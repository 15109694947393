import NotesIcon from "@mui/icons-material/Notes";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { IconButton, Tooltip } from "@mui/material";
import React, {
    CSSProperties,
    ChangeEvent,
    useEffect,
    useRef,
    useState,
} from "react";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import {
    CheckField,
    FixMeLater,
    NoteFieldType,
    NumField,
    ReturnDocument,
    ReturnDocumentNoteState,
    ReturnPage,
    TextField,
} from "src/types";
import DynamicColors from "src/utils/DynamicColors";
import Formatter, { removeCharacters } from "src/utils/Formatter";
import SpacedText from "../SpacedText/SpacedText";
import "./NumberField.scss";
import FieldToolbar from "../FieldToolbar/FieldToolbar";
import { useDebugMode } from "src/hooks/debugMode";
import { useDebugModeDisplayHidden } from "../../../hooks/debugModeDisplayHidden";

// Define types for props
interface NumberFieldProps {
    scale: number;
    templateField?: FixMeLater;
    value: {
        value: string | number | boolean;
        isOverride: boolean;
        documentField: NumField | TextField | CheckField;
    };
    updateReturnDocument: (
        fieldType: string,
        documentNode: NumField | TextField | CheckField,
        page: ReturnPage,
        overrideValue: string | number | boolean,
    ) => void;
    isLocked?: boolean;
    isPaymentRequest?: boolean;
    returnPage: ReturnPage;
    returnDocument: ReturnDocument;
    id: string;
    onClickHyperlinkCallback: (hyperlink: string) => void;
}

const NumberField: React.FC<NumberFieldProps> = ({
    scale,
    templateField,
    value,
    updateReturnDocument,
    isLocked,
    isPaymentRequest,
    returnPage,
    returnDocument,
    id,
    onClickHyperlinkCallback,
}) => {
    const { position, size } = templateField?.screenBox || {};
    const {
        overrideState,
        style,
        maxLength,
        isEnabled,
        toolTip,
        tabIndex,
        spacingArray,
        excludedChar,
        fieldType,
        hyperlink,
    } = templateField || {};

    const pageTabIndex = returnPage.attributes.displayOrder * 100 + tabIndex;

    const [showSpacedText, setShowSpacedText] = useState<boolean>(
        !!(spacingArray && spacingArray.trim() !== ""),
    );

    const [formattedInputValue, setFormattedInputValue] = useState<string>(
        Formatter.format(templateField, value?.value.toString()),
    );

    const [notFormattedInputValue, setNotFormattedInputValue] =
        useState<string>(value?.value.toString());

    // State for input value and spaced text visibility
    const [inputValue, setInputValue] = useState<string>(
        Formatter.format(templateField, value?.value.toString()),
    );

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value,
    );

    const returnNotes: ReturnDocumentNoteState = useAppSelector(
        (state) => state?.[product?.productName]?.value?.returnNotes,
    );

    const [toolbarVisible, setToolbarVisible] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const debugMode = useDebugMode();
    const debugModeDisplayHidden = useDebugModeDisplayHidden();

    useEffect(() => {
        // Update inputValue when value.value changes
        setAllInputValues(value?.value.toString());
        setInputValue(Formatter.format(templateField, value?.value.toString()));
    }, [value.value]);

    // Ref for input element
    const inputRef = useRef<HTMLInputElement | null>(null);

    const isFocusedFieldNote = id === returnNotes?.focusedFieldNoteId;

    useEffect(() => {
        if (isFocusedFieldNote && !isPaymentRequest) {
            inputRef?.current?.focus();
        }
    }, [
        returnNotes?.focusedFieldNoteId,
        returnNotes?.focusedFieldNoteType,
        id,
    ]);

    const hasSpacingArray = !!(spacingArray && spacingArray.trim() !== "");

    const dynamicTextFieldColor = DynamicColors.calculateDynamicTextFieldColor(
        isEnabled,
        overrideState,
        value?.isOverride,
        debugModeDisplayHidden
    );
    const dynamicBackgroundColor =
        DynamicColors.calculateDynamicBackgroundColor(isEnabled, overrideState, debugModeDisplayHidden);

    // Dynamic styles for position and size
    const dynamicPositionAndSize: CSSProperties = {
        left: `${position?.left * scale}px`,
        top: `${position?.top * scale}px`,
        height: `${size?.height * scale}px`,
        width: `${size?.width * scale}px`,
    };

    // Dynamic styles for input element
    const dynamicStylesInput: CSSProperties = {
        width: "100%",
        backgroundColor: dynamicBackgroundColor,
        textAlign: (style?.alignment || "left")?.toLowerCase(),
        fontSize: `${(style?.fontSize || 10) * scale}px`,
        fontWeight: style?.bold ? "bold" : "normal",
        fontStyle: style?.italic ? "italic" : "normal",
        fontFamily: style?.fontName || "inherit",
        boxSizing: "border-box",
        // If spaced text is visible, make the text color transparent
        color: showSpacedText ? "transparent" : dynamicTextFieldColor,
        cursor: isLocked ? "not-allowed" : "",
        border: hyperlink?.startsWith("SW")
            ? `3px solid ${DynamicColors.calculateDynamicTextFieldBorderColor(hyperlink)}`
            : isFocusedFieldNote && !isPaymentRequest
              ? "2px solid #000000"
              : "",
    };

    // Dynamic styles for spaced text element
    const dynamicStylesSpacingArray: CSSProperties = {
        height: `${size?.height * scale}px`,
        width: `${size?.width * scale}px`,
        color: dynamicTextFieldColor,
        textAlign: (style?.alignment || "left")?.toLowerCase(),
        fontSize: `${(style?.fontSize || 10) * scale}px`,
        fontWeight: style?.bold ? "bold" : "normal",
        fontStyle: style?.italic ? "italic" : "normal",
        fontFamily: style?.fontName || "monospace",
    };

    // Handler for spacing array click
    const handleSpacingArrayClick = () => {
        // Hide spaced text and focus on the input when the spaced text is clicked
        setShowSpacedText(false);
        inputRef?.current?.focus();
    };

    // Handler for input focus
    const handleInputFocus = () => {
        if (!isEnabled) {
            return;
        }
        setInputValue(notFormattedInputValue);

        // Hide spaced text when the input is focused
        if (hasSpacingArray) {
            setShowSpacedText(false);
        }

        if (!isPaymentRequest) {
            dispatch(
                GlobalStateActions?.[product?.productName]?.setReturnNotes({
                    ...returnNotes,
                    focusedFieldNoteId: id,
                    focusedFieldNotePage: returnPage.attributes.pageOrder,
                    focusedFieldNoteType: NoteFieldType.NUMBER,
                }),
            );
        }
    };

    const setAllInputValues = (newValue: string) => {
        setInputValue(newValue);
        setNotFormattedInputValue(newValue);
        setFormattedInputValue(Formatter.format(templateField, newValue));
    };

    // Handler for input blurs
    const handleInputBlur = () => {
        if (!isPaymentRequest) {
            dispatch(
                GlobalStateActions?.[product?.productName]?.setReturnNotes({
                    ...returnNotes,
                    focusedFieldNoteId: undefined,
                    isFocusedFieldNotePage: undefined,
                    focusedFieldNoteType: undefined,
                }),
            );
        }

        // Show spaced text when the input is blurredfi
        if (hasSpacingArray) {
            setShowSpacedText(true);
        }

        const currentValue = value.value;
        const defaultValue = value.documentField.value;
        const newValue: string = notFormattedInputValue;

        // Check if the default value is used
        const isDefaultValueUsed = currentValue == defaultValue;

        // Check if the new value is trying to clear the default value
        const clearingDefaultValue = newValue == "" && isDefaultValueUsed;

        // Check if the new value is empty
        const isNewValueEmpty = newValue == "";

        // Check if the new value is equal to the current value
        const isNewValueEqualToCurrent = newValue == currentValue;

        // Check if the new value is equal to the default value
        const isNewValueEqualToDefault = newValue == defaultValue;

        if (clearingDefaultValue) {
            setAllInputValues(defaultValue.toString());
            setInputValue(
                Formatter.format(templateField, defaultValue.toString()),
            );
            return;
        }

        if (isNewValueEqualToCurrent) {
            setAllInputValues(currentValue.toString());
            setInputValue(
                Formatter.format(templateField, currentValue.toString()),
            );
            return;
        }

        // If the new value is different from the default value and not empty, update the document
        if (!isNewValueEqualToDefault && !isNewValueEmpty) {
            updateReturnDocument(
                "numFields",
                value?.documentField,
                returnPage,
                newValue,
            );
            setAllInputValues(newValue);
        } else {
            // If the new value is the same as the default value or empty, reset the field to the default value
            updateReturnDocument(
                "numFields",
                value?.documentField,
                returnPage,
                "",
            );
            setAllInputValues(defaultValue!.toString());
        }
        setInputValue(formattedInputValue);
    };

    // Handler for input change
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        let newValue = event.target.value;

        let formattedValue = newValue.replace(/[^0-9.-]/g, "");
        formattedValue = formattedValue.replace(
            new RegExp(
                `(\\.\\d{0,${templateField.decimalPrecision ?? "2"}})\\d*`,
            ),
            "$1",
        );
        setInputValue(formattedValue);
        setNotFormattedInputValue(formattedValue);
        setFormattedInputValue(Formatter.format(templateField, formattedValue));
    };

    // Handler for input key press
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleInputBlur();
        }
    };

    const fieldToolbarChildren = [
        hyperlink?.startsWith("SW") && (
            <IconButton
                size="small"
                onClick={() =>
                    onClickHyperlinkCallback(templateField.hyperlink)
                }
            >
                <OpenInNewIcon
                    fontSize="small"
                    sx={{
                        color: DynamicColors.calculateDynamicTextFieldBorderColor(
                            hyperlink,
                        ),
                    }}
                />
            </IconButton>
        ),
        !isPaymentRequest && (
            <IconButton
                size="small"
                onClick={() => {
                    dispatch(
                        GlobalStateActions?.[
                            product?.productName
                        ]?.setReturnNotes({
                            newFieldNoteId: id,
                            newFieldNotePage: returnPage.attributes.pageOrder,
                            newFieldNoteType: NoteFieldType.NUMBER,
                            showNotes: true,
                        }),
                    );
                }}
            >
                <NotesIcon fontSize="small" />
            </IconButton>
        ),
    ].filter(Boolean); // Remove any false, null, or undefined values

    return (
        <>
            <Tooltip title={toolTip} placement="top">
                <div
                    className="number-field-container"
                    style={dynamicPositionAndSize}
                >
                    {debugMode ? (
                        <input
                            className="number-field-input"
                            style={dynamicStylesInput}
                            value={"N" + templateField.id}
                            readOnly={true}
                            tabIndex={pageTabIndex}
                        />
                    ) : (
                        <input
                            className="number-field-input"
                            type={"text"}
                            style={dynamicStylesInput}
                            value={inputValue}
                            readOnly={isLocked}
                            disabled={!isEnabled}
                            tabIndex={pageTabIndex}
                            maxLength={maxLength}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            onFocus={handleInputFocus}
                            onKeyDown={handleKeyPress} // Handle key press events
                            ref={inputRef}
                            id={id}
                            data-testid={id}
                            title="Number Field"
                            onMouseOver={() => setToolbarVisible(true)}
                        />
                    )}
                    {/* Conditionally render spaced text element */}
                    {showSpacedText ? (
                        <SpacedText
                            reversed={false}
                            runsInput={spacingArray}
                            // Exclude characters and apply optional chaining
                            value={inputValue}
                            scale={scale}
                            // Pass dynamic styles to SpacedText component
                            style={dynamicStylesSpacingArray}
                            onClick={handleSpacingArrayClick}
                            alignment={style?.alignment}
                        />
                    ) : null}
                </div>
            </Tooltip>
            {toolbarVisible && fieldToolbarChildren.length > 0 && (
                <FieldToolbar
                    setVisible={setToolbarVisible}
                    fieldStyles={dynamicPositionAndSize}
                    inputId={id}
                >
                    {fieldToolbarChildren}
                </FieldToolbar>
            )}
        </>
    );
};

export default NumberField;
