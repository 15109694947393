import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Checkbox, FormControlLabel } from "@mui/material";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import React, { FC } from "react";
import { CheckboxTreeData } from "src/types";
import "./CheckboxTree.scss";

interface CheckboxTreeProps {
    data: CheckboxTreeData[];
    onDataChange: (data: CheckboxTreeData[]) => void;
}

const CheckboxTree: FC<CheckboxTreeProps> = ({ data, onDataChange }) => {
    function hasChildren(data: CheckboxTreeData) {
        return Array.isArray(data.children) && data.children.length > 0;
    }

    function getAllIds(data: CheckboxTreeData[]) {
        let ids: string[] = [];
        data.forEach((item: CheckboxTreeData) => {
            ids.push(item.id);
            if (hasChildren(item) && item.children) {
                ids = ids.concat(getAllIds(item.children));
            }
        });
        return ids;
    }

    function findAndReplace(
        id: string,
        newCheckedStatus: boolean,
        data: CheckboxTreeData[],
    ) {
        data.forEach((item: CheckboxTreeData) => {
            if (item.id === id) {
                item.checked = newCheckedStatus;
                if (hasChildren(item) && item.children) {
                    item.children.forEach(
                        (child) => (child.checked = newCheckedStatus),
                    );
                }
            } else if (hasChildren(item) && item.children) {
                findAndReplace(id, newCheckedStatus, item.children);

                const allChildrenChecked = item.children.every(
                    (child) => child.checked,
                );
                const allChildrenUnchecked = item.children.every(
                    (child) => !child.checked,
                );
                if (allChildrenChecked) {
                    item.checked = true;
                } else if (allChildrenUnchecked) {
                    item.checked = false;
                }
            }
        });
    }

    function updateCheckedValue(id: string, checked: boolean) {
        const updatedData = [...data];
        findAndReplace(id, checked, updatedData);
        onDataChange(updatedData);
    }

    function onTreeDataChange(checked: boolean, data: CheckboxTreeData) {
        updateCheckedValue(data.id, checked);
    }

    function isIndeterminate(data: CheckboxTreeData) {
        if (hasChildren(data) && data.children) {
            return (
                data.children.some((child) => child.checked) &&
                !data.children.every((child) => child.checked)
            );
        }
        return false;
    }

    const renderCheckboxTree = (data: CheckboxTreeData[]) => {
        return (
            <>
                {data.map((node) => {
                    return (
                        <TreeItem
                            key={node.id}
                            itemId={node.id}
                            label={
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={node.checked}
                                            indeterminate={isIndeterminate(
                                                node,
                                            )}
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>,
                                            ) =>
                                                onTreeDataChange(
                                                    event.currentTarget.checked,
                                                    node,
                                                )
                                            }
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        />
                                    }
                                    label={<>{node.label}</>}
                                    key={node.id}
                                />
                            }
                        >
                            {hasChildren(node) && node.children
                                ? renderCheckboxTree(node.children)
                                : null}
                        </TreeItem>
                    );
                })}
            </>
        );
    };

    return (
        <SimpleTreeView
            slots={{
                expandIcon: ExpandMoreIcon,
                collapseIcon: ChevronRightIcon,
            }}
            defaultExpandedItems={[]}
        >
            {renderCheckboxTree(data)}
        </SimpleTreeView>
    );
};

export default CheckboxTree;
