import { InfoOutlined } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";
import {
    GridCellModes,
    GridCellModesModel,
    GridCellParams,
    GridColDef,
    GridColType,
    GridEditInputCell,
    GridFilterModel,
    GridRenderCellParams,
    GridRenderEditCellParams,
    GridRowModel,
    getGridSingleSelectOperators,
} from "@mui/x-data-grid-premium";
import React from "react";
import { formatDateTime, formatFilterValue } from "src/services/Utility";
import {
    AllocatorFilters,
    CorrectedAddresses,
    JOB_STATUS_TYPES,
    REPORT_COLUMN_TYPES,
    REPORT_EDIT_FIELDS,
} from "src/types";
import colors from "src/styles/colors.scss";

export const getParsedColumn = (
    field: string,
    headerName: string | undefined,
    type: GridColType | undefined,
    filterable: boolean | undefined,
    correctedAddresses: CorrectedAddresses,
    jobStatus: JOB_STATUS_TYPES,
    valueOptions: string[] | null,
    parsedRows: GridRowModel[],
    width?: number,
): GridColDef => {
    const isEditable =
        jobStatus === JOB_STATUS_TYPES.ON_REVIEW &&
        (field === REPORT_EDIT_FIELDS.NORMAL_CITY ||
            field === REPORT_EDIT_FIELDS.NORMAL_STREET ||
            field === REPORT_EDIT_FIELDS.NORMAL_STATE ||
            field === REPORT_EDIT_FIELDS.NORMAL_ZIP);

    const parsedColumn = {
        field,
        headerName,
        type,
        filterable,
        sortable: false,
        width: width ?? getTextWidth(parsedRows, field, headerName, isEditable),
        minWidth: type === REPORT_COLUMN_TYPES.DATE ? 160 : 120,
        editable: isEditable,
        valueGetter: (value) => value?.value,
        valueFormatter: (value) => {
            if (type === REPORT_COLUMN_TYPES.DATE) {
                return formatDateTime(value);
            }
            return value;
        },
        valueSetter: (value, row) => {
            return {
                ...row,
                [field]: {
                    ...row[field],
                    value:
                        row[field]?.sourceValue && value === ""
                            ? row[field]?.sourceValue
                            : value,
                },
            };
        },
        valueParser: (value) => value?.toUpperCase(),
        cellClassName: ({ field, value, row }: GridCellParams) => {
            if (row?.total_row?.value) {
                return "total";
            }
            if (isEditable) {
                if (
                    value !== row[field]?.sourceValue &&
                    (row[field]?.corrected ||
                        (correctedAddresses[row?.key] &&
                            value !== row?.old[field]?.value))
                ) {
                    return "corrected";
                }
                return "editable";
            }
            return "";
        },
        renderCell: (params: GridRenderCellParams) => {
            let { field, value, row, colDef } = params;
            
            if (colDef.valueFormatter && type === REPORT_COLUMN_TYPES.DATE) {
                value = formatDateTime(value);
            }

            if (type === REPORT_COLUMN_TYPES.BOOLEAN) {
                value = value ? "X" : "";
            }

            return (
            <>
                <Box 
                    title={value} 
                    sx={{
                        textOverflow: "ellipsis", 
                        overflow: "hidden"
                    }}
                >
                    { value }
                </Box>
                {
                    (isEditable && row?.[field]?.autocorrectionInfo && 
                    value === row?.old?.[field]?.value &&
                    value !== row?.[field]?.sourceValue) && (
                        <Tooltip
                            slotProps={{
                                tooltip: { sx: { maxWidth: "none" } },
                            }}
                            title={row?.[field]?.autocorrectionInfo}
                            arrow
                        >
                            <InfoOutlined 
                                fontSize="small"
                                sx={{
                                    color: colors.allocatorTextGreyColor, 
                                    marginLeft: "5px"
                                }}/>
                        </Tooltip>
                    )
                }
            </>
        )},
        renderEditCell: (params: GridRenderEditCellParams) => (
            <GridEditInputCell
                {...params}
                onKeyDown={(e) => {
                    if (params?.field === REPORT_EDIT_FIELDS.NORMAL_ZIP) {
                        const keyboardNumbers = ["1","2","3","4","5","6","7","8","9","0"];

                        if(keyboardNumbers.indexOf(e?.key) !== -1) {
                            if(e?.target?.value?.length === 5) {
                                e.target.value = e.target.value + "-";
                                return;
                            }
                        } else if (e?.key === "Backspace" || e?.key === "-" && e?.target?.value?.length === 5) {
                            return;
                        } else e.preventDefault();
                    }
                }}
                inputProps={{
                    maxLength: getMaxLengthByField(params?.field),
                }}
            />
        ),
    };

    if (valueOptions) {
        return {
            ...parsedColumn,
            type: REPORT_COLUMN_TYPES.SINGLE_SELECT,
            valueOptions,
            filterOperators: getGridSingleSelectOperators().filter(operator => operator.value === "is"),
            getApplyQuickFilterFn: () => null,
        }
    } else return parsedColumn;
};

export const getParsedRow = (
    row: GridRowModel,
    correctedAddresses: CorrectedAddresses,
    id: number | string,
    key: string
): GridRowModel => {
    return {
        ...row,
        id,
        key,
        [REPORT_EDIT_FIELDS.NORMAL_STREET]:
            correctedAddresses[key]?.[REPORT_EDIT_FIELDS.NORMAL_STREET] ??
            row?.old?.[REPORT_EDIT_FIELDS.NORMAL_STREET] ??
            row?.[REPORT_EDIT_FIELDS.NORMAL_STREET],
        [REPORT_EDIT_FIELDS.NORMAL_CITY]:
            correctedAddresses[key]?.[REPORT_EDIT_FIELDS.NORMAL_CITY] ??
            row?.old?.[REPORT_EDIT_FIELDS.NORMAL_CITY] ??
            row?.[REPORT_EDIT_FIELDS.NORMAL_CITY],
        [REPORT_EDIT_FIELDS.NORMAL_STATE]:
            correctedAddresses[key]?.[REPORT_EDIT_FIELDS.NORMAL_STATE] ??
            row?.old?.[REPORT_EDIT_FIELDS.NORMAL_STATE] ??
            row?.[REPORT_EDIT_FIELDS.NORMAL_STATE],
        [REPORT_EDIT_FIELDS.NORMAL_ZIP]:
            correctedAddresses[key]?.[REPORT_EDIT_FIELDS.NORMAL_ZIP] ??
            row?.old?.[REPORT_EDIT_FIELDS.NORMAL_ZIP] ??
            row?.[REPORT_EDIT_FIELDS.NORMAL_ZIP],
        old: row?.old ?? row,
    };
};

export const getUpdatedCellModesModel = (
    prevModel: GridCellModesModel,
    params: GridCellParams
): GridCellModesModel => {
    return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
            (acc, id) => ({
                ...acc,
                [id]: Object.keys(prevModel[id]).reduce(
                    (acc2, field) => ({
                        ...acc2,
                        [field]: { mode: GridCellModes.View },
                    }),
                    {}
                ),
            }),
            {}
        ),
        [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
                (acc, field) => ({
                    ...acc,
                    [field]: { mode: GridCellModes.View },
                }),
                {}
            ),
            [params.field]: { mode: GridCellModes.Edit },
        },
    };
};

export const getMappedFilters = (filterModel: GridFilterModel): AllocatorFilters[] => {
    return filterModel?.items?.filter(item => !!item?.value || item?.operator === "isEmpty" || item?.operator === "isNotEmpty")
        .map(item => {
            return {
                field: item?.field,
                value: formatFilterValue(item?.value),
                operator: item?.operator,
                booleanOperator: filterModel?.logicOperator?.toUpperCase()
            }
        })
};

const getMaxLengthByField = (field: string): number => {
    switch (field) {
        case REPORT_EDIT_FIELDS.NORMAL_STATE:
            return 2;
        case REPORT_EDIT_FIELDS.NORMAL_ZIP:
            return 10;
        default:
            return 250;
    }
};

const getTextWidth = (
    parsedRows: GridRowModel[], 
    field: string, 
    headerName: string | undefined, 
    isEditable: boolean,
): number => {
    const canvas = document.createElement("canvas");
    const context = canvas?.getContext("2d");

    const widestCell = parsedRows?.length ? 
        parsedRows?.reduce((prev, current) => {
            const prevWidth = getMeasuredWidth(context, prev?.[field]?.value, field, isEditable, prev);
            const currentWidth = getMeasuredWidth(context, current?.[field]?.value, field, isEditable, current);
            return prevWidth > currentWidth ? prev : current;
        }) : undefined;

    const widestText = widestCell?.[field]?.value ?? 0;

    const cellWidth = getMeasuredWidth(context, widestText, field, isEditable, widestCell);
    const headerWidth = getMeasuredWidth(context, headerName, field);
    const textWidth = headerWidth > cellWidth ? headerWidth : cellWidth;

    return textWidth;
};

const getMeasuredWidth = (
    context: CanvasRenderingContext2D | null, 
    text: string | undefined, 
    field: string, 
    isEditable?: boolean,
    cell?: GridRowModel,
) => {
    const font = "bold 0.875rem 'Roboto','Helvetica','Arial',sans-serif";
    let reservedSpace = 25;

    // double reserved space if auto correction icon is present
    if (isEditable && cell?.[field]?.autocorrectionInfo && 
        text === cell?.old?.[field]?.value &&
        text !== cell?.[field]?.sourceValue) {
            reservedSpace += reservedSpace
    }

    if (context && text) {
        // setting font and size for correct measuring text
        context.font = font;
        return context.measureText(text).width + reservedSpace;
    }

    return 0;
}
