import React, { FC, useEffect } from "react";
import ContainerWithResizableSideBar from "src/components/ContainerWithResizableSideBar/ContainerWithResizableSideBar";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import Loader from "src/components/Loader/Loader";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AgentService } from "src/services/AgentService";
import { FixMeLater } from "src/types";
import "./Agent.scss";
import AgentHome from "./components/AgentHome/AgentHome";
import Menu from "./components/Menu/Menu";
import SingleAddressLookup from "./components/SingleAddressLookup/SingleAddressLookup";
import UsageReport from "./components/UsageReport/UsageReport";
import { Layout } from "src/uikit";

const Agent: FC = () => {
    const [isLoading, setIsLoading] = React.useState<Boolean>(false);
    const [error, setError] = React.useState<FixMeLater>(null);

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const document = useAppSelector(
        (state) => state?.[product?.productName]?.value?.document
    );

    const agentService = AgentService.getInstance();

    const dispatch = useAppDispatch();

    useEffect(() => {
        const fetchUsageReport = async () => {
            setIsLoading(true);
            try {
                const usageReport = await agentService.getUsageReport();

                dispatch(
                    GlobalStateActions[product?.productName]?.setUsageReport(
                        usageReport
                    )
                );

                const effectiveDate = await agentService.getEffectiveDate();

                dispatch(
                    GlobalStateActions[product?.productName]?.setEffectiveDate(
                        effectiveDate
                    )
                );

                const accountOptions = await agentService.getAccountOptions();

                dispatch(
                    GlobalStateActions[product?.productName]?.setAccountOptions(
                        accountOptions
                    )
                );

                const userOptions = await agentService.getUserOptions();

                dispatch(
                    GlobalStateActions[product?.productName]?.setUserOptions(
                        userOptions
                    )
                );
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUsageReport();
    }, []);

    const getContent = () => {
        return (
            <div className="agent-right-container">
                {document === "Agent Home" && <AgentHome />}
                {document === "Single Address Lookup" && (
                    <SingleAddressLookup />
                )}
                {document === "Usage Report" && <UsageReport />}
                {document === "" ||
                    (!document && (
                        <div className="agent-error">
                            Please select a document.
                        </div>
                    ))}
            </div>
        );
    };

    if (error)
        return (
            <ErrorMessage error={`Error fetching data: ${error.toString()}`} />
        );

    if (!error && isLoading) return <Loader />;

    return (
        <Layout
            leftPane={<Menu />}
        >
            {getContent()}
        </Layout>
        // <div className="agent-container">
        //     <ContainerWithResizableSideBar
        //         sideBarContent={<Menu />}
        //         content={getContent()}
        //         resizeEnabled={false}
        //         toolbar={undefined}
        //     />
        // </div>
    );
};

export default Agent;
