import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import Loader from "src/components/Loader/Loader";
import { ProductIdToNameMap } from "src/constants/ProductIds";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { CompanyProductService } from "src/services";
import { FixMeLater } from "src/types";
import {
    StyledHeader,
    StyledNoDataFound,
    StyledOpenModalButton,
    StyledOpenModalButtonText,
    StyledPaper,
    StyledTableCell,
    StyledTableContainer,
} from "../../../../AccountAdmin.styled";
import AddProductModal from "../../modals/AddProductModal/AddProductModal";
import "./Products.scss";
import { processCompanyProducts } from "./Products.util";
import ProductsTableRow from "./components/ProductsTableRow/ProductsTableRow";
import { FrameCard } from "src/uikit";

const Products: FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value,
    );
    const selectedAccountCompany = useAppSelector(
        (state) => state?.[product?.productName].value?.selectedAccountCompany,
    );
    const selectedAccountCompanyProducts = useAppSelector(
        (state) =>
            state?.[product?.productName].value?.selectedAccountCompanyProducts,
    );

    const [addProductModalOpen, setAddProductModalOpen] =
        useState<boolean>(false);

    const dispatch = useAppDispatch();
    const companyProductService = CompanyProductService.getInstance();

    useEffect(() => {
        const fetchSelectedAccountCompanyProducts = async () => {
            if (selectedAccountCompany) {
                setIsLoading(true);
                try {
                    const selectedAccountCompanyProductsData: FixMeLater[] =
                        await companyProductService.getCompanyProducts(
                            selectedAccountCompany?.id,
                        );

                    const processedSelectedAccountCompanyProducts =
                        processCompanyProducts(
                            selectedAccountCompanyProductsData,
                        );

                    dispatch(
                        GlobalStateActions[
                            product?.productName
                        ].setSelectedAccountCompanyProducts(
                            processedSelectedAccountCompanyProducts,
                        ),
                    );
                } catch (error) {
                    console.error(error);
                    setError(error as Error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchSelectedAccountCompanyProducts();
    }, [selectedAccountCompany]);

    const groupProductsByName = (products: FixMeLater[]) => {
        const productMap = new Map<string, FixMeLater[]>();
        products?.forEach((product) => {
            let productName = ProductIdToNameMap.get(
                product?.productId,
            ) as string;
            let yearAndJurisdiction = {
                productName,
                year: product?.year,
                jurisdictionDTOs: product?.jurisdictionDTOs,
            };

            if (!productMap.has(productName)) {
                productMap.set(productName, [yearAndJurisdiction]);
            } else {
                const productArray = productMap.get(productName);
                if (productArray) {
                    productArray.push(yearAndJurisdiction);
                }
            }
        });
        return productMap;
    };

    const getTableRows = (groupedProducts: Map<string, FixMeLater[]>) => {
        const rows = Array.from(groupedProducts)?.map(
            ([productName, products]) => {
                return <ProductsTableRow key={productName} data={products} />;
            },
        );

        return rows;
    };

    const noDataFound =
        !selectedAccountCompany || selectedAccountCompanyProducts?.length === 0;

    const noDataFoundMessage = selectedAccountCompany
        ? "This company is not currently associated with any products."
        : "Please select a company.";

    if (error)
        return (
            <ErrorMessage
                error={`Error fetching company products: ${error.toString()}`}
            />
        );

    if (!error && isLoading) return <Loader />;

    return (
        <>
            <div className="products-container">
                <FrameCard
                    title="COMPANY PRODUCTS"
                    actions={
                        selectedAccountCompany
                            ? [
                                  {
                                      text: "Add Product",
                                      onClick: () =>
                                          setAddProductModalOpen(true),
                                  },
                              ]
                            : []
                    }
                >
                    {noDataFound ? (
                        <StyledNoDataFound>
                            {noDataFoundMessage}
                        </StyledNoDataFound>
                    ) : (
                        <StyledTableContainer $maxHeight={"292px"}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>
                                            Product
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            Tax Year
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            Jurisdiction
                                        </StyledTableCell>
                                        <StyledTableCell>Edit</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {getTableRows(
                                        groupProductsByName(
                                            selectedAccountCompanyProducts ||
                                                [],
                                        ),
                                    )}
                                </TableBody>
                            </Table>
                        </StyledTableContainer>
                    )}
                </FrameCard>
            </div>
            <AddProductModal
                open={addProductModalOpen}
                onClose={() => {
                    setAddProductModalOpen(false);
                }}
            />
        </>
    );
};

export default Products;
