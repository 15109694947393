import { BaseAPIService } from "src/services/BaseApiService";

export class WebSocketService {

    private static _instance: WebSocketService;
    private readonly BASE_PATH: string = "/api/v1";
    private readonly WS_PATH: string = `${process.env.REACT_APP_STRATUS_API_BASE_WS_URL}`;
    private api: BaseAPIService = BaseAPIService.getInstance();

    public static getInstance(): WebSocketService {
        if (!WebSocketService._instance) {
            WebSocketService._instance = new WebSocketService();
        }
        return WebSocketService._instance;
    }

    private getPath(path: string): string {
        return `${this.WS_PATH + this.BASE_PATH + path}`;
    }

    async openWebSocketConnection(groupTag: string): Promise<WebSocket> {
        const token = await this.api.getToken();
        return new WebSocket(this.getPath(`/ws?groupTag=${groupTag}&authorization=${token}`));
    }

}
