import React, { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import CalendarIcon from "src/assets/images/Calendar.ico";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { Product, UserOptions } from "src/types";
import SimpleAutoComplete from "../SimpleAutoComplete/SimpleAutoComplete";
import "./YearSelector.scss";

export type YearSelectorProps = {
    yearChangeFn: (changes: { selectedProduct: Product }) => void;
};

const YearSelector: FC<YearSelectorProps> = ({ yearChangeFn }) => {
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const product: Product | undefined = useAppSelector(
        (state) => state?.Product.value,
    );
    const userOptions: UserOptions | undefined = useAppSelector(
        (state) => state.UserOptions.value,
    );

    let lastYearFromPreferences;

    if (product) {
        lastYearFromPreferences =
            userOptions.productPreferences?.[product?.productName]?.filters
                ?.year;
    }

    const products: Product[] = useAppSelector(
        (state) => state?.Products?.value?.products,
    );

    let yearSet = new Set<number>();
    products?.forEach((item) => {
        if (item.productName === product?.productName)
            yearSet.add(item.taxYear);
    });

    const yearOptions = Array.from(yearSet)
        ?.map((year) => ({
            value: year,
            label: year.toString().slice(2, 4),
        }))
        .sort((a, b) => b.value - a.value);

    // Check if year param exists in url (this will only happen on page load)
    useEffect(() => {
        const yearParam = searchParams.get("year");

        if (yearParam) {
            let selectedProduct = products?.find(
                (item) =>
                    item.taxYear === parseInt(yearParam) &&
                    item.productName === product?.productName,
            );
            if (selectedProduct) {
                dispatch(GlobalStateActions.setProduct(selectedProduct));
            }
        }
    }, []);

    useEffect(() => {
        const yearFromPreferences = lastYearFromPreferences?.toString();
        const defaultYear = new Date().toString().slice(2);
        const selectedYear = yearFromPreferences
            ? parseInt(yearFromPreferences)
            : defaultYear;

        let selectedProduct = products?.find(
            (item) =>
                item.taxYear === selectedYear &&
                item.productName === product?.productName,
        );

        if (selectedProduct) {
            dispatch(GlobalStateActions.setProduct(selectedProduct));
        }
    }, [userOptions]);

    const handleChange = (selectedYear: number) => {
        let newProduct = products?.find(
            (item) =>
                item.taxYear === selectedYear &&
                item.productName === product?.productName,
        );

        if (newProduct) {
            dispatch(GlobalStateActions.setProduct(newProduct));
            yearChangeFn({ selectedProduct: newProduct });
        } else {
            console.error(
                "YearSelector: handleChange: newProduct is undefined",
            );
        }
    };

    return (
        <div className="tts-year-selector">
            <SimpleAutoComplete
                icon={<img src={CalendarIcon} width={25} alt="Calendar Icon" />}
                size="small"
                label="Year"
                data={yearOptions}
                onSelect={handleChange}
                selected={product?.taxYear || 0}
                data-testid="tts-year-selector"
                disableClearButton={true}
            />
        </div>
    );
};

export default YearSelector;
