import styled from "styled-components";
import { Button } from "@mui/material";

export const StyledModalContainer = styled.div`
    align-items: center;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
`;

export const StyledExportButton = styled.div`
    align-items: center;
    display: flex;
    justify-content: right;
    padding: 16px 20px;
`;

export const StyledExportButtonText = styled(Button)`
    cursor: pointer;
    font-weight: bold;
`;
