import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    AccessControlGroup,
    AccessControlGroupWithoutRelations,
    Account,
    Company,
    CompanyProduct,
    Product,
    Role,
    SubAccountWithCompanies,
    User,
} from "src/types";

interface AccountAdminState {
    value: {
        accounts: Account[];
        selectedAccount?: Account;
        selectedAccountCompanies: Company[];
        selectedAccountCompany?: Company;
        selectedAccountCompanyGroups: AccessControlGroupWithoutRelations[];
        selectedAccountCompanyProducts: CompanyProduct[];
        selectedAccountCompanyUsers: User[];
        selectedAccountGroup?: AccessControlGroup;
        selectedAccountGroupCompanies: Company[];
        selectedAccountGroupUsers: User[];
        selectedAccountGroups: AccessControlGroup[];
        selectedAccountProducts: Product[];
        selectedAccountSubAccountsWithCompanies: SubAccountWithCompanies[];
        selectedAccountUser?: User;
        selectedAccountUsers: User[];
        selectedAccountRole?: Role;
        selectedAccountRoles: Role[];
        selectedComponent: string;
        usersWithCsrRoleId: User[];
    };
}

const initialState: AccountAdminState = {
    value: {
        accounts: [],
        selectedAccount: undefined,
        selectedAccountCompanies: [],
        selectedAccountCompany: undefined,
        selectedAccountCompanyGroups: [],
        selectedAccountCompanyProducts: [],
        selectedAccountCompanyUsers: [],
        selectedAccountGroup: undefined,
        selectedAccountGroupCompanies: [],
        selectedAccountGroupUsers: [],
        selectedAccountGroups: [],
        selectedAccountProducts: [],
        selectedAccountSubAccountsWithCompanies: [],
        selectedAccountUser: undefined,
        selectedAccountUsers: [],
        selectedAccountRole: undefined,
        selectedAccountRoles: [],
        selectedComponent: "Account",
        usersWithCsrRoleId: [],
    },
};

export const AccountAdminSlice = createSlice({
    name: "AccountAdmin",
    initialState,
    reducers: {
        setAccounts: (state, action: PayloadAction<Account[]>) => {
            state.value.accounts = action.payload;
        },
        setSelectedAccount: (state, action: PayloadAction<Account>) => {
            state.value.selectedAccount = action.payload;
        },
        setSelectedAccountCompanies: (
            state,
            action: PayloadAction<Company[]>,
        ) => {
            state.value.selectedAccountCompanies = action.payload;
        },
        setSelectedAccountCompany: (state, action: PayloadAction<Company>) => {
            state.value.selectedAccountCompany = action.payload;
        },
        setSelectedAccountCompanyGroups: (
            state,
            action: PayloadAction<AccessControlGroupWithoutRelations[]>,
        ) => {
            state.value.selectedAccountCompanyGroups = action.payload;
        },
        setSelectedAccountCompanyProducts: (
            state,
            action: PayloadAction<CompanyProduct[]>,
        ) => {
            state.value.selectedAccountCompanyProducts = action.payload;
        },
        setSelectedAccountCompanyUsers: (
            state,
            action: PayloadAction<User[]>,
        ) => {
            state.value.selectedAccountCompanyUsers = action.payload;
        },
        setSelectedAccountGroup: (
            state,
            action: PayloadAction<AccessControlGroup>,
        ) => {
            state.value.selectedAccountGroup = action.payload;
        },
        setSelectedAccountGroupCompanies: (
            state,
            action: PayloadAction<Company[]>,
        ) => {
            state.value.selectedAccountGroupCompanies = action.payload;
        },
        setSelectedAccountGroupUsers: (
            state,
            action: PayloadAction<User[]>,
        ) => {
            state.value.selectedAccountGroupUsers = action.payload;
        },
        setSelectedAccountGroups: (
            state,
            action: PayloadAction<AccessControlGroup[]>,
        ) => {
            state.value.selectedAccountGroups = action.payload;
        },
        setSelectedAccountProducts: (
            state,
            action: PayloadAction<Product[]>,
        ) => {
            state.value.selectedAccountProducts = action.payload;
        },
        setSelectedAccountRole: (state, action: PayloadAction<Role>) => {
            state.value.selectedAccountRole = action.payload;
        },
        setSelectedAccountRoles: (state, action: PayloadAction<Role[]>) => {
            state.value.selectedAccountRoles = action.payload;
        },
        setSelectedAccountSubWithCompanies: (
            state,
            action: PayloadAction<SubAccountWithCompanies[]>,
        ) => {
            state.value.selectedAccountSubAccountsWithCompanies =
                action.payload;
        },
        setSelectedAccountUser: (state, action: PayloadAction<User>) => {
            state.value.selectedAccountUser = action.payload;
        },
        setSelectedAccountUsers: (state, action: PayloadAction<User[]>) => {
            state.value.selectedAccountUsers = action.payload;
        },
        setSelectedComponent: (state, action: PayloadAction<string>) => {
            state.value.selectedComponent = action.payload;
        },
        setUsersWithCsrRoleId: (state, action: PayloadAction<User[]>) => {
            state.value.usersWithCsrRoleId = action.payload;
        },
    },
});

export const {
    setAccounts,
    setSelectedAccount,
    setSelectedAccountCompanies,
    setSelectedAccountCompany,
    setSelectedAccountCompanyGroups,
    setSelectedAccountCompanyProducts,
    setSelectedAccountCompanyUsers,
    setSelectedAccountGroup,
    setSelectedAccountGroupCompanies,
    setSelectedAccountGroupUsers,
    setSelectedAccountGroups,
    setSelectedAccountProducts,
    setSelectedAccountSubWithCompanies,
    setSelectedAccountRole,
    setSelectedAccountRoles,
    setSelectedAccountUser,
    setSelectedAccountUsers,
    setSelectedComponent,
    setUsersWithCsrRoleId,
} = AccountAdminSlice.actions;
export default AccountAdminSlice.reducer;
