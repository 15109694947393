import { Middleware } from "@reduxjs/toolkit";
import { setProduct } from "../slices/ProductSlice";
import { Product } from "src/types";
import GlobalStateActions from "../slices/GlobalStateActions";

export const productMiddleware: Middleware = (store) => (next) => (action) => {
    if (setProduct.match(action)) {
        const product = action.payload as Product | undefined;
        const productName = product?.productName;

        // Dropdown state should be set to false for all other products except Premium and FormsPlus
        if (productName && productName !== "Premium" && productName !== "FormsPlus") {        
            store.dispatch(
                GlobalStateActions.UserOptions.setDropdownStateView({
                   productName: productName, 
                   dropdownStateView: false,
                }
            )
            );
        }
    }

    // Continue processing the action
    return next(action);
};
