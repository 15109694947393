import styled from "styled-components";
import colors from "src/styles/colors.scss";
import { Tabs } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export const StyledTabs = styled(Tabs)`
    border-bottom: 1px solid;
    border-color: ${colors.grey};

    .MuiTab-root {
        color: ${colors.primaryColor};
    }
    
    .MuiTabs-indicator {
        background-color: ${colors.primaryColor};
    }
`

export const StyledContainedButton = styled(LoadingButton)`
    color: ${colors.white};
    background-color: ${colors.primaryColor};

    &: hover {
        background-color:  ${colors.primaryColor};
    }
`;

export const StyledTextButton = styled(LoadingButton)`
    color: ${colors.primaryColor};
    background-color: ${colors.white};
`;