import React, { FC, useState } from "react";
import { DropEvent, ErrorCode, FileRejection, useDropzone } from "react-dropzone";
import { Upload } from "@mui/icons-material";
import Loader from "src/components/Loader/Loader";
import { ScheduleService } from "src/services";
import colors from "src/styles/colors.scss";
import { FixMeLater, State } from "src/types";
import "./ImportSBPData.scss";
import { ACCEPTED_FILE_TYPES, getErrorMessageByCode } from "./ImportSBPData.util";
import { useAppSelector } from "src/hooks";
import { Checkbox, FormControlLabel, Radio, Typography } from "@mui/material";
import { HttpError } from "src/utils/HttpError";
import { StateCheckStatus } from "../../types/StatesChecked.types";
import { StyledRadioGroup } from "./ImportSBPData.styled";

interface ScheduleSBPKey {
    companyId: string;
    moduleId: string;
    productId: string;
    taxYearId: string;
    returnId: string;
    folderId: string;
    pageNumber: string;
}

interface ImportSBPDataProps {
    scheduleInput: ScheduleSBPKey;
    onClose: (e: DropEvent | Event) => void;
    handleSnackbar: (message: string | string[], severity: string, autoHideDuration?: number) => void;
}

const ImportSBPData: FC<ImportSBPDataProps> = ({scheduleInput, onClose, handleSnackbar}) => {
    const scheduleService = ScheduleService.getInstance();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [importOption, setImportOption] = useState<string>("add_replace_states");
    const [formatOption, setFormatOption] = useState<string>("NAIC");
    const states = useAppSelector((state) => state?.States?.value);
    const initStateCheckStatus = states?.map((st: State) => ({
        value: st,
        label: st.longName,
        checked: false
    }));
    const [stateCheckStatus, setStateCheckStatus] = useState<StateCheckStatus[]>(initStateCheckStatus);
    const [allChecked, setAllChecked] = useState(false);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        disabled: isLoading,
        multiple: false,
        accept: ACCEPTED_FILE_TYPES,
        onDrop: (acceptedFiles: File[], rejectedFiles: FileRejection[], event: DropEvent | Event) => {
            if (rejectedFiles?.length) {
                handleRejectedFileCode(rejectedFiles[0]?.errors[0]?.code, event ?? new Event("importSBPData"));
            } else {
                handleUpload(acceptedFiles, event ?? new Event("importSBPData"));
            }
        },
    });

    const acceptStyle = {
        border: `1px dashed ${colors.allocatorSelectedContainerBorderColor}`,
        backgroundColor: colors.allocatorComponentBackgroundColor,
    };

    const handleRejectedFileCode = (errorCode: ErrorCode | string, event: DropEvent | Event) => {
        onClose(event);
        handleSnackbar(getErrorMessageByCode(errorCode), "error");
    };

    const handleUpload = async (files: File[], event: DropEvent | Event) => {
        setIsLoading(true);

        const formData = new FormData();
        formData.append("file", files[0], files[0].name)
        const attachmentMetadata = new Blob(
            [
                JSON.stringify(
                    scheduleInput
                ),
            ],
            { type: "application/json" },
        );

        formData.append("scheduleInput", attachmentMetadata);

        try {
            const response = await scheduleService.uploadSBPData(formData, importOption, stateCheckStatus, formatOption);
            if (response[0].includes("successful")) {
                handleSnackbar(response, "success", 3000);
            } else {
                handleSnackbar(response, "error", 3000);
            }
        } catch (error: FixMeLater) {
            if (error instanceof HttpError) {
                handleSnackbar(JSON.parse(error?.body)?.message, "error");
            } else {
                handleSnackbar("Error uploading SBP file", "error");
            }
        } finally {
            setIsLoading(false);
            onClose(event);
        }
    };

    const handleCheckboxChange = (e, state: State) => {
        e.stopPropagation();
        setStateCheckStatus((prevStatus) =>
            prevStatus.map((st) =>
                st.value === state ? { ...st, checked: !st.checked } : st
            )
        );

    };
    const handleSelectAllChange = () => {
        const newCheckedStatus = !allChecked;
        setStateCheckStatus((prevStatus) =>
            prevStatus.map((st) => ({ ...st, checked: newCheckedStatus }))
        );
        setAllChecked(newCheckedStatus);
    };

    return (
        <div style={{width: '800px'}}>
            <Typography sx={{ marginY: 1 }}>
                <b>Select Input format: </b>
            </Typography>
            <StyledRadioGroup
                value={formatOption}
                onChange={(e) => setFormatOption(e?.target?.value)}
            >
                <FormControlLabel
                    value={"NAIC"}
                    control={<Radio />}
                    label="NAIC"
                    disabled={isLoading}
                />
                <FormControlLabel
                    value={"ASCII"}
                    control={<Radio />}
                    label="ASCII"
                    disabled={isLoading}
                />
            </StyledRadioGroup>
            <Typography sx={{ marginY: 1 }}>
                <b>Replace/Append Options: </b>
            </Typography>

            <StyledRadioGroup
                value={importOption}
                onChange={(e) => setImportOption(e?.target?.value)}
            >
                <FormControlLabel
                    value={"add_replace_states"}
                    control={<Radio />}
                    label="Add/Replace State(s)"
                    disabled={isLoading}
                />
                <FormControlLabel
                    value={"replace_append_lob"}
                    control={<Radio />}
                    label="Replace/Append Lines of Business(es)"
                    disabled={isLoading}
                />
                <FormControlLabel
                    value={"clear"}
                    control={<Radio />}
                    label="Clear Data"
                    disabled={isLoading}
                />
            </StyledRadioGroup>

            <Typography sx={{ marginY: 1 }}>
                <b>Import Jurisdiction Option:</b>
            </Typography>

            <FormControlLabel
                control={
                    <Checkbox
                        checked={allChecked}
                        onChange={handleSelectAllChange}
                        name="selectAll"
                    />
                }
                label="Select/Deselect All Jurisidtions"

                className={'form-control-label'}
            />
            <div className={'state-check-status'}>
                {Array.from(stateCheckStatus).map((option) => (
                    <FormControlLabel
                        key={option.value.id}
                        control={
                            <Checkbox
                                checked={
                                    option?.checked
                                }
                                onChange={(e) => handleCheckboxChange(e, option.value)}
                                name={option.label}
                            />
                        }
                        label={option.label}
                    />
                ))}

            </div>

            <div
                {...getRootProps({
                    className: "import-sbp-data-dropzone",
                    style: isDragActive ? acceptStyle: {},
                })}
            >
                {isLoading ? (
                    <Loader color={colors.primaryColor}/>
                ) : (
                    <div className="import-sbp-data-content">
                        <input {...getInputProps()} />
                        <Upload
                            fontSize="medium"
                            style={{ color: colors.primaryColor }}
                        />
                        {isDragActive ? (
                            <p>Drop a document here</p>
                        ) : (
                            <p>Click or drag text (.txt) file</p>
                        )}
                    </div>
                )}
            </div>
         </div>
    );
};

export default ImportSBPData;
