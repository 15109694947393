import { Card, CardContent, Grid } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import React, { FC, useEffect, useState } from "react";
import { useAppSelector } from "src/hooks";
import { FixMeLater, QuarterlyUsageSummary, YearData } from "src/types";
import styled from "styled-components";
import { calculateQuarterlyUsageSummary } from "../../Agent.util";
import "./AgentHome.scss";

const StyledCard = styled(Card)`
    margin-bottom: 20px;
`;

const AgentHome: FC = () => {
    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const usageReport = useAppSelector(
        (state) => state?.[product?.productName]?.value?.usageReport
    );

    const [mostRecentYearData, setMostRecentYearData] = useState<YearData>();
    const [quarterlyUsageSummary, setQuarterlyUsageSummary] =
        useState<QuarterlyUsageSummary>();

    useEffect(() => {
        setMostRecentYearData(usageReport?.yearData?.[0]);
        setQuarterlyUsageSummary(
            calculateQuarterlyUsageSummary(usageReport?.yearData)
        );
    }, [usageReport]);

    return (
        <div className="agent-home-container">
            <StyledCard>
                <Grid container>
                    <Grid item xs={4}>
                        <CardContent>
                            <div>
                                <b>
                                    Total Clicks Purchased for{" "}
                                    {mostRecentYearData?.year}
                                </b>
                            </div>
                            <div>{mostRecentYearData?.purchasedClicks}</div>
                        </CardContent>
                    </Grid>

                    <Grid item xs={4}>
                        <CardContent>
                            <div>
                                <b>
                                    Total Clicks Used during{" "}
                                    {mostRecentYearData?.year}
                                </b>
                            </div>
                            <div>{mostRecentYearData?.totalUsedClicks}</div>
                        </CardContent>
                    </Grid>

                    <Grid item xs={4}>
                        <CardContent>
                            <div>
                                <b>
                                    Total Clicks Remaining for{" "}
                                    {mostRecentYearData?.year}
                                </b>
                            </div>
                            <div>
                                {mostRecentYearData?.purchasedClicks !==
                                    undefined &&
                                mostRecentYearData?.totalUsedClicks !==
                                    undefined
                                    ? mostRecentYearData?.purchasedClicks -
                                      mostRecentYearData?.totalUsedClicks
                                    : "N/A"}
                            </div>
                        </CardContent>
                    </Grid>
                </Grid>
            </StyledCard>
            <StyledCard>
                <CardContent>
                    <div className="chart-header">Quarterly Usage Summary</div>
                    <div>
                        <BarChart
                            series={Object.entries(
                                quarterlyUsageSummary || {}
                            ).map(([year, data]) => ({
                                data: data || [0, 0, 0, 0],
                                label: year,
                            }))}
                            height={290}
                            xAxis={[
                                {
                                    data: ["Q1", "Q2", "Q3", "Q4"],
                                    scaleType: "band",
                                },
                            ]}
                            margin={{
                                top:80,
                            }}

                            slotProps={{
                                legend: {
                                    direction: 'row',
                                    position: {
                                        vertical:'top',
                                        horizontal: 'left',
                                    },
                                }
                            }}
                        >
                        </BarChart>
                    </div>
                </CardContent>
            </StyledCard>
        </div>
    );
};

export default AgentHome;
